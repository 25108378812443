import { API_PORT, API_URL } from "const";
import { Socket, io } from "socket.io-client";

import { IUserIdentity } from "../types/user";
import { validNotEmptyString } from "utils/string";

type ResetTokenInput =
  | {
      withRefresh: false;
      token: null;
    }
  | {
      withRefresh: true;
      token: string | null;
    };

export class AutopilotSocketManager {
  private static instance: AutopilotSocketManager;

  // ? Should we private this and reflect emit method ?
  public readonly socketManager: Socket;
  private usedToken: string | null = null;

  public isOpen = false;
  public isConnected = false;

  public get socketId(): string {
    return this.socketManager.id;
  }

  constructor() {
    this.socketManager = io(`https://${API_URL}:${API_PORT}`, {
      randomizationFactor: 0,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 500,
      reconnectionDelayMax: 550,
      timeout: 4000,
      transports: ["websocket"],

      // https://socket.io/docs/v4/migrating-from-2-x-to-3-0/#add-a-clear-distinction-between-the-manager-query-option-and-the-socket-query-option
      auth: {},
      query: {},
    });

    this.socketManager.io.on("reconnect_attempt", () => {
      this.socketManager.io.opts.transports = ["polling", "websocket"];
    });

    this.socketManager.io.on("open", () => {
      console.log(">>> Debug - socket manager open");
      this.isOpen = true;
    });
    this.socketManager.io.on("close", () => {
      console.log(">>> Debug - socket manager close");
      this.isOpen = false;
    });

    this.attachEvent();
  }

  public static getSocket(): Socket | null {
    if (!AutopilotSocketManager.instance) {
      AutopilotSocketManager.instance = new AutopilotSocketManager();
    }
    return AutopilotSocketManager.instance.socketManager;
  }

  public static getInstance(): AutopilotSocketManager {
    if (!AutopilotSocketManager.instance) {
      AutopilotSocketManager.instance = new AutopilotSocketManager();
    }
    return AutopilotSocketManager.instance;
  }

  public resetSocketQueryParameters = () => {
    this.socketManager.io.opts.query = {}; // Remove token from opts
    this.socketManager.auth = {}; // Remove token from auth
  };

  public disconnect = () => {
    this.socketManager.disconnect();
  };

  public connect = () => {
    this.socketManager.connect();
  };

  private attachEvent = () => {
    console.log(">>> re-attach event");

    this.socketManager.on("connect", () => {
      console.log(">>> Debug - socket connected");
      this.isConnected = true;
    });
    this.socketManager.on("disconnect", () => {
      console.log(">>> Debug - socket disconnected");
      this.isConnected = false;
    });
    this.socketManager.on("error", () => {
      console.log(">>> Debug - socket error");
    });
  };

  public checkSocketState = (
    token: string | null = null,
    userIdentity: IUserIdentity | null = null
  ): void => {
    console.log(">>> Debug - check socket state");

    if (!this.usedToken && this.socketManager.connected) {
      console.log("Debug - should be disconnected");
      this.socketManager.disconnect();
      return;
    }

    if (
      !validNotEmptyString(token) ||
      (this.usedToken && token && this.usedToken === token)
    ) {
      console.log(">>> Debug - should be connected");
      return;
    }

    if (userIdentity && token && !this.socketManager.connected) {
      this.refreshSocket(token);
    }
  };

  public resetUsedToken = (
    { withRefresh, token }: ResetTokenInput = {
      withRefresh: false,
      token: null,
    }
  ): void => {
    console.log(">>> Debug - reset used token");
    this.usedToken = null;

    if (withRefresh && token) {
      this.refreshSocket(token);
    }
  };

  public refreshSocket = (token: string): void => {
    console.log("Debug - recreate socket from token change");

    console.log(">>> Disconnect manager");

    this.socketManager.disconnect();

    console.log(">>> Rebind token the old way");
    // keep to backward compatibility purpose
    this.socketManager.io.opts.query = {
      ...this.socketManager.io.opts.query,
      token: token,
    };
    this.socketManager.auth = {
      token,
    };

    console.log(">>> enforce reconnection");
    this.socketManager.connect();

    this.usedToken = token;
  };
}
