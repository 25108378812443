import { Alert, AlertTitle, Typography } from "@mui/material";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type IntercepCalibrationErrorProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
};

const IntercepCalibrationError = ({
  sendAction,
}: IntercepCalibrationErrorProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  // const navigate = useNavigate();

  const navigateToHomePage = useCallback(
    () => {
      sendAction({
        type: "RESET",
        step: INTERCEP_CALIBRATION_STEP.IDLE,
      });
      // navigate(ROUTES.ROOT, { replace: true }),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Alert variant="filled" severity="error">
          <AlertTitle>{t("common:errorOccurred")}</AlertTitle>
          <Typography>{t("intercep:intercepModeCalibrationError")}</Typography>
        </Alert>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          onClick={navigateToHomePage}
        >
          {t("intercep:calibrationRestart")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationError;
