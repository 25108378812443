const DEFAULT_NUMBER = 1_000_000;
const NUMBER_REGEX = /\d+/;

export const getNumber = (name: string): number => {
  let number = DEFAULT_NUMBER;

  try {
    number = parseInt(name.match(NUMBER_REGEX)![0], 10);
  } catch {
    // Do nothing
  }

  return number;
};
