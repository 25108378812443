import {
  CustomDividerSupportedSize,
  GridContainer,
} from "./CustomDivider.styled";
import { Divider, Grid } from "@mui/material";

export interface ICustomDividerProps {
  size?: CustomDividerSupportedSize;
  transparent?: boolean; // Allow just givin space without showing the divider
  dividerVariant?: "inset" | "middle" | "fullWidth" | undefined;
}

const CustomDivider = ({
  size = "default",
  transparent = false,
  dividerVariant = "middle",
}: ICustomDividerProps = {}) => {
  return (
    <Grid container item alignItems="flex-start" justifyContent="center">
      <GridContainer item xs={12} size={size}>
        {transparent ? null : <Divider variant={dividerVariant} light />}
      </GridContainer>
    </Grid>
  );
};

export default CustomDivider;
