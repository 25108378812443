import { Drawer, styled } from "@mui/material";

import { drawerWidth } from "config/theme";

export const GuiDrawer = styled(Drawer, {
  name: "GuiDrawer",
})(() => ({
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
}));

export const DrawerHeader = styled("div", {
  name: "DrawerHeader",
})(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
