import AppDrawer from "./AppDrawer";
import InstallUpdateDialog from "components/dialogs/InstallUpdateDialog";
import NoConnectivity from "components/dialogs/NoConnectivity";
import SignOutDialog from "components/dialogs/SignOutDialog";
import SnackBar from "components/controls/SnackBar";

const AnyDialogContainer = () => {
  return (
    <>
      <SnackBar />
      <InstallUpdateDialog />
      <SignOutDialog />
      <NoConnectivity />
      <AppDrawer />
    </>
  );
};

export default AnyDialogContainer;
