import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useSetRecoilState } from "recoil";

import { useEffect } from "react";

const useIsDev = () => {
  const setIsDevEnv = useSetRecoilState<boolean>(
    selectors[ATOMS.IS_DEV_ENV] as RecoilState<boolean>
  );

  useEffect(() => {
    setIsDevEnv(
      process.env.REACT_APP_CYPRESS !== "true" &&
        process.env.NODE_ENV !== "production"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.NODE_ENV]);
};

export default useIsDev;
