import { API_PORT, API_URL } from "const";
import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import ky, { Options } from "ky";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { GuidanceParams } from "types/guidance";
import { IApiResponse } from "types/api";
import { IFirebaseUser } from "types/firebase";
import { IRobotStatusCode } from "types/statusCode";
import { ITurtleResponse } from "types/live";
import { IUserIdentity } from "types/user";
import { IWorkParameters } from "types/work";
import { useCallback } from "react";

const endpoint = `https://${API_URL}:${API_PORT}`;

const useHttpApi = () => {
  const socketToken = useRecoilValue<string | null>(
    selectors[ATOMS.UI_USER_TOKEN] as RecoilState<string | null>
  );
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const userIdentity = useRecoilValue<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );

  const getKyConfig = useCallback((): Options => {
    return {
      headers: { Authorization: `Bearer ${socketToken}` },
      throwHttpErrors: false,
      timeout: 60 * 1_000,
    };
  }, [socketToken]);

  const getContext = useCallback(() => {
    return {
      userId: fbUser?.uid || "",
      version: process.env.REACT_APP_VERSION ?? "",
      userLogin: userIdentity?.username ?? "",
    };
  }, [fbUser, userIdentity]);

  const moveUpdateTurtle = useCallback(
    async (kind: "init" | "switch"): Promise<boolean> => {
      const data = await ky
        .post(`${endpoint}/live/turtle`, {
          json: { kind, context: getContext() },
          ...getKyConfig(),
        })
        .json<ITurtleResponse>();
      return data.turtle;
    },
    [getKyConfig, getContext]
  );

  const releaseControl = useCallback(async (): Promise<{
    success: boolean;
    status: IRobotStatusCode;
  }> => {
    return await ky
      .post(`${endpoint}/take_control/request`, {
        json: {
          name: AutopilotSocketManager.getInstance().socketId,
          want_control: false,
          context: getContext(),
        },
        ...getKyConfig(),
      })
      .json();
  }, [getKyConfig, getContext]);

  const takeControl = useCallback(async (): Promise<{
    success: boolean;
    status: IRobotStatusCode;
  }> => {
    return await ky
      .post(`${endpoint}/take_control/request`, {
        json: {
          name: AutopilotSocketManager.getInstance().socketId,
          want_control: true,
          context: getContext(),
        },
        ...getKyConfig(),
      })
      .json();
  }, [getKyConfig, getContext]);

  const setRobotUser = useCallback(
    async (data: any): Promise<void> => {
      try {
        await ky.post(`${endpoint}/phone/user_connected`, {
          json: { ...data, context: getContext() },
          ...getKyConfig(),
        });
        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },
    [getKyConfig, getContext]
  );

  const startAutonomous = useCallback(async (): Promise<IApiResponse> => {
    return await ky.post(`${endpoint}/autonomous/start`, getKyConfig()).json();
  }, [getKyConfig]);

  const saveIncident = useCallback(
    async ({
      name = "GUI report",
      micro = false,
      temp = false,
    } = {}): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/incident/save`, {
          json: {
            name,
            micro,
            temp,
            context: getContext(),
          },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setIncidentDescription = useCallback(
    async (category: string, description: string): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/incident/set_description`, {
          json: {
            category,
            description,
            context: getContext(),
          },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setMap = useCallback(async (): Promise<IApiResponse> => {
    return await ky.get(`${endpoint}/parcel/check`, getKyConfig()).json();
  }, [getKyConfig]);

  const setToolsConfig = useCallback(
    async (config_name: string): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/tools/set_config`, {
          json: {
            config_name,
            context: getContext(),
          },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setIntercepsCorrections = useCallback(
    async (
      blade_torque: number,
      sensor_activate: boolean,
      sensor_torque: number
    ): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/tools/set_interceps_corrections`, {
          json: {
            blade_torque,
            sensor_activate,
            sensor_torque,
            context: getContext(),
          },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setMowersCorrections = useCallback(
    async (speed: number): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/tools/set_mowers_corrections`, {
          json: { speed, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setToolbarCorrections = useCallback(
    async (left: number, right: number): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/tools/set_toolbars_corrections`, {
          json: { left, right, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setUserToolbarFloatingMode = useCallback(
    async (floating_mode: boolean): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/tools/set_user_floating_mode`, {
          json: { floating_mode, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const generateWork = useCallback(
    async (workParams: IWorkParameters): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/work/generate`, {
          json: { ...workParams, context: getContext() },
          ...getKyConfig(),
          timeout: 60000, // 1 minute to allow long job generation
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setLateralCorrection = useCallback(
    async (value: number): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/work/set_lateral_correction`, {
          json: { value, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setMaxSpeedInAutonomous = useCallback(
    async (speed: number): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/work/set_max_speed`, {
          json: { speed, in_kmh: true, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setGuidanceParams = useCallback(
    async (params: GuidanceParams): Promise<IApiResponse> => {
      return await ky
        .post(`${endpoint}/intercep_mapping/set_config`, {
          json: { ...params, context: getContext() },
          ...getKyConfig(),
        })
        .json();
    },
    [getKyConfig, getContext]
  );

  const setIntercepCalibrationStep = useCallback(
    async (step: number, callback?: VoidFunction): Promise<IApiResponse> => {
      const response = await ky
        .post(`${endpoint}/tools/set_intercep_calibration_step`, {
          json: { to_state: step, context: getContext() },
          ...getKyConfig(),
        })
        .json<IApiResponse>();

      if (callback) callback();
      return response;
    },
    [getKyConfig, getContext]
  );

  return {
    httpRobotManager: {
      generateWork,
      moveUpdateTurtle,
      releaseControl,
      saveIncident,
      setGuidanceParams,
      setIncidentDescription,
      setIntercepCalibrationStep,
      setIntercepsCorrections,
      setLateralCorrection,
      setMap,
      setMaxSpeedInAutonomous,
      setMowersCorrections,
      setRobotUser,
      setToolbarCorrections,
      setToolsConfig,
      setUserToolbarFloatingMode,
      startAutonomous,
      takeControl,
    },
  };
};
export default useHttpApi;
