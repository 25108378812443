import { IconButton, styled } from "@mui/material";

import { borderRadius } from "utils/sharedStyles";

export const TurtleIconButton = styled(IconButton, {
  name: "TurtleIconButton",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  border: 2px solid ${!disabled ? theme.palette.primary.main : "#00000061"};
  border-radius: ${borderRadius}px;
`
);
