import { ATOMS, selectors } from "recoil/atoms";
import { ISelectedRobot, ISocketPayload } from "types/userRobot";
import {
  RecoilState,
  RecoilValue,
  Resetter,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  arrayDeserializer,
  mapDeserializer,
  putDateReviver,
} from "utils/reviver";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { getStamp, getStampFromDateRobot } from "utils/rostime";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { IFirebaseUser } from "types/firebase";
import { IStamp } from "types/emergency";
import { dbToolConfig } from "types/tool";
import { fireDatabase } from "config/firebase";

export const socketDataConfig = {
  fields: [
    { field: "autonomous", strategy: "DIRECT" },
    { field: "autopilot_version", strategy: "DIRECT" },
    { field: "bakus_wizard_version", strategy: "DIRECT" },
    { field: "battery_status", strategy: "DIRECT" },
    { field: "bumpers", strategy: "DIRECT" },
    { field: "bumpers_shunted", strategy: "DIRECT" },
    { field: "calibrating", strategy: "DIRECT" },
    { field: "can_perform_intercep_calibration", strategy: "DIRECT" },
    { field: "capabilities", strategy: "DIRECT" },
    { field: "current_controller", interval: 300, strategy: "DEBOUNCE" },
    { field: "current_emergency", interval: 500, strategy: "DEBOUNCE" },
    { field: "current_parcel", strategy: "DIRECT" },
    { field: "current_parcel_friendly_name", strategy: "DIRECT" },
    { field: "current_parcel_update_date", strategy: "DIRECT" },
    { field: "current_row", interval: 10000, strategy: "DEBOUNCE" },
    { field: "estop", strategy: "DIRECT" },
    { field: "gps", interval: 900, strategy: "THROTTLE" },
    { field: "guidance_config", strategy: "DEBOUNCE", interval: 200 }, // Since all state are on different topic on the robot side, we need to debounce to avoid too many updates
    { field: "guidance_mode_available", strategy: "DIRECT" },
    { field: "intercep_calibration_step", strategy: "DIRECT" },
    { field: "is_charging", strategy: "DIRECT" },
    { field: "lock_status", interval: 2000, strategy: "DEBOUNCE" },
    { field: "low_level_version_mismatch", strategy: "DIRECT" },
    { field: "map", strategy: "DIRECT" },
    { field: "parcel_has_highway", strategy: "DIRECT" },
    { field: "ready_to_drive", strategy: "DIRECT" },
    { field: "robot_id", strategy: "DIRECT" },
    { field: "robot_looking_up", strategy: "DIRECT" },
    { field: "robot_name", strategy: "DIRECT" },
    { field: "robot_type", strategy: "DIRECT" },
    { field: "toolbars_can_float", strategy: "DIRECT" },
    { field: "tools_config", strategy: "DIRECT" },
    { field: "tools_config_available", strategy: "DIRECT" },
    { field: "tools_interceps_corrections", strategy: "DIRECT" },
    { field: "tools_modes_available", strategy: "DIRECT" },
    { field: "tools_mowers_corrections", strategy: "DIRECT" },
    { field: "tools_present", strategy: "DIRECT" },
    { field: "tools_ready", strategy: "DIRECT" },
    { field: "tools_toolbars_corrections", strategy: "DIRECT" },
    { field: "work_done", strategy: "DIRECT" },
    { field: "work_id", strategy: "DIRECT" },
    { field: "work_lateral_correction", strategy: "DIRECT" },
    { field: "work_path", interval: 1000, strategy: "DEBOUNCE" },
    { field: "work_progress", interval: 5000, strategy: "THROTTLE" },
    { field: "work_speed", strategy: "DIRECT" },
    { field: "work_speed_status", strategy: "DIRECT" },
    { field: "work_work", strategy: "DIRECT" },
  ],
};

enum DataSource {
  SOCKET = "SOCKET",
  DATABASE = "DATABASE",
}

interface ISetReset {
  set: SetterOrUpdater<unknown>;
  reset: Resetter;
  reviver?: (arg0: any) => any;
}
export interface IDiscoveryPayload {
  robot_id: string;
  robot_name: string;
}

interface IStampedSource {
  stamp: IStamp;
  source: DataSource;
}
interface IRemoteData {
  field: string;
  payload: any;
  stamp: IStamp;
  source: DataSource; // Useful in case of same date happened
}

interface IDenialData {
  denied: boolean;
  reason?: string;
}

const checkDenial = (
  stored: IStampedSource,
  incoming: IRemoteData
): IDenialData => {
  if (stored.stamp.secs > incoming.stamp.secs) {
    return {
      denied: true,
      reason: `secs -> ${stored.stamp.secs} > ${incoming.stamp.secs}`,
    };
  }
  if (
    stored.stamp.secs === incoming.stamp.secs &&
    stored.stamp.nsecs > incoming.stamp.nsecs
  ) {
    return {
      denied: true,
      reason: `nsecs -> ${stored.stamp.nsecs} > ${incoming.stamp.nsecs}`,
    };
  }
  if (
    stored.stamp.secs === incoming.stamp.secs &&
    stored.stamp.nsecs === incoming.stamp.nsecs &&
    stored.source !== incoming.source &&
    stored.source === DataSource.SOCKET
  ) {
    return {
      denied: true,
      reason: `trust socket first`,
    };
  }
  return {
    denied: false,
  };
};

const useRobotData = () => {
  const subscribers = useRef<(() => void)[]>([]);
  const fieldUpdateDate = useRef<Map<string, IStampedSource>>(
    new Map<string, IStampedSource>()
  );

  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);

  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilValue<IFirebaseUser | null>
  );
  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilValue<ISelectedRobot>
  );

  const setAutonomous = useSetRecoilState(selectors[ATOMS.AUTONOMOUS]);
  const setAutopilotVersion = useSetRecoilState(
    selectors[ATOMS.AUTOPILOT_VERSION]
  );
  const setBakusWizardVersion = useSetRecoilState(
    selectors[ATOMS.BAKUS_WIZARD_VERSION]
  );
  const setBatteryStatus = useSetRecoilState(selectors[ATOMS.BATTERY_STATUS]);
  const setBumpersShunted = useSetRecoilState(selectors[ATOMS.BUMPERS_SHUNTED]);
  const setBumpers = useSetRecoilState(selectors[ATOMS.BUMPERS]);
  const setCalibrating = useSetRecoilState(selectors[ATOMS.CALIBRATING]);
  const setCanPerformIntercepCalibration = useSetRecoilState(
    selectors[ATOMS.CAN_PERFORM_INTERCEP_CALIBRATION]
  );
  const setCapabilities = useSetRecoilState(selectors[ATOMS.CAPABILITIES]);
  const setCurrentController = useSetRecoilState(
    selectors[ATOMS.CURRENT_CONTROLLER]
  );
  const setCurrentEmergency = useSetRecoilState(
    selectors[ATOMS.CURRENT_EMERGENCY]
  );
  const setCurrentParcel = useSetRecoilState(selectors[ATOMS.CURRENT_PARCEL]);
  const setCurrentParcelFriendlyName = useSetRecoilState(
    selectors[ATOMS.CURRENT_PARCEL_FRIENDLY_NAME]
  );
  const setCurrentParcelUpdateDate = useSetRecoilState(
    selectors[ATOMS.CURRENT_PARCEL_UPDATE_DATE]
  );
  const setCurrentRow = useSetRecoilState(selectors[ATOMS.CURRENT_ROW]);
  const setEstop = useSetRecoilState(selectors[ATOMS.ESTOP]);
  const setGps = useSetRecoilState(selectors[ATOMS.GPS]);
  const setGuidanceConfig = useSetRecoilState(selectors[ATOMS.GUIDANCE_CONFIG]);
  const setGuidanceModeAvailable = useSetRecoilState(
    selectors[ATOMS.GUIDANCE_MODE_AVAILABLE]
  );
  const setIntercepCalibrationStep = useSetRecoilState(
    selectors[ATOMS.INTERCEP_CALIBRATION_STEP]
  );
  const setIsCharging = useSetRecoilState(selectors[ATOMS.IS_CHARGING]);
  const setLockStatus = useSetRecoilState(selectors[ATOMS.LOCK_STATUS]);
  const setLowLevelVersionMismatch = useSetRecoilState(
    selectors[ATOMS.LOW_LEVEL_VERSION_MISMATCH]
  );
  const setMap = useSetRecoilState(selectors[ATOMS.MAP]);
  const setParcelHasHighway = useSetRecoilState(
    selectors[ATOMS.PARCEL_HAS_HIGHWAY]
  );
  const setReadyToDrive = useSetRecoilState(selectors[ATOMS.READY_TO_DRIVE]);
  const setRobotId = useSetRecoilState(selectors[ATOMS.ROBOT_ID]);
  const setRobotLookingUp = useSetRecoilState(
    selectors[ATOMS.ROBOT_LOOKING_UP]
  );
  const setRobotName = useSetRecoilState(selectors[ATOMS.ROBOT_NAME]);
  const setRobotType = useSetRecoilState(selectors[ATOMS.ROBOT_TYPE]);
  const setToolbarsCanFloat = useSetRecoilState(
    selectors[ATOMS.TOOLBARS_CAN_FLOAT]
  );
  const setToolsConfig = useSetRecoilState(selectors[ATOMS.TOOLS_CONFIG]);
  const setToolsConfigAvailable = useSetRecoilState(
    selectors[ATOMS.TOOLS_CONFIG_AVAILABLE]
  );
  const setToolsIntercepsCorrections = useSetRecoilState(
    selectors[ATOMS.TOOLS_INTERCEPS_CORRECTIONS]
  );
  const setToolsModesAvailable = useSetRecoilState(
    selectors[ATOMS.TOOLS_MODES_AVAILABLE]
  );
  const setToolsMowersCorrections = useSetRecoilState(
    selectors[ATOMS.TOOLS_MOWERS_CORRECTIONS]
  );
  const setToolsPresent = useSetRecoilState(selectors[ATOMS.TOOLS_PRESENT]);
  const setToolsReady = useSetRecoilState(selectors[ATOMS.TOOLS_READY]);
  const setToolsToolbarsCorrections = useSetRecoilState(
    selectors[ATOMS.TOOLS_TOOLBARS_CORRECTIONS]
  );
  const setWorkDone = useSetRecoilState(selectors[ATOMS.WORK_DONE]);
  const setWorkId = useSetRecoilState(selectors[ATOMS.WORK_ID]);
  const setWorkLateralCorrection = useSetRecoilState(
    selectors[ATOMS.WORK_LATERAL_CORRECTION]
  );
  const setWorkPath = useSetRecoilState(selectors[ATOMS.WORK_PATH]);
  const setWorkProgress = useSetRecoilState(selectors[ATOMS.WORK_PROGRESS]);
  const setWorkSpeed = useSetRecoilState(selectors[ATOMS.WORK_SPEED]);
  const setWorkSpeedStatus = useSetRecoilState(
    selectors[ATOMS.WORK_SPEED_STATUS]
  );
  const setWorkWork = useSetRecoilState(selectors[ATOMS.WORK_WORK]);

  const resetAutonomous = useResetRecoilState(selectors[ATOMS.AUTONOMOUS]);
  const resetAutopilotVersion = useResetRecoilState(
    selectors[ATOMS.AUTOPILOT_VERSION]
  );
  const resetBakusWizardVersion = useResetRecoilState(
    selectors[ATOMS.BAKUS_WIZARD_VERSION]
  );
  const resetBatteryStatus = useResetRecoilState(
    selectors[ATOMS.BATTERY_STATUS]
  );
  const resetBumpersShunted = useResetRecoilState(
    selectors[ATOMS.BUMPERS_SHUNTED]
  );
  const resetBumpers = useResetRecoilState(selectors[ATOMS.BUMPERS]);
  const resetCalibrating = useResetRecoilState(selectors[ATOMS.CALIBRATING]);
  const resetCanPerformIntercepCalibration = useResetRecoilState(
    selectors[ATOMS.CAN_PERFORM_INTERCEP_CALIBRATION]
  );
  const resetCapabilities = useResetRecoilState(selectors[ATOMS.CAPABILITIES]);
  const resetCurrentController = useResetRecoilState(
    selectors[ATOMS.CURRENT_CONTROLLER]
  );
  const resetCurrentEmergency = useResetRecoilState(
    selectors[ATOMS.CURRENT_EMERGENCY]
  );
  const resetCurrentParcel = useResetRecoilState(
    selectors[ATOMS.CURRENT_PARCEL]
  );
  const resetCurrentParcelFriendlyName = useResetRecoilState(
    selectors[ATOMS.CURRENT_PARCEL_FRIENDLY_NAME]
  );
  const resetCurrentParcelUpdateDate = useResetRecoilState(
    selectors[ATOMS.CURRENT_PARCEL_UPDATE_DATE]
  );
  const resetCurrentRow = useResetRecoilState(selectors[ATOMS.CURRENT_ROW]);
  const resetEstop = useResetRecoilState(selectors[ATOMS.ESTOP]);
  const resetGps = useResetRecoilState(selectors[ATOMS.GPS]);
  const resetGuidanceConfig = useResetRecoilState(
    selectors[ATOMS.GUIDANCE_CONFIG]
  );
  const resetGuidanceModeAvailable = useResetRecoilState(
    selectors[ATOMS.GUIDANCE_MODE_AVAILABLE]
  );
  const resetIntercepCalibrationStep = useResetRecoilState(
    selectors[ATOMS.INTERCEP_CALIBRATION_STEP]
  );
  const resetIsCharging = useResetRecoilState(selectors[ATOMS.IS_CHARGING]);
  const resetLockStatus = useResetRecoilState(selectors[ATOMS.LOCK_STATUS]);
  const resetLowLevelVersionMismatch = useResetRecoilState(
    selectors[ATOMS.LOW_LEVEL_VERSION_MISMATCH]
  );
  const resetMap = useResetRecoilState(selectors[ATOMS.MAP]);
  const resetParcelHasHighway = useResetRecoilState(
    selectors[ATOMS.PARCEL_HAS_HIGHWAY]
  );
  const resetReadyToDrive = useResetRecoilState(
    selectors[ATOMS.READY_TO_DRIVE]
  );
  const resetRobotId = useResetRecoilState(selectors[ATOMS.ROBOT_ID]);
  const resetRobotLookingUp = useResetRecoilState(
    selectors[ATOMS.ROBOT_LOOKING_UP]
  );
  const resetRobotName = useResetRecoilState(selectors[ATOMS.ROBOT_NAME]);
  const resetRobotType = useResetRecoilState(selectors[ATOMS.ROBOT_TYPE]);
  const resetToolbarsCanFloat = useResetRecoilState(
    selectors[ATOMS.TOOLBARS_CAN_FLOAT]
  );
  const resetToolsConfig = useResetRecoilState(selectors[ATOMS.TOOLS_CONFIG]);
  const resetToolsConfigAvailable = useResetRecoilState(
    selectors[ATOMS.TOOLS_CONFIG_AVAILABLE]
  );
  const resetToolsIntercepsCorrections = useResetRecoilState(
    selectors[ATOMS.TOOLS_INTERCEPS_CORRECTIONS]
  );
  const resetToolsModesAvailable = useResetRecoilState(
    selectors[ATOMS.TOOLS_MODES_AVAILABLE]
  );
  const resetToolsMowersCorrections = useResetRecoilState(
    selectors[ATOMS.TOOLS_MOWERS_CORRECTIONS]
  );
  const resetToolsPresent = useResetRecoilState(selectors[ATOMS.TOOLS_PRESENT]);
  const resetToolsReady = useResetRecoilState(selectors[ATOMS.TOOLS_READY]);
  const resetToolsToolbarsCorrections = useResetRecoilState(
    selectors[ATOMS.TOOLS_TOOLBARS_CORRECTIONS]
  );
  const resetWorkDone = useResetRecoilState(selectors[ATOMS.WORK_DONE]);
  const resetWorkId = useResetRecoilState(selectors[ATOMS.WORK_ID]);
  const resetWorkLateralCorrection = useResetRecoilState(
    selectors[ATOMS.WORK_LATERAL_CORRECTION]
  );
  const resetWorkPath = useResetRecoilState(selectors[ATOMS.WORK_PATH]);
  const resetWorkProgress = useResetRecoilState(selectors[ATOMS.WORK_PROGRESS]);
  const resetWorkSpeed = useResetRecoilState(selectors[ATOMS.WORK_SPEED]);
  const resetWorkSpeedStatus = useResetRecoilState(
    selectors[ATOMS.WORK_SPEED_STATUS]
  );
  const resetWorkWork = useResetRecoilState(selectors[ATOMS.WORK_WORK]);

  const [wsRobotID, setWsRobotID] = useRecoilState<string>(
    selectors[ATOMS.WS_ROBOT_ID] as RecoilState<string>
  );
  const setWsRobotDiscovery = useSetRecoilState<IDiscoveryPayload | null>(
    selectors[ATOMS.WS_ROBOT_DISCOVERY] as RecoilState<IDiscoveryPayload | null>
  );
  const resetWsRobotId = useResetRecoilState(selectors[ATOMS.WS_ROBOT_ID]);
  const resetWsRobotDiscovery = useResetRecoilState(
    selectors[ATOMS.WS_ROBOT_DISCOVERY]
  );

  const dataProcessor = useMemo<{
    [Key: string]: ISetReset;
  }>(
    () => ({
      autonomous: { set: setAutonomous, reset: resetAutonomous },
      autopilot_version: {
        set: setAutopilotVersion,
        reset: resetAutopilotVersion,
      },
      bakus_wizard_version: {
        set: setBakusWizardVersion,
        reset: resetBakusWizardVersion,
      },
      battery_status: { set: setBatteryStatus, reset: resetBatteryStatus },
      bumpers: { set: setBumpers, reset: resetBumpers },
      bumpers_shunted: { set: setBumpersShunted, reset: resetBumpersShunted },
      calibrating: { set: setCalibrating, reset: resetCalibrating },
      can_perform_intercep_calibration: {
        set: setCanPerformIntercepCalibration,
        reset: resetCanPerformIntercepCalibration,
      },
      capabilities: { set: setCapabilities, reset: resetCapabilities },
      current_controller: {
        set: setCurrentController,
        reset: resetCurrentController,
      },
      current_emergency: {
        set: setCurrentEmergency,
        reset: resetCurrentEmergency,
      },
      current_parcel: { set: setCurrentParcel, reset: resetCurrentParcel },
      current_parcel_friendly_name: {
        set: setCurrentParcelFriendlyName,
        reset: resetCurrentParcelFriendlyName,
      },
      current_parcel_update_date: {
        set: setCurrentParcelUpdateDate,
        reset: resetCurrentParcelUpdateDate,
      },
      current_row: { set: setCurrentRow, reset: resetCurrentRow },
      estop: { set: setEstop, reset: resetEstop },
      gps: { set: setGps, reset: resetGps },
      guidance_config: {
        set: setGuidanceConfig,
        reset: resetGuidanceConfig,
        reviver: putDateReviver,
      },
      guidance_mode_available: {
        set: setGuidanceModeAvailable,
        reset: resetGuidanceModeAvailable,
      },
      intercep_calibration_step: {
        set: setIntercepCalibrationStep,
        reset: resetIntercepCalibrationStep,
      },
      is_charging: { set: setIsCharging, reset: resetIsCharging },
      lock_status: { set: setLockStatus, reset: resetLockStatus },
      low_level_version_mismatch: {
        set: setLowLevelVersionMismatch,
        reset: resetLowLevelVersionMismatch,
      },
      map: { set: setMap, reset: resetMap, reviver: mapDeserializer },
      parcel_has_highway: {
        set: setParcelHasHighway,
        reset: resetParcelHasHighway,
      },
      ready_to_drive: { set: setReadyToDrive, reset: resetReadyToDrive },
      robot_id: { set: setRobotId, reset: resetRobotId },
      robot_looking_up: {
        set: setRobotLookingUp,
        reset: resetRobotLookingUp,
      },
      robot_name: { set: setRobotName, reset: resetRobotName },
      robot_type: { set: setRobotType, reset: resetRobotType },
      toolbars_can_float: {
        set: setToolbarsCanFloat,
        reset: resetToolbarsCanFloat,
      },
      tools_config: { set: setToolsConfig, reset: resetToolsConfig },
      tools_config_available: {
        set: setToolsConfigAvailable,
        reset: resetToolsConfigAvailable,
      },
      tools_interceps_corrections: {
        set: setToolsIntercepsCorrections,
        reset: resetToolsIntercepsCorrections,
      },
      tools_modes_available: {
        set: setToolsModesAvailable,
        reset: resetToolsModesAvailable,
      },
      tools_mowers_corrections: {
        set: setToolsMowersCorrections,
        reset: resetToolsMowersCorrections,
      },
      tools_present: { set: setToolsPresent, reset: resetToolsPresent },
      tools_ready: { set: setToolsReady, reset: resetToolsReady },
      tools_toolbars_corrections: {
        set: setToolsToolbarsCorrections,
        reset: resetToolsToolbarsCorrections,
      },
      work_done: {
        set: setWorkDone,
        reset: resetWorkDone,
        reviver: arrayDeserializer,
      },
      work_id: { set: setWorkId, reset: resetWorkId },
      work_lateral_correction: {
        set: setWorkLateralCorrection,
        reset: resetWorkLateralCorrection,
      },
      work_path: {
        set: setWorkPath,
        reset: resetWorkPath,
        reviver: arrayDeserializer,
      },
      work_progress: { set: setWorkProgress, reset: resetWorkProgress },
      work_speed: { set: setWorkSpeed, reset: resetWorkSpeed },
      work_speed_status: {
        set: setWorkSpeedStatus,
        reset: resetWorkSpeedStatus,
      },
      work_work: {
        set: setWorkWork,
        reset: resetWorkWork,
        reviver: arrayDeserializer,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ); //TODO

  const handleRemoteData = useCallback(
    (data: IRemoteData) => {
      if (fieldUpdateDate.current.has(data.field)) {
        const stored = fieldUpdateDate.current.get(
          data.field
        ) as IStampedSource;
        const denial = checkDenial(stored, data);
        if (denial.denied) {
          console.log("New data denied for field", data.field, denial.reason);
          return;
        }
      }

      fieldUpdateDate.current.set(data.field, {
        source: data.source,
        stamp: data.stamp,
      });

      if (data.source === DataSource.SOCKET && data.field === "robot_id") {
        setWsRobotID(data.payload);
      }

      if (Object.keys(dataProcessor).includes(data.field)) {
        const handler = dataProcessor[data.field];
        let futureData = data.payload;
        if (handler.reviver !== null && handler.reviver !== undefined) {
          futureData = handler.reviver(futureData);
        }
        handler.set(futureData);
      }
    },
    [dataProcessor, setWsRobotID]
  );

  const handleSocketRobotStatus = useCallback((data: ISocketPayload) => {
    if (
      data.namespace === null ||
      data.namespace === undefined ||
      data.namespace === "" ||
      data.field === null ||
      data.field === undefined ||
      data.field === "" ||
      data.payload === null ||
      data.payload === undefined
    ) {
      return;
    }

    if (data.field === "initial_state") {
      Object.entries(data.payload).forEach(([field, payload]) =>
        handleRemoteData({
          source: DataSource.SOCKET,
          stamp: data.stamp,
          field,
          payload,
        })
      );
    } else {
      handleRemoteData({
        source: DataSource.SOCKET,
        stamp: data.stamp,
        field: data.field,
        payload: data.payload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketConnected === true) {
      AutopilotSocketManager.getInstance().socketManager.emit(
        "bakus:discovery",
        (data: IDiscoveryPayload) => {
          setWsRobotDiscovery(data);
        }
      );

      AutopilotSocketManager.getInstance().socketManager.on(
        "robot_status",
        handleSocketRobotStatus
      );

      return () => {
        AutopilotSocketManager.getInstance().socketManager.off("robot_status");
        resetWsRobotDiscovery();
        resetWsRobotId();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected]);

  useEffect(() => {
    if (
      wsRobotID !== null &&
      wsRobotID !== undefined &&
      wsRobotID.length !== 0
    ) {
      return;
    }
    if (fbUser && selectedRobot.id) {
      const subscriberToolsConfig = onSnapshot(
        query(
          collection(fireDatabase, "tools_config"),
          where("available_for", "array-contains", selectedRobot.id)
        ),
        (snap) => {
          let tools_config: dbToolConfig[] = [];
          if (snap.size > 0) {
            tools_config = snap.docs.map((doc) => doc.data() as dbToolConfig);
          }
          handleRemoteData({
            source: DataSource.DATABASE,
            field: "tools_config_available",
            payload: tools_config,
            stamp: getStamp(),
          });
        }
      );

      subscribers.current.push(subscriberToolsConfig);

      socketDataConfig.fields.forEach((fieldInfo) => {
        const subscriber = onSnapshot(
          doc(
            fireDatabase,
            `robots/${selectedRobot.id}/last_status/${fieldInfo.field}`
          ),
          (snap) => {
            const wrappedData = snap.data();
            if (wrappedData !== null && wrappedData !== undefined) {
              handleRemoteData({
                source: DataSource.DATABASE,
                field: fieldInfo.field,
                payload: wrappedData.value,
                stamp: getStampFromDateRobot(
                  wrappedData.date_robot,
                  wrappedData.drs
                ),
              });
            }
          }
        );

        subscribers.current.push(subscriber);
      });
      return () => {
        subscribers.current.forEach((sub) => sub());
        subscribers.current = [];
      };
    }
  }, [fbUser, handleRemoteData, selectedRobot, wsRobotID]);
};

export default useRobotData;
