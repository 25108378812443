import { TextField, Typography, styled } from "@mui/material";

export const ConfigTextField = styled(TextField, {
  name: "ConfigTextField",
})(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)}
`
);

export const ConfigItemName = styled(Typography, {
  name: "ConfigItemName",
  shouldForwardProp: (prop) => prop !== "equipped",
})<{ equipped: boolean }>(
  ({ theme, equipped }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${equipped ? theme.palette.text.primary : theme.palette.text.disabled};
`
);
