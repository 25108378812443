import { ATOMS, selectors } from "recoil/atoms";
import {
  IFirebaseRobotSimpleEntry,
  IFirebaseUser,
  IFirebaseUserData,
} from "types/firebase";
import {
  RecoilState,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { doc, onSnapshot } from "firebase/firestore";

import { fireDatabase } from "config/firebase";
import { useEffect } from "react";

let connecterUserSnapshot: (() => void) | null = null;

const deregisterConnectedUserListener = () => {
  if (connecterUserSnapshot !== null && connecterUserSnapshot !== undefined) {
    connecterUserSnapshot();
    connecterUserSnapshot = null;
  }
};

const registerConnectedUserListener = (
  firebaseUser: IFirebaseUser,
  setter: {
    setRobotsAvailable: SetterOrUpdater<any>;
    setUserAccess: SetterOrUpdater<string[]>;
    setUiLang: SetterOrUpdater<string>;
  } = {
    setRobotsAvailable: () => {},
    setUserAccess: () => {},
    setUiLang: () => {},
  }
) => {
  deregisterConnectedUserListener(); // By default, deregister before register another just in case
  connecterUserSnapshot = onSnapshot(
    doc(fireDatabase, `users/${firebaseUser.uid}`),
    (snap) => {
      if (snap.exists()) {
        const userData = snap.data() as IFirebaseUserData;
        setter.setRobotsAvailable(userData.robot_enum ?? []);
        setter.setUserAccess(userData.access_roles ?? []);
        setter.setUiLang(userData.lang ?? "fr");
      }
    }
  );
};

const useConnectedUser = () => {
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );

  const setRobotsAvailable = useSetRecoilState<IFirebaseRobotSimpleEntry[]>(
    selectors[ATOMS.ROBOTS_AVAILABLE] as RecoilState<
      IFirebaseRobotSimpleEntry[]
    >
  );
  const setUserAccess = useSetRecoilState<string[]>(
    selectors[ATOMS.USER_ACCESS] as RecoilState<string[]>
  );
  const setUiLang = useSetRecoilState<string>(
    selectors[ATOMS.UI_LANG] as RecoilState<string>
  );

  useEffect(() => {
    if (fbUser) {
      registerConnectedUserListener(fbUser, {
        setRobotsAvailable,
        setUserAccess,
        setUiLang,
      });
      return () => deregisterConnectedUserListener();
    } else {
      setRobotsAvailable([]);
      setUserAccess([]);
      // setUiLang("fr");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser]);
};

export default useConnectedUser;
