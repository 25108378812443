import {
  AnyEventObject,
  BaseActionObject,
  Event,
  EventData,
  ResolveTypegenMeta,
  SCXML,
  ServiceMap,
  SingleOrArray,
  State,
  TypegenDisabled,
  createMachine,
} from "xstate";

export type TIntercepCalibrationMachineSendActionFn = (
  event: SCXML.Event<AnyEventObject> | SingleOrArray<Event<AnyEventObject>>,
  payload?: EventData | undefined
) => State<
  unknown,
  AnyEventObject,
  any,
  {
    value: any;
    context: unknown;
  },
  ResolveTypegenMeta<
    TypegenDisabled,
    AnyEventObject,
    BaseActionObject,
    ServiceMap
  >
>;

// visualize it with https://stately.ai/viz
export const intercepCalibrationMachine = createMachine({
  id: "intercep_sensor_calibration",
  initial: "idle",
  states: {
    idle: {
      on: {
        CLIENT_START: {
          target: "send_start",
          // step: INTERCEP_CALIBRATION_STEP.STARTED,
        },
        ROBOT_ON_STEP_1: { target: "robot_step_1" },
        ROBOT_ON_STEP_2: { target: "robot_step_2" },
        ROBOT_ON_STEP_3: { target: "gui_step_3" },
        ROBOT_ON_STEP_4: { target: "robot_step_4" },
        ROBOT_ON_STEP_5: { target: "robot_step_5" },
        ROBOT_ON_STEP_6: { target: "gui_step_6" },
        ERROR: { target: "error" },
      },
    },
    send_start: {
      invoke: {
        id: "sendCalibrationStart",
        src: "sendCalibrationStep",
        onDone: { target: "robot_step_1" },
        onError: { target: "error" },
      },
    },
    robot_step_1: {
      on: {
        AU_OFF: {
          target: "send_init",
          // step: INTERCEP_CALIBRATION_STEP.INITIALIZING,
        },
        ERROR: { target: "error" },
      },
    },
    send_init: {
      invoke: {
        id: "sendCalibrationInitializing",
        src: "sendCalibrationStep",
        onDone: { target: "robot_step_2" },
        onError: { target: "error" },
      },
    },
    robot_step_2: {
      on: {
        NEXT: { target: "gui_step_3" },
        ERROR: { target: "error" },
      },
    },
    gui_step_3: {
      on: {
        AU_ON: { target: "gui_step_3_1" },
        ERROR: { target: "error" },
      },
    },
    gui_step_3_1: {
      on: {
        MECHANICAL_DEPLOYED: { target: "gui_step_3_2" },
        ERROR: { target: "error" },
      },
    },
    gui_step_3_2: {
      on: {
        AU_OFF: {
          target: "send_calibration",
          // step: INTERCEP_CALIBRATION_STEP.CALIBRATION,
        },
        ERROR: { target: "error" },
      },
    },
    send_calibration: {
      invoke: {
        id: "sendStartCalibration",
        src: "sendCalibrationStep",
        onDone: { target: "robot_step_4" },
        onError: { target: "error" },
      },
    },
    robot_step_4: {
      on: { NEXT: { target: "robot_step_5" }, ERROR: { target: "error" } },
    },
    robot_step_5: {
      on: { NEXT: { target: "gui_step_6" }, ERROR: { target: "error" } },
    },
    gui_step_6: {
      on: { AU_ON: { target: "gui_step_6_1" }, ERROR: { target: "error" } },
    },
    gui_step_6_1: {
      on: {
        MECHANICAL_FOLDED: { target: "gui_step_6_2" },
        ERROR: { target: "error" },
      },
    },
    gui_step_6_2: {
      on: { AU_OFF: { target: "gui_step_6_3" }, ERROR: { target: "error" } },
    },
    gui_step_6_3: {
      on: {
        CALIBRATION_FINISHED: {
          target: "reset_calibration",
          // step: INTERCEP_CALIBRATION_STEP.IDLE,
        },
        ERROR: { target: "error" },
      },
    },
    reset_calibration: {
      invoke: {
        id: "resetCalibration",
        src: "sendCalibrationStep",
        onDone: { target: "idle" },
        onError: { target: "error" },
      },
    },
    error: {
      on: {
        RESET: { target: "reset_calibration" },
      },
      // type: "final",
    },
  },
});
