import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { BatteryAlert } from "@mui/icons-material";
import { IAlertData } from "types/alert";
import { IGuiConfig } from "types/guiConfig";
import { makeId } from "utils/string";
import { useTranslation } from "react-i18next";

const usePhoneInfo = () => {
  const { t } = useTranslation(["common", "live"]);
  const remoteConfig = useRecoilValue<IGuiConfig>(
    selectors[ATOMS.USERS_CONFIG] as RecoilState<IGuiConfig>
  );

  const [batteryError, setBatteryError] = useState<IAlertData | null>(null);
  const [batteryLevel, setBatteryLevel] = useState(100);

  useEffect(() => {
    const batteryInterval = setInterval(() => {
      if (typeof FullyKiosk !== "undefined") {
        // eslint-disable-next-line no-undef
        const foufi = fully;
        const batteryLevel = foufi.getBatteryLevel() ?? -1;

        setBatteryLevel(parseInt(batteryLevel, 10));
      } else {
        setBatteryLevel(100);
      }
    }, 5000);
    return () => {
      clearInterval(batteryInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (batteryLevel <= remoteConfig.requiredBatteryLevelForManuelControl) {
      setBatteryError({
        id: makeId(),
        helper: t("common:chargePhone"),
        icon: <BatteryAlert />,
        severity: "error",
        text: t("live:unableToTakeControlBecauseOfBatteryLevel", {
          batteryLevel: remoteConfig.requiredBatteryLevelForManuelControl,
        }),
        title: t("common:phoneBattery"),
      });
    } else {
      setBatteryError(null);
    }
  }, [batteryLevel, remoteConfig, t]);

  return { batteryLevel, batteryError };
};

export default usePhoneInfo;
