import { API_ORDERS, BOTTOM_PANE_CONTENT, ROLE_DEV_ONLY } from "const";
import { ATOMS, selectors } from "recoil/atoms";
import { Badge, Divider, SvgIcon, Typography } from "@mui/material";
import {
  BugReport,
  ExitToApp,
  Memory,
  PermDeviceInformation,
  Person,
  SettingsRemote,
  Update,
} from "@mui/icons-material";
import { DevContainer, DevSpan } from "styled/Dev";
import { IFirebaseRobotSimpleEntry, IFirebaseUser } from "types/firebase";
import {
  RecoilState,
  RecoilValue,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import BakusIcon from "resources/icon/bakus";
import ElementBar from "components/controls/ElementBar";
import { IBakusVersion } from "types/version";
import { ISelectedRobot } from "types/userRobot";
import { ISnackbarContent } from "types/snackbar";
import { IUserIdentity } from "types/user";
import LangSelector from "./Drawer/LangSelector";
import ParameterOpener from "./Drawer/ParameterOpener";
import { VersionSocketManager } from "managers/VersionSocket";
import { isDefined } from "utils/object";
import { nameCleaner } from "utils/name";
import useGetStatusByCode from "hooks/statusCodes/useGetStatusByCode";
import useHasAccess from "hooks/user/useHasAccess";
import usePaneManager from "hooks/ui/usePaneManager";
import useRobotOrder from "hooks/robot/useRobotOrder";
import { useTranslation } from "react-i18next";
import { validNotEmptyString } from "utils/string";

const DrawerMenu = () => {
  const { t } = useTranslation(["common", "version"]);
  const { showPane } = usePaneManager();

  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);
  const versionSocketConnected = useRecoilValue(
    selectors[ATOMS.VERSION_WS_CONNECTED]
  );
  const shouldShowVersionUpdateNotification = useRecoilValue<boolean>(
    selectors[
      ATOMS.VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION
    ] as RecoilState<boolean>
  );
  const shouldShowLowLevelVersionUpdateMenu = useRecoilValue<boolean>(
    selectors[ATOMS.UI_DRAWER_SHOW_LOW_LEVEL_UPDATE] as RecoilState<boolean>
  );

  const [selectedRobot, setSelectedRobot] = useRecoilState<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );

  const bakusVersion = useRecoilValue<IBakusVersion | null>(
    selectors[
      ATOMS.ROBOT_ACTUAL_BAKUS_VERSION
    ] as RecoilValue<IBakusVersion | null>
  );

  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const userIdentity = useRecoilValue<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );
  const robotsAvailable = useRecoilValue<IFirebaseRobotSimpleEntry[]>(
    selectors[ATOMS.ROBOTS_AVAILABLE] as RecoilState<
      IFirebaseRobotSimpleEntry[]
    >
  );

  const isDevEnv = useRecoilValue<boolean>(
    selectors[ATOMS.IS_DEV_ENV] as RecoilState<boolean>
  );
  const setSignOutDialogOpen = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_SIGN_OUT_DIALOG_OPEN] as RecoilState<boolean>
  );

  const setSnackbar = useSetRecoilState<Partial<ISnackbarContent>>(
    selectors[ATOMS.SNACKBAR] as RecoilState<Partial<ISnackbarContent>>
  );
  const setDrawerOpen = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_DRAWER_OPEN] as RecoilState<boolean>
  );
  const isDev = useHasAccess("all", ROLE_DEV_ONLY);

  const sendOrder = useRobotOrder();

  const getStatus = useGetStatusByCode();

  const goGdi = () => {
    window.location.href = "http://bakus/";
  };

  const getUserTile = () => {
    if (userIdentity && userIdentity.username) {
      return (
        <ElementBar
          trailing={<Person color="primary" />}
          title={
            <Typography noWrap>{nameCleaner(userIdentity.username)}</Typography>
          }
        />
      );
    }

    return null;
  };

  const handleLogInOut = () => {
    setDrawerOpen(false);
    setSignOutDialogOpen(true);
  };

  const handleVersionUpdate = () => {
    showPane(BOTTOM_PANE_CONTENT.version_update);
    setDrawerOpen(false);
  };

  const handleLowLevelVersionUpdate = () => {
    showPane(BOTTOM_PANE_CONTENT.low_level_version_update);
    setDrawerOpen(false);
  };

  const handleBugReport = async () => {
    try {
      const response = await sendOrder(API_ORDERS.IncidentSave, {
        name: "GUI report",
      });
      if (response) {
        if (response.success === true) {
          setSnackbar({ content: t("common:informationRegistered") });
          showPane(BOTTOM_PANE_CONTENT.bug_report);
        } else {
          let errorMessage: string | JSX.Element =
            getStatus(response.status.code).message ||
            t("common:errorOccurred");

          if (isDev && response.status.code && response.status.message) {
            errorMessage = (
              <>
                {errorMessage}
                <br />
                <DevSpan>
                  {response.status.code}: {response.status.message}
                </DevSpan>
              </>
            );
          }
          setSnackbar({
            content: errorMessage,
            duration: 4000,
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          content: t("common:noResponseFromService"),
          duration: 4000,
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        content: t("common:errorOccurred"),
        duration: 4000,
        severity: "error",
      });
    }
  };

  return (
    <div id="drawer">
      <Divider />
      {getUserTile()}
      {userIdentity && fbUser && robotsAvailable.length > 1 ? (
        <ElementBar
          trailing={
            <SvgIcon viewBox="0 0 68.03 68.03" color="primary">
              <path d={BakusIcon} />
            </SvgIcon>
          }
          onClick={() => {
            setSelectedRobot({});
            setDrawerOpen(false);
          }}
          title={<Typography noWrap>{t("common:myBakus")}</Typography>}
        />
      ) : null}
      {/* <ElementBar  title="Guide utilisateur" trailing={<LocalLibraryIcon color="primary" />} /> */}
      <ElementBar
        id="menu-connect-item"
        data-cy="login-btn"
        trailing={
          fbUser ? <ExitToApp color="primary" /> : <Person color="primary" />
        }
        title={userIdentity ? t("common:logout") : t("common:login")}
        onClick={() => handleLogInOut()}
      />
      <Divider />
      {/* {selectedRobot.id && (socketConnected === true || fbUser) ? ( */}
      {selectedRobot.id && userIdentity ? (
        <>
          <ElementBar
            data-cy="signal-problem-btn"
            onClick={() => {
              setDrawerOpen(false);
              handleBugReport();
            }}
            trailing={<BugReport color="primary" />}
            title={t("common:signalProblem")}
          />
        </>
      ) : null}
      {userIdentity !== null ? (
        <>
          <ElementBar
            onClick={handleVersionUpdate}
            trailing={
              shouldShowVersionUpdateNotification ? (
                <Badge variant="dot" color="error">
                  <Update color="primary" />
                </Badge>
              ) : (
                <Update color="primary" />
              )
            }
            title={t("version:drawerMenuTitle")}
          />
          {shouldShowLowLevelVersionUpdateMenu ? (
            <ElementBar
              onClick={handleLowLevelVersionUpdate}
              trailing={
                <Badge variant="dot" color="error">
                  <Memory color="primary" />
                </Badge>
              }
              title={t("version:drawerMenuTitleLowLevel")}
            />
          ) : null}
        </>
      ) : null}
      {/* <ListItem button onClick={handleDashboard}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Robot" />
      </ListItem>
      <ListItem button onClick={handleSettings}>
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary="Paramètre" />
      </ListItem> */}
      {isDevEnv ? (
        <>
          <DevContainer data-cy="drawer-menu-dev-container">
            <ElementBar
              onClick={() => {
                if (socketConnected) {
                  AutopilotSocketManager.getInstance().disconnect();
                } else {
                  AutopilotSocketManager.getInstance().connect();
                }
              }}
              trailing={<SettingsRemote />}
              title={`${socketConnected ? "Disconnect" : "Connect"} socket`}
            />
          </DevContainer>
        </>
      ) : null}
      {isDevEnv ? (
        <>
          <DevContainer data-cy="drawer-menu-dev-container">
            <ElementBar
              onClick={() => {
                if (versionSocketConnected) {
                  VersionSocketManager.getInstance().disconnect();
                } else {
                  VersionSocketManager.getInstance().connect();
                }
              }}
              trailing={<SettingsRemote />}
              title={`${
                versionSocketConnected ? "Disconnect" : "Connect"
              } version socket`}
            />
          </DevContainer>
        </>
      ) : null}
      {isDev ? (
        <DevContainer>
          <ElementBar
            onClick={goGdi}
            trailing={<ExitToApp />}
            title={t("common:accessGDI")}
          />
        </DevContainer>
      ) : null}

      {userIdentity !== null &&
      isDefined(selectedRobot) &&
      validNotEmptyString(selectedRobot.id) ? (
        <ParameterOpener />
      ) : (
        <LangSelector inDrawer={true} />
      )}

      <ElementBar
        style={{ position: "absolute", bottom: 0 }}
        trailing={<PermDeviceInformation color="primary" />}
        title={<Typography variant="body1">{t("common:versions")}</Typography>}
        subline={
          <>
            <Typography variant="body2" component="span" display="block">
              Bakus:
              <Typography
                variant="inherit"
                component="span"
                fontWeight="bold"
                ml={0.5}
              >
                {bakusVersion?.version ?? t("common:unknown")}
              </Typography>
            </Typography>
            <Typography variant="body2" component="span" display="block">
              {t("common:interface")}:
              <Typography
                variant="inherit"
                component="span"
                fontWeight="bold"
                ml={0.5}
              >
                {process.env.REACT_APP_VERSION ?? t("common:unknown")}
              </Typography>
            </Typography>
          </>
        }
        dense
        leadingInteractive={false}
        leadingFlex
      />
    </div>
  );
};

export default DrawerMenu;
