import { useEffect, useState } from "react";

import Splash from "components/Splash/Splash";
import VitibotLogo from "resources/logo/logo_carre.png";

const SplashScreen = ({
  children,
}: {
  children: JSX.Element | JSX.Element;
}): JSX.Element => {
  const [openSplash, setOpenSplash] = useState(true);

  useEffect(() => {
    new Image().src = VitibotLogo; // Ugly preloading //Not sure if useful in release mode
    setTimeout(() => {
      setOpenSplash(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (openSplash) {
    return <Splash />;
  }
  return <>{children}</>;
};

export default SplashScreen;
