import { CSSObject, Theme } from "@mui/material";

export const borderSize = 5;
export const borderRadius = 10;
export const progressBarBorderRadius = 1;

export const visibleMixin = (theme: Theme): CSSObject => ({
  opacity: 1,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const hiddenMixin = (theme: Theme): CSSObject => ({
  opacity: 0,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const sharedStyles = {
  highlight: {
    backgroundColor: "red",
  },
  highlightRed: {
    backgroundColor: "white",
    borderColor: "white",
    color: "red",
  },
  highlightWhite: {
    backgroundColor: "red",
    borderColor: "red",
    color: "white",
  },
  highlightImportant: {
    backgroundColor: "red !important",
  },
  rounded: {
    borderRadius: 4,
  },
  buttonBarRadius: {
    "& > *": {
      borderRadius: 0,
    },
    "& > :first-child": {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    },
    "& > :last-child": {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
  hidden: {
    display: "none",
  },
};

export default sharedStyles;
