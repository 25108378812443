import { IWorkConfigEnum, availableWorkConfigEntry } from "const";
import { useEffect, useState } from "react";

import { LiveDataEvent } from "managers/LiveDataManager";
import { secureOrder } from "utils/live";
import useLiveData from "hooks/live/useLiveData";

const liveFields: LiveDataEvent[] = [
  LiveDataEvent.ToolsStatusMowerSpeedLeft,
  LiveDataEvent.ToolsStatusMowerSpeedRight,
  LiveDataEvent.ToolsStatusMowerWorkStatusLeft,
  LiveDataEvent.ToolsStatusMowerWorkStatusRight,
];

const useMowerLiveHandlingManager = () => {
  const {
    mowerSpeedLeft: leftCurrentSpeed,
    mowerSpeedRight: rightCurrentSpeed,
    mowerWorkStatusLeft: leftCurrentMode,
    mowerWorkStatusRight: rightCurrentMode,
  } = useLiveData(liveFields, "useMowerLiveHandlingManager");

  // Requested mode
  const [leftRequestToRobotMode, setLeftRequestToRobotMode] =
    useState<availableWorkConfigEntry>(
      secureOrder(leftCurrentMode as availableWorkConfigEntry)
    );
  const [rightRequestToRobotMode, setRightRequestToRobotMode] =
    useState<availableWorkConfigEntry>(
      secureOrder(rightCurrentMode as availableWorkConfigEntry)
    );

  // Requested speed
  const [leftRequestToRobotSpeed, setLeftRequestToRobotSpeed] =
    useState<number>(leftCurrentSpeed);
  const [rightRequestToRobotSpeed, setRightRequestToRobotSpeed] =
    useState<number>(rightCurrentSpeed);

  useEffect(() => {
    if (leftRequestToRobotMode === IWorkConfigEnum.STOP) {
      setLeftRequestToRobotSpeed(0);
    }
  }, [leftRequestToRobotMode]);

  useEffect(() => {
    if (rightRequestToRobotMode === IWorkConfigEnum.STOP) {
      setRightRequestToRobotSpeed(0);
    }
  }, [rightRequestToRobotMode]);

  return {
    leftCurrentMode,
    rightCurrentMode,

    leftCurrentSpeed,
    rightCurrentSpeed,

    leftRequestToRobotMode,
    setLeftRequestToRobotMode,
    rightRequestToRobotMode,
    setRightRequestToRobotMode,

    leftRequestToRobotSpeed,
    setLeftRequestToRobotSpeed,
    rightRequestToRobotSpeed,
    setRightRequestToRobotSpeed,
  };
};

export default useMowerLiveHandlingManager;
