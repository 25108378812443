import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilState, useRecoilValue } from "recoil";
import { doc, onSnapshot } from "firebase/firestore";

import { IFirebaseUser } from "types/firebase";
import { IGuiConfig } from "types/guiConfig";
import { fireDatabase } from "config/firebase";
import { useEffect } from "react";

const useConfig = () => {
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const [usersConfig, setUserConfig] = useRecoilState<IGuiConfig>(
    selectors[ATOMS.USERS_CONFIG] as RecoilState<IGuiConfig>
  );

  useEffect(() => {
    if (fbUser) {
      const unsub = onSnapshot(doc(fireDatabase, "gui/config"), (snapshot) => {
        if (snapshot.exists()) {
          console.log("Receive config from remote");
          // Let to remote config the ability to be partial
          setUserConfig(Object.assign({ ...usersConfig }, snapshot.data()));
        } else {
          console.log("Remote config does not exist, default value are used!");
        }
      });
      return () => {
        unsub();
      };
    } else {
      console.log(
        "Unable to retrieve config, User is not logged in, default value are used!"
      );
      // setUserConfig({}) // Never overwrite default value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser]);
};

export default useConfig;
