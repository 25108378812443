import { useEffect, useState } from "react";

import Turtle from "resources/icon/turtle";
import { TurtleIconButton } from "./TurtleSwitch.styled";
import useHttpApi from "hooks/network/useHttpApi";
import { useTheme } from "@mui/material";

interface ITurtleSwitch {
  disabled: boolean;
  setEnabledAngle: (enabled: boolean) => void;
}

const TurtleSwitch = (props: ITurtleSwitch) => {
  const theme = useTheme();

  const { httpRobotManager } = useHttpApi();

  const [inTurtle, setInTurtle] = useState(false);

  useEffect(() => {
    httpRobotManager.moveUpdateTurtle("init").then((newTurtle) => {
      setInTurtle(newTurtle);
      props.setEnabledAngle(!newTurtle); // If in turtle, disable angle
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTurtleSwitch = async () => {
    props.setEnabledAngle(false); // Disable angle while switching
    const newTurtle = await httpRobotManager.moveUpdateTurtle("switch");
    setInTurtle(newTurtle);
    setTimeout(() => {
      if (!newTurtle) {
        props.setEnabledAngle(true); // Enable angle if not in turtle
      }
    }, 1000);
  };

  return (
    <TurtleIconButton
      data-cy="turtle-mode-switch"
      onClick={handleTurtleSwitch}
      disabled={props.disabled}
      size="large"
    >
      <Turtle
        fontSize={120}
        arrowColor={
          props.disabled
            ? "#00000061"
            : inTurtle
            ? theme.palette.primary.main
            : "#CFCECE"
        }
        bakusColor={props.disabled ? "#00000061" : theme.palette.primary.main}
      />
    </TurtleIconButton>
  );
};

export default TurtleSwitch;
