import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

import { progressBarBorderRadius } from "utils/sharedStyles";

export const LinearProgressWithLabel = (
  props: LinearProgressProps & {
    value: number;
    error?: boolean;
    height: number;
  }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: props.height,
            borderRadius: progressBarBorderRadius,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary" textAlign="right">
          {props.error === true ? "Erreur" : `${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
