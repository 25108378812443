import { IWorkConfigEnum, availableWorkConfigEntry } from "const";
import LiveDataManager, { LiveDataEvent } from "managers/LiveDataManager";
import { useCallback, useEffect, useState } from "react";

import { ILiveStatus } from "types/live";

const useLiveData = (fields: LiveDataEvent[], component = "unknown") => {
  const [initialized, setInitialized] = useState(
    LiveDataManager.getInstance().initialized
  );
  const [initialData, setInitialData] = useState<ILiveStatus | null>(
    LiveDataManager.getInstance().status
  );
  // Move
  const [angularAngle, setAngularAngle] = useState<number>(
    LiveDataManager.getInstance().angularAngle
  );
  const [lateralAngle, setLateralAngle] = useState<number>(
    LiveDataManager.getInstance().lateralAngle
  );

  // Tools const
  const [toolsConstTop, setToolsConstTop] = useState<number>(
    LiveDataManager.getInstance().toolsConstTop
  );
  const [toolsConstBottom, setToolsConstBottom] = useState<number>(
    LiveDataManager.getInstance().toolsConstBottom
  );

  // Tools status
  const [interceptWorkStatusLeft, setInterceptWorkStatusLeft] =
    useState<availableWorkConfigEntry>(
      LiveDataManager.getInstance()
        .toolsStatusInterceptWorkStatusLeft as availableWorkConfigEntry
    );
  const [interceptWorkStatusRight, setInterceptWorkStatusRight] =
    useState<availableWorkConfigEntry>(
      LiveDataManager.getInstance()
        .toolsStatusInterceptWorkStatusRight as availableWorkConfigEntry
    );
  const [interceptOutsideWorkStatusLeft, setInterceptOutsideWorkStatusLeft] =
    useState<availableWorkConfigEntry>(
      LiveDataManager.getInstance()
        .toolsStatusInterceptOutsideWorkStatusLeft as availableWorkConfigEntry
    );
  const [interceptOutsideWorkStatusRight, setInterceptOutsideWorkStatusRight] =
    useState<availableWorkConfigEntry>(
      LiveDataManager.getInstance()
        .toolsStatusInterceptOutsideWorkStatusRight as availableWorkConfigEntry
    );
  const [toolsStatusLeft, setToolsStatusLeft] = useState<number>(
    LiveDataManager.getInstance().toolsStatusLeft
  );
  const [toolsStatusRight, setToolsStatusRight] = useState<number>(
    LiveDataManager.getInstance().toolsStatusRight
  );
  const [mowerSpeedLeft, setMowerSpeedLeft] = useState<number>(
    LiveDataManager.getInstance().toolsStatusMowerSpeedLeft
  );
  const [mowerSpeedRight, setMowerSpeedRight] = useState<number>(
    LiveDataManager.getInstance().toolsStatusMowerSpeedRight
  );
  const [mowerWorkStatusLeft, setMowerWorkStatusLeft] =
    useState<IWorkConfigEnum>(
      LiveDataManager.getInstance().toolsStatusMowerWorkStatusLeft
    );
  const [mowerWorkStatusRight, setMowerWorkStatusRight] =
    useState<IWorkConfigEnum>(
      LiveDataManager.getInstance().toolsStatusMowerWorkStatusRight
    );

  // Turtle mode
  const [turtleMode, setTurtleMode] = useState<boolean>(false);

  const updateData = useCallback((data: ILiveStatus) => {
    console.log("updateData global", component, data);
    // Update all states
    // Move
    setAngularAngle(data.move.angular_angle);
    setLateralAngle(data.move.lateral_angle);

    // Tools const
    setToolsConstTop(data.tools_const.TOP);
    setToolsConstBottom(data.tools_const.BOTTOM);

    // Tools status
    setInterceptWorkStatusLeft(
      data.tools_status.intercep_work_status_left as availableWorkConfigEntry
    );
    setInterceptWorkStatusRight(
      data.tools_status.intercep_work_status_right as availableWorkConfigEntry
    );
    setInterceptOutsideWorkStatusLeft(
      data.tools_status
        .intercep_outside_work_status_left as availableWorkConfigEntry
    );
    setInterceptOutsideWorkStatusRight(
      data.tools_status
        .intercep_outside_work_status_right as availableWorkConfigEntry
    );
    setToolsStatusLeft(data.tools_status.tools_status_left);
    setToolsStatusRight(data.tools_status.tools_status_right);
    setMowerSpeedLeft(data.tools_status.mower_speed_left);
    setMowerSpeedRight(data.tools_status.mower_speed_right);
    setMowerWorkStatusLeft(
      data.tools_status.mower_work_status_left as IWorkConfigEnum
    );
    setMowerWorkStatusRight(
      data.tools_status.mower_work_status_right as IWorkConfigEnum
    );

    // Turtle mode
    setTurtleMode(data.turtle_mode);

    setInitialData(data);
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDataField = useCallback((field: LiveDataEvent) => {
    return (data: unknown) => {
      console.log("updateDataField", component, field, data);
      switch (field) {
        case LiveDataEvent.Init:
          return;
        // Move
        case LiveDataEvent.MoveAngular:
          setAngularAngle(data as number);
          break;
        case LiveDataEvent.MoveLateral:
          setLateralAngle(data as number);
          break;

        // Tools const
        case LiveDataEvent.ToolsConstTop:
          setToolsConstTop(data as number);
          break;
        case LiveDataEvent.ToolsConstBottom:
          setToolsConstBottom(data as number);
          break;

        // Tools status
        case LiveDataEvent.ToolsStatusInterceptWorkStatusLeft:
          setInterceptWorkStatusLeft(data as availableWorkConfigEntry);
          break;
        case LiveDataEvent.ToolsStatusInterceptWorkStatusRight:
          setInterceptWorkStatusRight(data as availableWorkConfigEntry);
          break;
        case LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusLeft:
          setInterceptOutsideWorkStatusLeft(data as availableWorkConfigEntry);
          break;
        case LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusRight:
          setInterceptOutsideWorkStatusRight(data as availableWorkConfigEntry);
          break;
        case LiveDataEvent.ToolsStatusLeft:
          setToolsStatusLeft(data as number);
          break;
        case LiveDataEvent.ToolsStatusRight:
          setToolsStatusRight(data as number);
          break;
        case LiveDataEvent.ToolsStatusMowerSpeedLeft:
          setMowerSpeedLeft(data as number);
          break;
        case LiveDataEvent.ToolsStatusMowerSpeedRight:
          setMowerSpeedRight(data as number);
          break;
        case LiveDataEvent.ToolsStatusMowerWorkStatusLeft:
          setMowerWorkStatusLeft(data as IWorkConfigEnum);
          break;
        case LiveDataEvent.ToolsStatusMowerWorkStatusRight:
          setMowerWorkStatusRight(data as IWorkConfigEnum);
          break;

        // Turtle mode
        case LiveDataEvent.TurtleMode:
          setTurtleMode(data as boolean);
          break;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LiveDataManager.getInstance().on(LiveDataEvent.Init, updateData);

    return () => {
      LiveDataManager.getInstance().removeListener(
        LiveDataEvent.Init,
        updateData
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fields.forEach((field) => {
      if (field === LiveDataEvent.Init) {
        return;
      }
      LiveDataManager.getInstance().on(field, updateDataField(field));
    });

    return () => {
      fields.forEach((field) => {
        // Note sure this will work :D
        LiveDataManager.getInstance().removeListener(
          field,
          updateDataField(field)
        );
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return {
    initialized,
    initialData,
    angularAngle,
    lateralAngle,
    toolsConstTop,
    toolsConstBottom,
    interceptWorkStatusLeft,
    interceptWorkStatusRight,
    interceptOutsideWorkStatusLeft,
    interceptOutsideWorkStatusRight,
    toolsStatusLeft,
    toolsStatusRight,
    mowerSpeedLeft,
    mowerSpeedRight,
    mowerWorkStatusLeft,
    mowerWorkStatusRight,
    turtleMode,
  };
};

export default useLiveData;
