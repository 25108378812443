import { getAnalytics, initializeAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { initializePerformance } from "firebase/performance";

const config = {
  firebase_bkps: {
    apiKey: "AIzaSyAzUGzke2rgFP9pd3fu35YMsBmMfWO5tSE",
    appId: "1:648295389413:web:dcff8d344290a275",
    authDomain: "vitibot-bkps.firebaseapp.com",
    databaseURL: "https://vitibot-bkps.firebaseio.com",
    measurementId: "G-KMMN09700B",
    messagingSenderId: "648295389413",
    projectId: "vitibot-bkps",
    storageBucket: "vitibot-bkps.appspot.com",
  },
  firebase_dev: {
    apiKey: "AIzaSyDwGC4ParxzNwiyCgwZ4BZ4kfirp_un9wY",
    appId: "1:217910399660:web:48a84c58918110a09fc509",
    authDomain: "vitibot-web-services-dev.firebaseapp.com",
    databaseURL: "https://vitibot-web-services-dev.firebaseio.com",
    messagingSenderId: "217910399660",
    projectId: "vitibot-web-services-dev",
    storageBucket: "vitibot-web-services-dev.appspot.com",
  },
};

const dev =
  process.env.REACT_APP_DEV_BUILD === "true" ||
  process.env.NODE_ENV !== "production";

const firebaseApp = initializeApp(
  dev ? config.firebase_dev : config.firebase_bkps
);

// @ts-ignore
if (window.Cypress) {
  console.log(" >>> Force cypress config <<<");
  initializeFirestore(firebaseApp, {
    experimentalForceLongPolling: true,
    // ssl: false,
  });
}

if (!dev) {
  initializeAnalytics(firebaseApp);
  initializePerformance(firebaseApp);
}

export const fireDatabase = getFirestore(firebaseApp);
export const fireFunction = getFunctions(firebaseApp);
export const fireAuth = getAuth(firebaseApp);
export const fireAnalytics = getAnalytics(firebaseApp);

export default firebaseApp;
