import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useSetRecoilState } from "recoil";

import { BOTTOM_PANE_CONTENT } from "const";
import ElementBar from "../ElementBar";
import { Settings } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ParameterOpener = () => {
  const { t } = useTranslation(["common"]);

  const setDrawerOpen = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_DRAWER_OPEN] as RecoilState<boolean>
  );
  const setShowBottomPane = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_SHOW_BOTTOM_PANE] as RecoilState<boolean>
  );
  const setBottomPaneContent = useSetRecoilState<BOTTOM_PANE_CONTENT>(
    selectors[
      ATOMS.UI_SHOW_BOTTOM_PANE_CONTENT
    ] as RecoilState<BOTTOM_PANE_CONTENT>
  );

  return (
    <ElementBar
      data-cy="drawer-item-settings-btn"
      trailing={<Settings color="primary" />}
      title={t("common:settings")}
      onClick={() => {
        setBottomPaneContent(BOTTOM_PANE_CONTENT.parameter);
        setShowBottomPane(true);
        setDrawerOpen(false);
      }}
    />
  );
};

export default ParameterOpener;
