import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import { Suspense, lazy, useMemo, useState } from "react";

import FixedSpinner from "components/Global/FixedSpinner";
import { IDiscoveryPayload } from "hooks/robot/useRobotData";
import { IFirebaseRobotSimpleEntry } from "types/firebase";
import { ISelectedRobot } from "types/userRobot";
import NoRobot from "./NoRobot";
import { getNumber } from "utils/robot";
import { isDefined } from "utils/object";
import useSelectRobot from "hooks/robot/useSelectRobot";
import { validNotEmptyString } from "utils/string";

const SingleRobotHome = lazy(() => import("./SingleRobotHome"));
const MultiRobotHome = lazy(() => import("./MultiRobotHome"));

// Quand on arrive ici, on est forcément authentifié, c'est ici que va se faire la sélection de la page à afficher en fonction des datas de l'utilisateur
const GuiScaffold = () => {
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);
  const robotsAvailable = useRecoilValue<IFirebaseRobotSimpleEntry[]>(
    selectors[ATOMS.ROBOTS_AVAILABLE] as RecoilState<
      IFirebaseRobotSimpleEntry[]
    >
  );
  const wsRobotId = useRecoilValue<string>(
    selectors[ATOMS.WS_ROBOT_ID] as RecoilState<string>
  );
  const wsRobotDiscovery = useRecoilValue<IDiscoveryPayload | null>(
    selectors[ATOMS.WS_ROBOT_DISCOVERY] as RecoilState<IDiscoveryPayload | null>
  );
  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );

  const [currentRobotList, setCurrentRobotList] = useState<
    IFirebaseRobotSimpleEntry[]
  >([]);

  const { selectRobot: dispatchSelection } = useSelectRobot();

  useMemo(() => {
    // logged
    let possibleRobot = [...robotsAvailable];

    if (socketConnected) {
      const onSocketRobot: string[] = [];
      if (validNotEmptyString(wsRobotId)) {
        onSocketRobot.push(wsRobotId);
      }
      if (
        isDefined(wsRobotDiscovery) &&
        validNotEmptyString(wsRobotDiscovery?.robot_id)
      ) {
        onSocketRobot.push(wsRobotDiscovery?.robot_id as string);
      }

      possibleRobot = possibleRobot.map((thisPossibleRobot) => {
        return {
          ...thisPossibleRobot,
          socket: onSocketRobot.includes(thisPossibleRobot.id),
        };
      });

      // device is not in this authorized list
      if (
        selectedRobot.id &&
        robotsAvailable &&
        robotsAvailable.findIndex(
          (thisRobot) => selectedRobot.id === thisRobot.id
        ) === -1
      ) {
        possibleRobot.push({
          id: selectedRobot.id,
          name: selectedRobot.name as string,
          socket: true,
        });
      }
    }

    if (possibleRobot.length === 0) {
      console.log("no robot");
      dispatchSelection({});
      return;
    }

    if (possibleRobot.length === 1) {
      dispatchSelection(possibleRobot[0]);
      return;
    }

    if (
      validNotEmptyString(selectedRobot.id) &&
      possibleRobot.some((r) => r.id === selectedRobot.id)
    ) {
      // robot is already selected and still available
      return;
    }

    /**
     * Sort by robot number (0 -> 1 -> ... -> 9)
     */
    possibleRobot.sort((a, b) => {
      return getNumber(a.name) - getNumber(b.name);
    });

    /**
     * Sort by socket (active robot)
     */
    possibleRobot.sort((a, b) => {
      if (a.socket !== b.socket) {
        if (a.socket) return -1;
        if (b.socket) return 1;
      }
      return 0;
    });

    console.log("Reset selected robot here");
    dispatchSelection({});

    setCurrentRobotList(possibleRobot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    robotsAvailable,
    socketConnected,
    selectedRobot,
    wsRobotId,
    wsRobotDiscovery,
  ]);

  console.log("hasRobot", {
    selectedRobot,
    l: currentRobotList.length,
  });

  if (
    currentRobotList.length === 0 &&
    (!isDefined(selectedRobot) || !validNotEmptyString(selectedRobot.id))
  ) {
    return <NoRobot />;
  }

  if (isDefined(selectedRobot) && validNotEmptyString(selectedRobot.id)) {
    return (
      <Suspense fallback={<FixedSpinner />}>
        <SingleRobotHome />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<FixedSpinner />}>
      <MultiRobotHome robotList={currentRobotList} />
    </Suspense>
  );
};

export default GuiScaffold;
