import FixedSpinner from "components/Global/FixedSpinner";
import { IntercepCalibrationPageBodyContent } from "pages/IntercepCalibrationPage.styled";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { validNotEmptyString } from "utils/string";

type IntercepCalibrationIntercepLoadingStepProps = {
  text?: string;
};

const IntercepCalibrationIntercepLoadingStep = ({
  text,
}: IntercepCalibrationIntercepLoadingStepProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Typography textAlign="center">
          {validNotEmptyString(text)
            ? text
            : `${t("common:loadingInProgress")}...`}
        </Typography>
        <FixedSpinner />
      </IntercepCalibrationPageBodyContent>
    </>
  );
};

export default IntercepCalibrationIntercepLoadingStep;
