import { ATOMS, selectors } from "recoil/atoms";
import { BOTTOM_PANE_CONTENT, LIVE_PANE_CONTENT } from "const";
import {
  IUpdateBakusVersion,
  IUpdateLowLevelVersion,
  UpdaterStatus,
} from "types/version";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { useCallback } from "react";

type PaneStrategyProps = {
  forceCloseBugReport: boolean;
};

const defaultPaneStrategyProps: PaneStrategyProps = {
  forceCloseBugReport: false,
};

const usePaneManager = () => {
  const setShowBottomPane = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_SHOW_BOTTOM_PANE] as RecoilState<boolean>
  );
  const [bottomPaneContent, setBottomPaneContent] =
    useRecoilState<BOTTOM_PANE_CONTENT>(
      selectors[
        ATOMS.UI_SHOW_BOTTOM_PANE_CONTENT
      ] as RecoilState<BOTTOM_PANE_CONTENT>
    );

  const setLivePaneContent = useSetRecoilState<LIVE_PANE_CONTENT>(
    selectors[ATOMS.UI_LIVE_PANE_CONTENT] as RecoilState<LIVE_PANE_CONTENT>
  );

  const robotUpdateVersion = useRecoilValue<IUpdateBakusVersion>(
    selectors[ATOMS.VERSION_ROBOT_DATA] as RecoilState<IUpdateBakusVersion>
  );
  const robotUpdateLowLevelVersion = useRecoilValue<IUpdateLowLevelVersion>(
    selectors[
      ATOMS.VERSION_ROBOT_LOW_LEVEL_DATA
    ] as RecoilState<IUpdateLowLevelVersion>
  );

  const shouldBlockNavigation = useCallback(
    (strategy: PaneStrategyProps) => {
      switch (bottomPaneContent) {
        case BOTTOM_PANE_CONTENT.version_update:
          return robotUpdateVersion.global.status === UpdaterStatus.UPDATING;
        case BOTTOM_PANE_CONTENT.low_level_version_update:
          return robotUpdateLowLevelVersion.global === UpdaterStatus.UPDATING;
        case BOTTOM_PANE_CONTENT.bug_report:
          if (strategy.forceCloseBugReport) {
            return false;
          }
          return true;
        default:
          return false;
      }
    },
    [bottomPaneContent, robotUpdateVersion, robotUpdateLowLevelVersion]
  );

  const showPane = useCallback(
    (content: BOTTOM_PANE_CONTENT, props: Partial<PaneStrategyProps> = {}) => {
      const strategy = { ...defaultPaneStrategyProps, ...props };
      if (shouldBlockNavigation(strategy)) return;

      setBottomPaneContent(content);
      setShowBottomPane(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldBlockNavigation]
  );

  const hidePane = useCallback(
    (props: Partial<PaneStrategyProps> = {}) => {
      const strategy = { ...defaultPaneStrategyProps, ...props };

      if (shouldBlockNavigation(strategy)) return;

      setShowBottomPane(false);
      setBottomPaneContent(BOTTOM_PANE_CONTENT.none); // None is the default content
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldBlockNavigation]
  );

  const hideIfOnThisPane = useCallback(
    (content: BOTTOM_PANE_CONTENT, props: Partial<PaneStrategyProps> = {}) => {
      const strategy = { ...defaultPaneStrategyProps, ...props };
      console.log("hideIfOnThisPane", { content, bottomPaneContent, strategy });
      if (bottomPaneContent === content) hidePane(strategy);
    },
    [bottomPaneContent, hidePane]
  );

  const showLivePane = (content: LIVE_PANE_CONTENT) => {
    setLivePaneContent(content);
  };

  const resetLivePane = () => {
    setLivePaneContent(LIVE_PANE_CONTENT.move);
  };

  return {
    showPane,
    hidePane,
    hideIfOnThisPane,
    showLivePane,
    resetLivePane,
  };
};

export default usePaneManager;
