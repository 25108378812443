import { GetRecoilValue, RecoilState } from "recoil";
import { avoidLogKey, filterLogKeys } from "recoil/atoms";

import { ISnackbarContent } from "types/snackbar";
import { deepEqual } from "utils/object";

const diffCheck = (newObj: any, old: any) => {
  if (!newObj) {
    if (old) return Object.keys(old);
    return [];
  }
  if (!old) return Object.keys(newObj);
  const changed: string[] = [];
  Object.keys(newObj).forEach((key) => {
    try {
      // JSON comparison is quicker but object can be not stringified
      if (JSON.stringify(old[key]) !== JSON.stringify(newObj[key])) {
        changed.push(key);
      }
    } catch {
      // Fallback on a deepEqual technic
      if (!deepEqual(old[key], newObj[key])) {
        changed.push(key);
      }
    }
  });
  return changed;
};

const logger = (
  field: string,
  content: any,
  prev: any,
  changed: string[] = []
) => {
  if (process.env.REACT_APP_DEV_BUILD === "true") {
    try {
      console.log(`update ${field}`);
      console.log(`prevState ${JSON.stringify(prev)}`);
      console.log(`state ${JSON.stringify(content)}`);
      console.log(`changed ${JSON.stringify(changed)}`);
    } catch {
      // do nothing
    }
    return;
  }

  console.groupCollapsed("update", field);
  try {
    console.log(
      "%cprevState  ",
      "color: #fc9700",
      JSON.parse(JSON.stringify(prev))
    );
    console.log(
      "%cstate  ",
      "color: #03A9F4",
      JSON.parse(JSON.stringify(content))
    );
    console.groupCollapsed("%cchanged", "color: #db1212", changed);
    console.groupEnd();
  } catch {
    // do nothing
  }
  console.groupEnd();
};

export const createSnackBarContent = ({
  content = "",
  severity = "info",
  duration = 6000,
  open = true,
  onCloseCallback = () => {},
  position = "top-center",
}: Partial<ISnackbarContent>): ISnackbarContent => {
  return {
    content,
    duration,
    onCloseCallback,
    open,
    position,
    severity,
  };
};

export const handleLoggingAtom = (
  key: string,
  getter: GetRecoilValue,
  atom: RecoilState<any>,
  value: any
) => {
  if (
    process.env.NODE_ENV !== "production" ||
    process.env.REACT_APP_DEV_BUILD === "true"
  ) {
    if (Object.keys(avoidLogKey).includes(key)) {
      const evaluator = avoidLogKey[key];
      let shouldLog = true;
      if (
        evaluator.current !== 0 &&
        evaluator.current % evaluator.every !== 0
      ) {
        shouldLog = false;
      }
      evaluator.current++;
      avoidLogKey[key] = evaluator;
      if (!shouldLog) {
        return;
      }
    }

    if (
      !filterLogKeys ||
      (filterLogKeys && filterLogKeys.length === 0) ||
      (filterLogKeys && filterLogKeys.length > 0 && filterLogKeys.includes(key))
    ) {
      const newAtomValue = getter(atom);
      const diff = diffCheck(value, newAtomValue);
      logger(key, value, newAtomValue, diff);
    }
  }
  // }
};

enum dataKey {
  USER_CREDENTIALS = "user_credentials",
  STATUS_CODE = "status_code",
}

const replacer = (_: any, value: any): any => {
  return value;
};

const reviver = (_: any, value: any) => {
  return value;
};

export const extractFromStorage = (key: string, defaultValue: any) => {
  console.log(`Try to access ${key} from localStorage`);
  const value = window.localStorage.getItem(key);
  if (value === null || value === undefined) {
    console.log(`${key} not found in localStorage`);
    return defaultValue;
  }
  try {
    return JSON.parse(value, reviver);
  } catch (error) {
    console.log("Unable to read data");
  }
  return defaultValue;
};
export const putToStorage = (key: string, value: any) => {
  console.log(`Write ${key} into localStorage`, { key, value });
  window.localStorage.setItem(key, JSON.stringify(value, replacer));
};

export const clearStorage = () => {
  console.log(`Clear localStorage`);
  window.localStorage.clear();
};

export const storeUtils = {
  dataKey,
  extractFromStorage,
  putToStorage,
  clearStorage,
  replacer,
  reviver,
};
