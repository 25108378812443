import { ATOMS, selectors } from "recoil/atoms";
import { IFirebaseUser, IVersionType } from "types/firebase";
import {
  RecoilState,
  RecoilValue,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";

import { ISelectedRobot } from "types/userRobot";
import { fireDatabase } from "config/firebase";
import { useEffect } from "react";

const useVersionAvailable = () => {
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilValue<IFirebaseUser | null>
  );
  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilValue<ISelectedRobot>
  );

  const setCurrentRobotVersionType = useSetRecoilState<string | null>(
    selectors[ATOMS.VERSION_ACTUAL_ROBOT_TYPE] as RecoilState<string | null>
  );
  const setAvailableRobotVersionTypes = useSetRecoilState<IVersionType[]>(
    selectors[ATOMS.VERSION_AVAILABLE_ROBOT_TYPE] as RecoilState<IVersionType[]>
  );

  useEffect(() => {
    if (fbUser && selectedRobot.id) {
      const unsubscribeRobotVersion = onSnapshot(
        doc(fireDatabase, "robots", selectedRobot.id),
        (snapshot) => {
          setCurrentRobotVersionType(snapshot.data()?.version_type_id);
        }
      );

      const unsubscribeAvailableVersion = onSnapshot(
        query(
          collection(fireDatabase, "versions", "types", "data"),
          where("available_for", "array-contains-any", ["*", selectedRobot.id])
        ),
        (snapshot) => {
          setAvailableRobotVersionTypes(
            snapshot.docs.map((doc) => doc.data() as IVersionType)
          );
        }
      );

      return () => {
        unsubscribeRobotVersion();
        unsubscribeAvailableVersion();
        setCurrentRobotVersionType(null);
        setAvailableRobotVersionTypes([]);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser, selectedRobot.id]);
};

export default useVersionAvailable;
