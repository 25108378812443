import { ATOMS, selectors } from "recoil/atoms";
import { Navigate, useLocation } from "react-router-dom";
import { RecoilState, useRecoilValue } from "recoil";

import FixedSpinner from "components/Global/FixedSpinner";
import { IFirebaseUser } from "types/firebase";
import { IUserIdentity } from "types/user";
import { ROUTES } from "constants/routes";
import { isDefined } from "utils/object";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const waitInit = useRecoilValue<boolean>(
    selectors[ATOMS.UI_WAITING_FIREBASE_INIT] as RecoilState<boolean>
  );

  const currentUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const userIdentity = useRecoilValue<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );

  if (waitInit) {
    return <FixedSpinner />;
  }

  if (!isDefined(currentUser) && !isDefined(userIdentity)) {
    console.log("User is undefined");

    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
