import "components/Splash/Splash.css";

import { useEffect, useState } from "react";

import { SplashContainer } from "./Splash.styled";
/* eslint-disable react/jsx-key */
import { useTheme } from "@mui/material";

const Splash = () => {
  const theme = useTheme();

  const [grappeLoaded, setGrappeLoaded] = useState(false);
  const [textLoaded, setTextLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setGrappeLoaded(true);
    }, 100);
    setTimeout(() => {
      setTextLoaded(true);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const grappeClasses = grappeLoaded ? "dessin fill-grappe" : "dessin";
  const svgClass = textLoaded ? "svgMove" : "";

  return (
    <SplashContainer id="splash">
      <svg
        id="svg"
        className={svgClass}
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 578.33 366.25"
      >
        <g>
          <path
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            d="M19.81,266.93,50.2,346a4.88,4.88,0,0,0,4.89,3.35A5.05,5.05,0,0,0,60,346l31.87-79.1h19.42L76,354.33q-2.82,7-7.93,9.83t-13,2.84a28.41,28.41,0,0,1-12.82-2.69q-5.42-2.7-8.23-10L.54,266.93Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
        <g>
          <rect
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            x="124.2"
            y="300.71"
            width="17.49"
            height="65.54"
          />
          <rect
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            x="124.2"
            y="266.18"
            width="17.49"
            height="17.49"
          />
        </g>
        <g>
          <path
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            d="M235,266.93v17.5H205.75V367H188.11V284.43h-29.2v-17.5Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
        <g>
          <rect
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            x="250.51"
            y="300.86"
            width="17.49"
            height="65.39"
          />
          <rect
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            x="250.51"
            y="266.18"
            width="17.49"
            height="17.64"
          />
        </g>
        <g>
          <path
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            d="M340.4,266.93a50.35,50.35,0,0,1,15.2,2A28.18,28.18,0,0,1,366,274.57a21.88,21.88,0,0,1,6,8.67,30.8,30.8,0,0,1,1.93,11.12q0,8.75-3.63,14.08a22.67,22.67,0,0,1-10.75,8.16,24.39,24.39,0,0,1,11,8.52q3.86,5.56,3.85,14.31a31,31,0,0,1-1.92,11.12,22,22,0,0,1-6,8.67,29,29,0,0,1-10.37,5.71,49.08,49.08,0,0,1-15.2,2.07H288.07V266.93Zm-35.13,42.26h35.58q15.42,0,15.42-12a12.69,12.69,0,0,0-3.63-9.49c-2.43-2.37-6.3-3.56-11.64-3.56H305.27Zm0,40.47h36q8,0,11.72-3.41t3.7-9.64q0-12.9-15.42-12.9h-36Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
        <g>
          <path
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            d="M433.17,367h13.64q21.94,0,33.58-12.3a39.33,39.33,0,0,0,9-16.35H470.2a19.75,19.75,0,0,1-2.41,3.53q-6.6,7.64-21,7.63H433.17q-14.67,0-21.2-7.56a19.56,19.56,0,0,1-2.43-3.6H390.63a39.45,39.45,0,0,0,9,16.35Q411.23,367,433.17,367Z"
            transform="translate(-0.54 -0.75)"
          />
          <path
            className="cls-2 fondu"
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            d="M405.45,317.19q0-17.34,6.6-25.05t21.12-7.71h13.64q14.54,0,21.06,7.71t6.52,24.9c0,2.41-.1,4.66-.3,6.79h17.72c.14-2.14.22-4.34.22-6.64q0-25.35-11.64-37.8t-33.58-12.46H433.17q-21.93,0-33.57,12.46T388,317.19c0,2.3.08,4.5.22,6.64h17.55C405.55,321.75,405.45,319.54,405.45,317.19Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
        <g>
          <path
            className="cls-1 fondu"
            stroke="#373a36"
            strokeWidth="1"
            d="M578.87,266.93v17.5H549.66V367H532V284.43h-29.2v-17.5Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
        <g id="Grape">
          <circle
            className={grappeClasses}
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            cx="286.85"
            cy="194.22"
            r="23.41"
          />
          <path
            className={grappeClasses}
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            d="M319,118.22c-12.93,0-18.1,10.89-31,10.89s-18.1-10.89-31-10.89a23.41,23.41,0,1,0,0,46.81c12.92,0,18.1-10.88,31-10.88S306,165,319,165a23.41,23.41,0,0,0,0-46.81Z"
            transform="translate(-0.54 -0.75)"
          />
          <path
            className={grappeClasses}
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            d="M349.44,64.88c-12.73,0-17.94,10.55-30.44,10.87-7.3-.16-12.12-3.8-17.38-6.83a23.26,23.26,0,0,0-9.33-3.7c-.32-.06-.65-.11-1-.15l-.64-.08c-.71-.07-1.44-.11-2.19-.11s-1.49,0-2.19.11l-.65.08c-.33,0-.66.09-1,.15a23.15,23.15,0,0,0-9.32,3.7C270,72,265,75.77,257.45,75.77c-12.93,0-18.1-10.89-31-10.89a23.41,23.41,0,1,0,0,46.81c12.92,0,18.09-10.89,31-10.89,7.59,0,12.51,3.75,17.89,6.85a23.15,23.15,0,0,0,9.32,3.7c.32.06.65.11,1,.15l.65.08c.7.07,1.43.11,2.19.11s1.48,0,2.19-.11l.64-.08c.33,0,.66-.09,1-.15a23.26,23.26,0,0,0,9.33-3.7c5.26-3,10.08-6.67,17.38-6.83,12.5.33,17.71,10.87,30.44,10.87a23.41,23.41,0,0,0,0-46.81Z"
            transform="translate(-0.54 -0.75)"
          />
          <path
            className={grappeClasses}
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            d="M247.25,47.47,260,60.23a39.69,39.69,0,0,1,56.1,0l12.77-12.76a57.72,57.72,0,0,0-81.64,0Z"
            transform="translate(-0.54 -0.75)"
          />
          <path
            className={grappeClasses}
            stroke={theme.palette.primary.main}
            strokeWidth="1"
            d="M226.18,26.39,237.8,38a71.11,71.11,0,0,1,100.55,0L350,26.39a87.53,87.53,0,0,0-123.79,0Z"
            transform="translate(-0.54 -0.75)"
          />
        </g>
      </svg>
    </SplashContainer>
  );
};

export default Splash;
