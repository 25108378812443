import { Alert, Stack, Typography } from "@mui/material";
import {
  IUpdateLowLevelVersion,
  UpdateStatus,
  UpdaterStatus,
} from "types/version";

import { DevStack } from "styled/Dev";
import { LinearProgressWithLabel } from "components/utils/Progress";
import { ROLE_DEV_ONLY } from "const";
import { clamp } from "utils/number";
import useHasAccess from "hooks/user/useHasAccess";
import { useTranslation } from "react-i18next";

type UpdatingLowLevelVersionProgressProps = {
  robotVersion: IUpdateLowLevelVersion;
};

const UpdatingLowLevelVersionProgress = ({
  robotVersion,
}: UpdatingLowLevelVersionProgressProps) => {
  const { t } = useTranslation(["version"]);

  const isDev = useHasAccess("all", ROLE_DEV_ONLY);

  return (
    <Stack spacing={1} sx={{ flex: 1 }}>
      {isDev ? (
        <DevStack>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Typography variant="body2">
              {robotVersion.version.version?.type}
            </Typography>
            <Typography variant="body2" textAlign="right">
              {robotVersion.version.version?.version.version}
            </Typography>
          </Stack>
          <LinearProgressWithLabel
            variant="determinate"
            value={clamp(
              robotVersion.version.version?.installProgress ?? 0,
              0,
              robotVersion.version.version?.updateStatus ===
                UpdateStatus.UPDATING
                ? 99
                : 100
            )}
            color={
              robotVersion.version.version?.updateStatus === UpdateStatus.ERROR
                ? "error"
                : "primary"
            }
            height={16}
            error={
              robotVersion.version.version?.updateStatus === UpdateStatus.ERROR
            }
          />
          <Alert severity="warning">{t("version:updateWarning")}</Alert>
          <LinearProgressWithLabel
            variant="determinate"
            value={clamp(
              robotVersion.version.version?.installProgress ?? 0,
              0,
              100
            )}
            color={
              robotVersion.global === UpdaterStatus.UPDATING_WAITING_RETRY
                ? "warning"
                : "primary"
            }
            height={16}
          />
        </DevStack>
      ) : null}
    </Stack>
  );
};

export default UpdatingLowLevelVersionProgress;
