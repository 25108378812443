import useAlertManager from "hooks/alert/useAlertManager";
import useApiSocket from "hooks/network/useApiSocket";
import useConfig from "hooks/config/useConfig";
import useConnectedUser from "hooks/user/useConnectedUser";
import useFirebaseUser from "hooks/user/useFirebaseUser";
import useFullyKiosk from "hooks/utils/useFullyKiosk";
import useGetNetwork from "hooks/network/useGetNetwork";
import useIsDev from "hooks/user/useIsDev";
import useLang from "hooks/i18n/useLang";
import useLowLevelVersionWatcher from "hooks/version/useLowLevelVersionWatcher";
import useRobotData from "hooks/robot/useRobotData";
import useRobotSocket from "hooks/robot/useRobotSocket";
import useRobotUser from "hooks/robot/useRobotUser";
import useStatusCode from "hooks/statusCodes/useStatusCode";
import useUpdateBakusWatcher from "hooks/version/useUpdateBakusWatcher";
import useVersionAvailable from "hooks/version/useVersionAvailable";
import useVersionSocket from "hooks/network/useVersionSocket";

const useInitHooks = () => {
  // Foufi
  useFullyKiosk();

  // Lang
  useLang();

  // User
  useFirebaseUser();
  useConnectedUser();
  useIsDev();
  useRobotUser();

  // Network
  useGetNetwork();
  useApiSocket();
  useVersionSocket();

  // Robot
  useRobotSocket();
  useRobotData();

  // Status code
  useStatusCode();

  // Config
  useConfig();

  // Version
  useUpdateBakusWatcher();
  useLowLevelVersionWatcher();
  useVersionAvailable();

  //Alert
  useAlertManager();
};

export default useInitHooks;
