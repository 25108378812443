import { SvgIcon } from "@mui/material";

interface ToolLiftIconProps {
  disabled?: boolean;
  disabledColor?: string;
  color?: string;
  fontSize?: number | string;
}

const ToolLift = ({
  disabled = false,
  disabledColor = "#ffffff4d",
  color = "#fff",
  fontSize = "3rem",
}: ToolLiftIconProps) => {
  return (
    <SvgIcon viewBox="0 0 68.03 68.03" style={{ fontSize: fontSize }}>
      <path
        fill={disabled ? disabledColor : color}
        d="M22.38,43.26a3.17,3.17,0,0,0,1.36,1.29H36.1c1.75,0,1-1.21,1-1.21C33.15,40.46,29.41,31,29.41,31c-.39-.79-5.9,8.29-7,9.5s-.31,2,0,2.73M5.61,45.31h9.18L13,41.7a2.43,2.43,0,0,1,.46-2.31,39,39,0,0,1,4.8-4.9C22.7,30.36,27,24.13,26.7,18.3c0-.06,5.91-.2,5.91-.2a35.53,35.53,0,0,0,6.62,18.53s5.38,10.22,21.71,8.74c0,0,.46-.28,1.57,1.85s2.41,2.88,0,2.79S14.1,50,14.1,50h-7s-.69.16-1.75-1.9l-1.06-2s-.76-.76,1.36-.76"
      />
      <polygon
        fill={disabled ? disabledColor : color}
        points="46.11 22.29 51.29 17.11 56.48 22.29 58.07 20.69 51.29 13.91 44.51 20.69 46.11 22.29"
      />
      <polygon
        fill={disabled ? disabledColor : color}
        points="56.48 26.46 51.29 31.64 46.11 26.46 44.51 28.06 51.29 34.84 58.07 28.06 56.48 26.46"
      />
    </SvgIcon>
  );
};

export default ToolLift;
