import {
  BOTTOM_PANE_CONTENT,
  GUIDANCE_MODE,
  INTERCEP_CALIBRATION_STEP,
  LIVE_PANE_CONTENT,
  MAP_OFFSET,
  SIDE,
} from "const";
import {
  IUpdateBakusVersion,
  IUpdateLowLevelVersion,
  UpdaterStatus,
} from "types/version";
import { IWorkParameters, IWorkSpeedStatus } from "types/work";
import { RecoilState, atom, selector } from "recoil";
import {
  createSnackBarContent,
  handleLoggingAtom,
  storeUtils,
} from "recoil/atomUtils";

import { GuidanceParams } from "types/guidance";
import { IGuiConfig } from "types/guiConfig";
import { IRobotGPS } from "types/gps";
import { ISnackbarContent } from "types/snackbar";
import { emptyArray } from "utils/array";

// BIG initial state
const userConfigInitialState: IGuiConfig = {
  SupportPhoneNumberInvent: "0787554629",
  WorkCorrectionTimeOut: 500,
  autonomousSpeedMax: 4.5, // km/h
  autonomousSpeedMaxDev: 6, // km/h
  autonomousSpeedMin: 2, // km/h
  autonomousSpeedMinDev: 2, // km/h
  autonomousSpeedStep: 0.5,
  bladeTorqueColorScale: JSON.stringify({
    95: { color: "#FF0000" },
    79: { color: "#00A300" },
    84: { color: "#ffa500" },
  }),
  maxValidProofAge: 90000,
  refreshProofInterval: 60000,
  requiredBatteryLevelForManuelControl: 5,
  sensorTorqueColorScale: JSON.stringify({
    3: { color: "#00A300" },
    5: { color: "#ffa500" },
    7: { color: "#FF0000" },
  }),
  startWorkAutoLockTimer: 4000,
  takeControlTimeout: 30000,
};
const snackBarInitialState: ISnackbarContent = {
  content: "",
  duration: 0,
  onCloseCallback: undefined,
  open: false,
  position: "bottom-center",
  severity: "info",
};
const defaultStatus = {
  code: "",
  details: "",
  hardware_location: "",
  message: "",
};
const defaultUser = {
  name: "",
  type: "",
};
const defaultGPS: IRobotGPS = {
  orientation: { w: 0, x: 0, y: 0, z: 0 },
  position: { latitude: 0, longitude: 0 },
  status_back: "FLOAT_RTK",
  status_front: "FLOAT_RTK",
};

const defaultGuidanceConfig: GuidanceParams = {
  mode: GUIDANCE_MODE.MANUAL,
  save: true,
  smooth: 0.5,
  date: new Date().getTime() - 1000 * 60 * 60 * 24, // One day ago
};

const defaultInterceps = {
  blade_torque: { max: 0, min: 0, step: 0, value: 0 },
  sensor_torque: { activated: false, max: 0, min: 0, step: 0, value: 0 },
};
const defaultWorkParam: IWorkParameters = {
  ignored_rows: [],
  loop_mode: false,
  side_to_work: SIDE.RIGHT,
  step: 1,
  work_id: "",
};

export const defaultBakusUpdateVersion: IUpdateBakusVersion = {
  global: {
    status: UpdaterStatus.IDLE,
    pullProgress: 0,
    installProgress: 0,
    name: "unknown",
  },
  versions: [],
};

export const defaultLowUpdateVersion: IUpdateLowLevelVersion = {
  global: UpdaterStatus.WAITING_FOR_VERSION_INFO,
  version: {
    expectedVersion: "",
    hasMismatch: false,
    version: undefined, // ? should this be null ?
  },
};

const defaultToolsConfig = {
  can_backward: false,
  general_manager: {
    controller: { type: "" },
    tools_id: [], // { name: '', side: ''}
  },
  label: { id: "", name: "" },
  tool_settings_left: {
    center_to_back_from_point_reference: 0.0,
    center_to_front_from_point_reference: 0.0,
    tools: [], // { controller: { type:''}, tool_id: {name: '', side: ''} }
    top_to_ground_from_point_reference: 0.0,
  },
  tool_settings_right: {
    center_to_back_from_point_reference: 0.0,
    center_to_front_from_point_reference: 0.0,
    tools: [], // { controller: { type:''}, tool_id: {name: '', side: ''} }
    top_to_ground_from_point_reference: 0.0,
  },
};

const defaultSpeedStatus: IWorkSpeedStatus = {
  speed: 0,
  min_speed: 0,
  max_speed: 0,
  speed_step: 0,
};

export const ATOMS = {
  AUTONOMOUS: "ATOM_AUTONOMOUS",
  AUTOPILOT_VERSION: "ATOM_AUTOPILOT_VERSION",
  ROBOT_ACTUAL_BAKUS_VERSION: "ATOM_ROBOT_ACTUAL_BAKUS_VERSION",
  BAKUS_WIZARD_VERSION: "ATOM_BAKUS_WIZARD_VERSION",
  BATTERY_STATUS: "ATOM_BATTERY_STATUS",
  BUMPERS: "ATOM_BUMPERS",
  BUMPERS_SHUNTED: "ATOM_BUMPERS_SHUNTED",
  CALIBRATING: "ATOM_CALIBRATING",
  CAN_PERFORM_INTERCEP_CALIBRATION: "ATOM_CAN_PERFORM_INTERCEP_CALIBRATION",
  CAPABILITIES: "ATOM_CAPABILITIES",
  CURRENT_CONTROLLER: "ATOM_CURRENT_CONTROLLER",
  CURRENT_EMERGENCY: "ATOM_CURRENT_EMERGENCY",
  CURRENT_PARCEL: "ATOM_CURRENT_PARCEL",
  CURRENT_PARCEL_FRIENDLY_NAME: "ATOM_CURRENT_PARCEL_FRIENDLY_NAME",
  CURRENT_PARCEL_UPDATE_DATE: "ATOM_CURRENT_PARCEL_UPDATE_DATE",
  CURRENT_ROW: "ATOM_CURRENT_ROW",
  ESTOP: "ATOM_ESTOP",
  FB_USER: "ATOM_FB_USER",
  GPS: "ATOM_GPS",
  GUIDANCE_CONFIG: "ATOM_GUIDANCE_CONFIG", // Robot actual config
  GUIDANCE_MODE_AVAILABLE: "ATOM_GUIDANCE_MODE_AVAILABLE",
  GUIDANCE_PARAMS: "ATOM_GUIDANCE_PARAMS",
  HAS_USER_LOGGED_OUT: "ATOM_HAS_USER_LOGGED_OUT",
  INTERCEP_CALIBRATION_STEP: "ATOM_INTERCEP_CALIBRATION_STEP",
  IS_CHARGING: "ATOM_IS_CHARGING",
  IS_DEV_ENV: "ATOM_IS_DEV_ENV",
  IS_USER_ONLINE: "ATOM_IS_USER_ONLINE",
  LOCK_STATUS: "ATOM_LOCK_STATUS",
  LOW_LEVEL_VERSION_MISMATCH: "ATOM_LOW_LEVEL_VERSION_MISMATCH",
  MAP: "ATOM_MAP",
  MAP_CHECK_ERROR: "MAP_CHECK_ERROR",
  PARCEL_HAS_HIGHWAY: "ATOM_PARCEL_HAS_HIGHWAY",
  READY_TO_DRIVE: "ATOM_READY_TO_DRIVE",
  ROBOTS_AVAILABLE: "ATOM_ROBOTS_AVAILABLE",
  ROBOT_ID: "ATOM_ROBOT_ID",
  ROBOT_LOOKING_UP: "ATOM_ROBOT_LOOKING_UP",
  ROBOT_NAME: "ATOM_ROBOT_NAME",
  ROBOT_TYPE: "ATOM_ROBOT_TYPE",
  SELECTED_ROBOT: "ATOM_SELECTED_ROBOT",
  SNACKBAR: "ATOM_SNACKBAR",
  STATUS_CODE: "ATOM_STATUS_CODE",
  TOOLBARS_CAN_FLOAT: "ATOM_TOOLBARS_CAN_FLOAT",
  TOOLS_CONFIG: "ATOM_TOOLS_CONFIG",
  TOOLS_CONFIG_AVAILABLE: "ATOM_TOOLS_CONFIG_AVAILABLE",
  TOOLS_INTERCEPS_CORRECTIONS: "ATOM_TOOLS_INTERCEPS_CORRECTIONS",
  TOOLS_MODES_AVAILABLE: "ATOM_TOOLS_MODES_AVAILABLE",
  TOOLS_MOWERS_CORRECTIONS: "ATOM_TOOLS_MOWERS_CORRECTIONS",
  TOOLS_PRESENT: "ATOM_TOOLS_PRESENT",
  TOOLS_READY: "ATOM_TOOLS_READY",
  TOOLS_TOOLBARS_CORRECTIONS: "ATOM_TOOLS_TOOLBARS_CORRECTIONS",
  USERS_CONFIG: "ATOM_USERS_CONFIG",
  USER_ACCESS: "ATOM_USER_ACCESS",
  WORK_DONE: "ATOM_WORK_DONE",
  WORK_ID: "ATOM_WORK_ID",
  WORK_LATERAL_CORRECTION: "ATOM_WORK_LATERAL_CORRECTION",
  WORK_PARAM: "ATOM_WORK_PARAM",
  WORK_PATH: "ATOM_WORK_PATH",
  WORK_PROGRESS: "ATOM_WORK_PROGRESS",
  WORK_SPEED: "ATOM_WORK_SPEED",
  WORK_SPEED_STATUS: "ATOM_WORK_SPEED_STATUS",
  WORK_WORK: "ATOM_WORK_WORK",
  WS_LATENCY: "ATOM_WS_LATENCY",
  WS_ROBOT_ID: "ATOM_WS_ROBOT_ID",
  WS_ROBOT_DISCOVERY: "ATOM_WS_ROBOT_DISCOVERY",

  WS_CONNECTED: "ATOM_SOCKET_CONNECTED",

  VERSION_WS_CONNECTED: "ATOM_VERSION_SOCKET_CONNECTED",
  VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION:
    "ATOM_VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION",
  VERSION_ROBOT_DATA: "ATOM_VERSION_ROBOT_DATA",
  VERSION_ROBOT_LOW_LEVEL_DATA: "ATOM_VERSION_ROBOT_LOW_LEVEL_DATA",
  VERSION_ACTUAL_ROBOT_TYPE: "ATOM_VERSION_ACTUAL_ROBOT_TYPE",
  VERSION_AVAILABLE_ROBOT_TYPE: "ATOM_VERSION_AVAILABLE_ROBOT_TYPE",

  ALERT_FEEDBACK_LIST: "ATOM_ALERT_FEEDBACK_LIST",

  UI_LANG: "ATOM_UI_LANG",
  UI_LIVE_PANE_CONTENT: "ATOM_UI_LIVE_PANE_CONTENT",
  UI_SHOW_BOTTOM_PANE: "ATOM_UI_SHOW_BOTTOM_PANE",
  UI_SHOW_BOTTOM_PANE_CONTENT: "ATOM_UI_SHOW_BOTTOM_PANE_CONTENT",
  UI_CAN_TRIGGER_START_AUTONOMOUS: "ATOM_UI_CAN_TRIGGER_START_AUTONOMOUS",
  UI_DRAWER_OPEN: "ATOM_UI_DRAWER_OPEN",
  UI_CONNECTIVITY_OPEN: "ATOM_UI_CONNECTIVITY_OPEN",
  UI_REPORT_DIALOG_OPEN: "ATOM_UI_REPORT_DIALOG_OPEN",
  UI_SIGN_OUT_DIALOG_OPEN: "ATOM_UI_SIGN_OUT_DIALOG_OPEN",
  UI_DRAWER_SHOW_LOW_LEVEL_UPDATE: "ATOM_UI_DRAWER_SHOW_LOW_LEVEL_UPDATE",

  UI_MAP_TRACK_ENABLED: "ATOM_UI_MAP_TRACK_ENABLED",
  UI_MAP_OFFSET: "ATOM_UI_MAP_OFFSET",
  UI_HAS_ALERT_FEEDBACK: "ATOM_UI_HAS_ALERT_FEEDBACK",

  UI_USER_CREDENTIALS: "ATOM_UI_USER_CREDENTIALS",
  UI_USER_TOKEN: "ATOM_UI_USER_TOKEN",

  UI_WAITING_FIREBASE_INIT: "ATOM_UI_WAITING_FIREBASE_INIT",
};

const initialState: { [Key: string]: any } = {};

initialState[ATOMS.UI_LANG] = "fr";
initialState[ATOMS.UI_LIVE_PANE_CONTENT] = LIVE_PANE_CONTENT.move;
initialState[ATOMS.UI_SHOW_BOTTOM_PANE] = false;
initialState[ATOMS.UI_SHOW_BOTTOM_PANE_CONTENT] = BOTTOM_PANE_CONTENT.none;
initialState[ATOMS.UI_MAP_TRACK_ENABLED] = false;
initialState[ATOMS.UI_MAP_OFFSET] = MAP_OFFSET.withoutAlert;
initialState[ATOMS.UI_HAS_ALERT_FEEDBACK] = false;
initialState[ATOMS.UI_CAN_TRIGGER_START_AUTONOMOUS] = false;
initialState[ATOMS.UI_DRAWER_OPEN] = false;
initialState[ATOMS.UI_CONNECTIVITY_OPEN] = false;

initialState[ATOMS.UI_WAITING_FIREBASE_INIT] = true;

initialState[ATOMS.UI_REPORT_DIALOG_OPEN] = false;
initialState[ATOMS.UI_SIGN_OUT_DIALOG_OPEN] = false;
initialState[ATOMS.UI_DRAWER_SHOW_LOW_LEVEL_UPDATE] = false;

initialState[ATOMS.UI_USER_CREDENTIALS] = storeUtils.extractFromStorage(
  storeUtils.dataKey.USER_CREDENTIALS,
  null
);
initialState[ATOMS.UI_USER_TOKEN] = null;

initialState[ATOMS.ALERT_FEEDBACK_LIST] = [];

initialState[ATOMS.AUTONOMOUS] = { reason: defaultStatus, status: false };
initialState[ATOMS.AUTOPILOT_VERSION] = null;
initialState[ATOMS.ROBOT_ACTUAL_BAKUS_VERSION] = null;
initialState[ATOMS.BAKUS_WIZARD_VERSION] = null;
initialState[ATOMS.BATTERY_STATUS] = 0;
initialState[ATOMS.BUMPERS_SHUNTED] = false;
initialState[ATOMS.BUMPERS] = 0;
initialState[ATOMS.CALIBRATING] = false;
initialState[ATOMS.CAN_PERFORM_INTERCEP_CALIBRATION] = false;
initialState[ATOMS.CAPABILITIES] = {
  parcel_map_order_version: 0,
  set_tools_version: 0,
};
initialState[ATOMS.CURRENT_CONTROLLER] = defaultUser;
initialState[ATOMS.CURRENT_EMERGENCY] = {
  level: 0,
  level_label: "",
  reason: defaultStatus,
  user: defaultUser,
};
initialState[ATOMS.CURRENT_PARCEL] = "";
initialState[ATOMS.CURRENT_PARCEL_FRIENDLY_NAME] = "";
initialState[ATOMS.CURRENT_PARCEL_UPDATE_DATE] = -1;
initialState[ATOMS.CURRENT_ROW] = -1;
initialState[ATOMS.ESTOP] = 0;
initialState[ATOMS.FB_USER] = null;
initialState[ATOMS.GPS] = defaultGPS;
initialState[ATOMS.GUIDANCE_CONFIG] = defaultGuidanceConfig;
initialState[ATOMS.GUIDANCE_MODE_AVAILABLE] = [GUIDANCE_MODE.MANUAL];
initialState[ATOMS.GUIDANCE_PARAMS] = defaultGuidanceConfig;
initialState[ATOMS.INTERCEP_CALIBRATION_STEP] = INTERCEP_CALIBRATION_STEP.IDLE;
initialState[ATOMS.IS_CHARGING] = false;
initialState[ATOMS.IS_DEV_ENV] = false;
initialState[ATOMS.IS_USER_ONLINE] = false;
initialState[ATOMS.HAS_USER_LOGGED_OUT] = false;
initialState[ATOMS.LOCK_STATUS] = { level: 0, level_label: "", locks: [] };
initialState[ATOMS.LOW_LEVEL_VERSION_MISMATCH] = false;
initialState[ATOMS.MAP] = {
  origin_position: { latitude: 0, longitude: 0 },
  rows: [],
};
initialState[ATOMS.MAP_CHECK_ERROR] = null;
initialState[ATOMS.PARCEL_HAS_HIGHWAY] = false;
initialState[ATOMS.READY_TO_DRIVE] = false;
initialState[ATOMS.ROBOTS_AVAILABLE] = [];
initialState[ATOMS.ROBOT_ID] = "";
initialState[ATOMS.ROBOT_LOOKING_UP] = false;
initialState[ATOMS.ROBOT_NAME] = "";
initialState[ATOMS.ROBOT_TYPE] = "bkps";
initialState[ATOMS.SELECTED_ROBOT] = { id: null, name: null };
initialState[ATOMS.SNACKBAR] = snackBarInitialState;
initialState[ATOMS.STATUS_CODE] = storeUtils.extractFromStorage(
  storeUtils.dataKey.STATUS_CODE,
  {}
);
initialState[ATOMS.TOOLBARS_CAN_FLOAT] = false;
initialState[ATOMS.TOOLS_CONFIG] = defaultToolsConfig;
initialState[ATOMS.TOOLS_CONFIG_AVAILABLE] = [];
initialState[ATOMS.TOOLS_INTERCEPS_CORRECTIONS] = defaultInterceps;
initialState[ATOMS.TOOLS_MODES_AVAILABLE] = [];
initialState[ATOMS.TOOLS_MOWERS_CORRECTIONS] = {
  max: 0,
  min: 0,
  speed: 0,
  step: 0,
};
initialState[ATOMS.TOOLS_PRESENT] = null;
initialState[ATOMS.TOOLS_READY] = { status_code: {}, tools: [] };
initialState[ATOMS.TOOLS_TOOLBARS_CORRECTIONS] = {
  left: 0,
  max: 0,
  min: 0,
  right: 0,
  step: 0,
};
initialState[ATOMS.USERS_CONFIG] = userConfigInitialState;
initialState[ATOMS.USER_ACCESS] = [];
initialState[ATOMS.WORK_DONE] = emptyArray();
initialState[ATOMS.WORK_ID] = "";
initialState[ATOMS.WORK_LATERAL_CORRECTION] = {
  active: false,
  max: 0,
  step: 0,
  value: 0,
};
initialState[ATOMS.WORK_PARAM] = defaultWorkParam;
initialState[ATOMS.WORK_PATH] = emptyArray();
initialState[ATOMS.WORK_PROGRESS] = -1;
initialState[ATOMS.WORK_SPEED] = 0.8;
initialState[ATOMS.WORK_SPEED_STATUS] = defaultSpeedStatus;
initialState[ATOMS.WORK_WORK] = emptyArray();

initialState[ATOMS.WS_LATENCY] = Infinity;
initialState[ATOMS.WS_ROBOT_ID] = "";
initialState[ATOMS.WS_ROBOT_DISCOVERY] = null;
initialState[ATOMS.WS_CONNECTED] = false;

initialState[ATOMS.VERSION_WS_CONNECTED] = false;
initialState[ATOMS.VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION] = false;
initialState[ATOMS.VERSION_ROBOT_DATA] = defaultBakusUpdateVersion;
initialState[ATOMS.VERSION_ROBOT_LOW_LEVEL_DATA] = defaultLowUpdateVersion;
initialState[ATOMS.VERSION_ACTUAL_ROBOT_TYPE] = null;
initialState[ATOMS.VERSION_AVAILABLE_ROBOT_TYPE] = [];

export const avoidLogKey: {
  [Key: string]: { current: number; every: number };
} = {};

export const filterLogKeys: string[] = [
  // ATOMS.FB_USER,
  // ATOMS.UI_USER_CREDENTIALS,
  // ATOMS.UI_USER_TOKEN,
];

avoidLogKey[ATOMS.WS_LATENCY] = { current: 0, every: 100 };

const storeKeys: { [Key: string]: string } = {};
storeKeys[ATOMS.UI_USER_CREDENTIALS] = storeUtils.dataKey.USER_CREDENTIALS;
storeKeys[ATOMS.STATUS_CODE] = storeUtils.dataKey.STATUS_CODE;

const mySelectors: { [Key: string]: RecoilState<unknown> } = {};

const makeSelector = () => {
  Object.keys(initialState).forEach((key) => {
    const newAtom = atom({
      default: initialState[key],
      key: key + "_BC",
    });
    const newSelector = selector({
      get: ({ get }) => get(newAtom),
      key: key + "_BC_SELECTOR",
      set: ({ set, get }, newValue) => {
        handleLoggingAtom(key, get, newAtom, newValue);
        switch (key) {
          case ATOMS.SNACKBAR:
            set(
              newAtom,
              createSnackBarContent(newValue as Partial<ISnackbarContent>)
            );
            break;
          default:
            set(newAtom, newValue);
        }
        if (Object.keys(storeKeys).includes(key)) {
          storeUtils.putToStorage(storeKeys[key], newValue);
        }
      },
    });
    mySelectors[key] = newSelector;
  });
};

makeSelector();

export const selectors = mySelectors;
