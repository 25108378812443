import { IconProps } from "types/icon";
import SvgIcon from "@mui/material/SvgIcon";

const IntercepIcon = (props: IconProps) => {
  const fontColor = props.fontColor ?? "currentColor";
  const fontSize = props.fontSizeNum ?? 24;

  return (
    <SvgIcon viewBox="0 0 24 24" style={{ fontSize }}>
      <path
        d="M13.8509 20.7535L16.3595 19.4814H13.8509L3 21.5L13.8509 20.7535Z"
        fill={fontColor}
      />
      <path
        d="M20.6534 5.52471L15.4595 6.39157M21 8.62525L18.0754 9.13569M13.8509 19.4814H16.3595L13.8509 20.7535L3 21.5L13.8509 19.4814ZM13.8509 19.4814V4.60932C13.8509 4.12459 14.1985 3.70965 14.6758 3.62477L17.9808 3.03697M3.3196 15.9524L8.73728 14.9458C11.7161 14.2759 11.8713 14.4038 12.6594 11.8793C12.9881 10.8265 13.8509 9.86942 15.1507 9.64613L18.0754 9.13569M21 2.5L17.9808 3.03697M17.9808 3.03697L18.0754 9.13569"
        fill="none"
        stroke={fontColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default IntercepIcon;
