import styled from "@emotion/styled";

export const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;
