import { LatLngTuple } from "leaflet";
import flagCS from "resources/logo/lang/cs.png";
import flagEN from "resources/logo/lang/en.png";
import flagES from "resources/logo/lang/es.png";
import flagFR from "resources/logo/lang/fr.png";
import flagHU from "resources/logo/lang/hu.png";
import flagIT from "resources/logo/lang/it.png";
import { validNotEmptyString } from "utils/string";

export const vitibotPos: LatLngTuple = [49.226097, 4.097473];

export enum SIDE {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  UNKNOWN = "UNKNOWN",
}

export enum INTERCEP_CALIBRATION_STEP {
  IDLE = 0,
  STARTED = 1,
  INITIALIZING = 2,
  WAIT_CALIBRATION = 3,
  CALIBRATION = 4,
  SAVE_CONFIG = 5,

  SUCCESS = 254,
  ERROR = 255,
}

export enum GUIDANCE_MODE {
  STRAIGHT = 0,
  MANUAL = 1,
  CEPS = 2,
  INTERCEPS = 3,
}

export enum TOOL_ID {
  All = "All",
  INTERCEP = "Intercep",
  INTERCEP_OUTSIDE = "Intercep_outside",
  LEFT = "Left",
  MECHANICAL = "Mechanical",
  MOWER = "Mower",
  RIGHT = "Right",
  SPRAY = "Spray",
  TOOLBAR = "Toolbar",
}

export enum TOOL_SIDE {
  left = "left",
  right = "right",
}

export enum API_ORDERS {
  AutonomousStart = "autonomous:start",
  EmergencyHeartbeat = "emergency:heartbeat",
  EmergencyLock = "emergency:lock",
  IncidentSave = "incident:save",
  IncidentSetDescription = "incident:set_description",
  IntercepMappingSetConfig = "intercep_mapping:set_config",
  MapCheck = "map:check",
  ToolsSetConfig = "tools:set_config",
  ToolsSetIntercepsCorrections = "tools:set_interceps_corrections",
  ToolsSetMowersCorrections = "tools:set_mowers_corrections",
  ToolsSetToolbarCorrections = "tools:set_toolbars_corrections",
  ToolsSetUserFloatingMode = "tools:set_user_floating_mode",
  WorkGenerate = "work:generate",
  WorkSetLateralCorrection = "work:set_lateral_correction",
  WorkSetMaxSpeed = "work:set_max_speed",
}

export enum EMERGENCY_LEVEL {
  NONE = 0,
  NO_AUTONOMOUS = 1,
  STOP = 2,
  BRAKES = 3,
  NO_POWER = 4,
}

export const MAP_OFFSET = {
  withAlert: [0, 80],
  withoutAlert: [0, 60],
};

export enum LIVE_PANE_CONTENT {
  move = "move",
  toolbar = "toolbar",
  tools = "tools",
  mower = "mowers",
}

export enum BOTTOM_PANE_CONTENT {
  none = "none",

  bug_report = "bug_report",
  work = "work",
  work_guidance = "work_guidance",

  tools_autonomousCorrector = "autonomousCorrector",
  tools_intercepCorrector = "intercepCorrector",
  tools_mowerCorrector = "mowerCorrector",
  tools_rowExtremity = "rowExtremity",
  tools_workingDepthCorrector = "workingDepthCorrector",

  version_update = "version_update",
  low_level_version_update = "low_level_version_update",

  map_legend = "map_legend",
  parameter = "parameter",
  error = "error",
}

export enum HOOK_STATE {
  alive = "alive",
  stopped = "stopped",
}

export const availableIntercepMode = {
  Calibrating: "intercepModeCalibrating",
  Clean: "intercepModeClean",
  Clean_end: "intercepModeCleanEnd",
  Error: "intercepModeError",
  Error_calibrating: "intercepModeCalibrationError",
  Error_finding_zero: "intercepModeErrorFindingZero",
  Idle: "intercepModeIdle",
  Idle_end: "intercepModeIdleEnd",
  Not_ready: "intercepModeNotReady",
  Stop: "intercepModeStop",
  Work: "intercepModeWork",
  // Work2: "Pulvérisation",// ? "c'était pour la pulvé, je ne sais pas si c'est encore utilisé"
} as const;

export const availableMowerMode = {
  Calibrating: "mowerModeCalibrating",
  Clean: "mowerModeClean",
  Clean_end: "mowerModeCleanEnd",
  Error: "mowerModeError",
  Error_calibrating: "mowerModeCalibrationError",
  Error_finding_zero: "mowerModeErrorFindingZero",
  Idle: "mowerModeIdle",
  Idle_end: "mowerModeIdleEnd",
  Not_ready: "mowerModeNotReady",
  Stop: "mowerModeStop",
  Work: "mowerModeWork",
  // Work2: "Pulvérisation",// ? "c'était pour la pulvé, je ne sais pas si c'est encore utilisé"
} as const;

export enum IWorkConfigEnum {
  WORK = "Work",
  // WORK2 = "Work_2",
  IDLE = "Idle",
  IDLE_END = "Idle_end",
  STOP = "Stop",
  DIAGNOSE = "Diagnose",
  DIAGNOSE_END = "Diagnose_end",
  CLEAN = "Clean",
  CLEAN_END = "Clean_end",
  CALIBRATING = "Calibrating",
  NOT_READY = "Not_ready",
  ERROR = "Error",
  ERROR_FINDING_ZERO = "Error_finding_zero",
  ERROR_CALIBRATING = "Error_calibrating",
}

export type availableWorkConfigEntry =
  | "Calibrating"
  | "Clean"
  | "Clean_end"
  | "Error"
  | "Error_calibrating"
  | "Error_finding_zero"
  | "Idle"
  | "Idle_end"
  | "Not_ready"
  | "Stop"
  | "Work";

export const DEFAULT_LANG = "en";

/**
 * If running in local (localhost)  -> "bakus"
 * From another device (smartphone) -> window.location.hostname
 */
let baseUrl = "";
const actAsLocalHost = ["localhost", "gui"];

const getBaseUrl = () => {
  if (validNotEmptyString(baseUrl)) {
    return baseUrl;
  }

  // We are not running a production build
  if (
    process.env.NODE_ENV !== "production" ||
    process.env.REACT_APP_DEV_BUILD === "true"
  ) {
    // Search if the host is known as local (CI included)
    if (actAsLocalHost.includes(window.location.hostname)) {
      baseUrl = "bakus";
    } else {
      // Fall back to the hostname
      baseUrl = window.location.hostname;
    }
  } else {
    baseUrl = "192.168.101.42"; // Production (for robot usage)
  }

  console.log("Used baseUrl: ", baseUrl);
  return baseUrl;
};

export const API_URL = getBaseUrl();
export const API_PORT = 4610;
export const VERSION_PORT = 4612;

export enum USER_ROLES {
  DEV = "dev",
  SUPERVISOR = "supervisor",
  USER = "user",
}

export const ROLE_DEV_ONLY = [USER_ROLES.DEV];
export const ROLE_DEV_SUPERVISOR = [USER_ROLES.DEV, USER_ROLES.SUPERVISOR];
export const ROLE_SUPERVISOR_ONLY = [USER_ROLES.SUPERVISOR];

export const LANGUAGES = {
  FRENCH: {
    label: "french",
    code: "fr-FR",
    shortCode: "fr",
    flag: flagFR,
  },
  ENGLISH: {
    label: "english",
    code: "en-US",
    shortCode: "en",
    flag: flagEN,
  },
  ITALIAN: {
    label: "italian",
    code: "it-IT",
    shortCode: "it",
    flag: flagIT,
  },
  SPANISH: {
    label: "spanish",
    code: "es-ES",
    shortCode: "es",
    flag: flagES,
  },
  CZECH: {
    label: "czech",
    code: "cs-CZ",
    shortCode: "cs",
    flag: flagCS,
  },
  HUNGARIAN: {
    label: "hungarian",
    code: "hu-HU",
    shortCode: "hu",
    flag: flagHU,
  },
} as const;
