import styled from "@emotion/styled";

export const WholeAppWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  text-align: center;
  inset: 0;
`;
