import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useSetRecoilState } from "recoil";

import { useEffect } from "react";

// FIXME: not work with local network
const getOnline = () => navigator.onLine;

const useGetNetwork = () => {
  const setIsUserOnline = useSetRecoilState<boolean>(
    selectors[ATOMS.IS_USER_ONLINE] as RecoilState<boolean>
  );

  useEffect(() => {
    setIsUserOnline(getOnline());
    window.addEventListener("online", () => setIsUserOnline(getOnline()));
    window.addEventListener("offline", () => setIsUserOnline(getOnline()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useGetNetwork;
