import { DEFAULT_LANG } from "const";
import { ITool } from "types/tool";

/**
 * get the best match with the current language and the codes list
 *
 * @param {String[]} codes - liste of language codes
 * @param {String} currentLanguageCode - current language code
 * @param {Boolean} allowFallbackToDefault - allow translation code finder to fall back to the DEFAULT_LANG
 * @returns {String}
 */
export const getBestTranslationCode = (
  codes: string[] = [],
  currentLanguageCode = "",
  allowFallbackToDefault = true
) => {
  // check codes list not empty
  if (codes?.length === 0) {
    return null;
  }

  // check if the exact code is in the list
  const currentLangCodeInCodes = codes.find(
    (code) => code === currentLanguageCode
  );
  if (currentLangCodeInCodes !== undefined) {
    return currentLangCodeInCodes;
  }

  // check if the generous code of a specialized code is in the list (ex: en-GB match with en)
  const currentLangMatchGenerousLang = codes
    .filter((code) => code.length === 2)
    .find((code) => code === currentLanguageCode.slice(0, 2));
  if (currentLangMatchGenerousLang !== undefined) {
    return currentLangMatchGenerousLang;
  }

  // check if the main part of a specialized code match with an other specialized with the same generous code (ex: en-GB match with en-UK)
  const currentLangMatchSameGenerousLang = codes.find(
    (code) => code.slice(0, 2) === currentLanguageCode.slice(0, 2)
  );
  if (currentLangMatchSameGenerousLang !== undefined) {
    return currentLangMatchSameGenerousLang;
  }

  if (allowFallbackToDefault) {
    // find a generous match with the default language
    const defaultLanguageMatchGenerousLang = codes.find(
      (code) => code === DEFAULT_LANG
    );
    if (defaultLanguageMatchGenerousLang !== undefined) {
      return defaultLanguageMatchGenerousLang;
    }

    // find a specialized match with the default language
    const defaultLanguageMatchSpecializedLang = codes.find(
      (code) => code.slice(0, 2) === DEFAULT_LANG
    );
    if (defaultLanguageMatchSpecializedLang !== undefined) {
      return defaultLanguageMatchSpecializedLang;
    }
  }

  return null;
};

export const getToolName = (tool: ITool, languageCode: string) => {
  if (!Array.isArray(tool.translations)) {
    return tool.id;
  }
  if (languageCode) {
    const langToUse = getBestTranslationCode(
      tool.translations.map((tr) => tr.code),
      languageCode
    );
    if (langToUse) {
      const thisTranslation = tool.translations.find(
        (tr) => tr.code === langToUse
      );
      if (thisTranslation) {
        return thisTranslation.name || tool.id;
      } else {
        if (tool.translations.length === 1) {
          return tool.translations[0].name || tool.id;
        }
      }
    }
  }
  return tool.id;
};
