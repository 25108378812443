const deepEqual = (x: any, y: any): boolean => {
  if (x === y) {
    return true;
  } else if (
    typeof x == "object" &&
    x != null &&
    typeof y == "object" &&
    y != null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    for (var prop in x) {
      if (Object.prototype.hasOwnProperty.call(y, prop)) {
        if (!deepEqual(x[prop], y[prop])) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

function isNull<T>(obj: T): boolean {
  return obj === null;
}

function isDefined<T>(obj: T): obj is NonNullable<T> {
  return obj !== null && obj !== undefined;
}

function areDefined(obj: any[]): boolean {
  return obj.every(isDefined);
}

function isDefinedOrNull<T>(obj: T): obj is Exclude<T, undefined> {
  if (obj === null) return true;
  return obj !== undefined;
}

export { isDefined, areDefined, deepEqual, isDefinedOrNull, isNull };
