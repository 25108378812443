import { Container, Root } from "./ProgressLoader.styled";

import { CircularProgress } from "@mui/material";

const ProgressLoader = () => {
  return (
    <Root>
      <Container>
        <CircularProgress />
      </Container>
    </Root>
  );
};

export default ProgressLoader;
