import { ATOMS, selectors } from "recoil/atoms";
import {
  FieldValue,
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { RecoilState, useRecoilValue } from "recoil";

import { API_ORDERS } from "const";
import { GuidanceParams } from "types/guidance";
import { IApiResponse } from "types/api";
import { IFirebaseUser } from "types/firebase";
import { ISelectedRobot } from "types/userRobot";
import { IWorkParameters } from "types/work";
import { fireDatabase } from "config/firebase";
import { useCallback } from "react";
import useHttpApi from "hooks/network/useHttpApi";

interface IOrder {
  call: API_ORDERS;
  context: {
    userId: string;
    version: string;
  };
  date_server: FieldValue;
  date_user: Timestamp;
  payload: any;
}

const useRobotOrder = () => {
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);
  const { httpRobotManager } = useHttpApi();

  const socketToken = useRecoilValue<string | null>(
    selectors[ATOMS.UI_USER_TOKEN] as RecoilState<string | null>
  );
  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );

  const sendOrderLocally = useCallback(
    async (order: IOrder): Promise<IApiResponse> => {
      switch (order.call) {
        case API_ORDERS.AutonomousStart:
          return httpRobotManager.startAutonomous();
        case API_ORDERS.IncidentSave:
          return httpRobotManager.saveIncident({ name: order.payload.name });
        case API_ORDERS.IncidentSetDescription:
          return httpRobotManager.setIncidentDescription(
            order.payload.category,
            order.payload.description
          );
        case API_ORDERS.IntercepMappingSetConfig:
          return httpRobotManager.setGuidanceParams(
            order.payload as GuidanceParams
          );
        case API_ORDERS.MapCheck:
          return httpRobotManager.setMap();
        case API_ORDERS.ToolsSetConfig:
          return httpRobotManager.setToolsConfig(order.payload.config_name);
        case API_ORDERS.ToolsSetIntercepsCorrections:
          return httpRobotManager.setIntercepsCorrections(
            order.payload.blade_torque,
            order.payload.sensor_activate,
            order.payload.sensor_torque
          );
        case API_ORDERS.ToolsSetMowersCorrections:
          return httpRobotManager.setMowersCorrections(order.payload.speed);
        case API_ORDERS.ToolsSetToolbarCorrections:
          return httpRobotManager.setToolbarCorrections(
            order.payload.left,
            order.payload.right
          );
        case API_ORDERS.ToolsSetUserFloatingMode:
          return httpRobotManager.setUserToolbarFloatingMode(
            order.payload.floating_mode
          );
        case API_ORDERS.WorkGenerate:
          return httpRobotManager.generateWork(
            order.payload as IWorkParameters
          );
        case API_ORDERS.WorkSetLateralCorrection:
          return httpRobotManager.setLateralCorrection(order.payload.value);
        case API_ORDERS.WorkSetMaxSpeed:
          return httpRobotManager.setMaxSpeedInAutonomous(order.payload.speed);

        default:
          return Promise.reject("Could not handle locally");
      }
    },
    [httpRobotManager]
  );

  const sendOrderToSelectedRobotID = useCallback(
    async (call: API_ORDERS, payload: any): Promise<IApiResponse> => {
      const order: IOrder = {
        call,
        context: {
          userId: fbUser?.uid || "",
          version: process.env.REACT_APP_VERSION ?? "",
        },
        date_server: serverTimestamp(), // unknown behavior when used like this with socket
        date_user: Timestamp.now(),
        payload,
      };
      if (selectedRobot.id) {
        if (socketToken !== null && socketConnected) {
          console.log("Use local network first");
          try {
            const response = await sendOrderLocally(order);
            console.log(
              "Receive response",
              response,
              "for order",
              order.call,
              order.payload
            );
            return response;
          } catch (error) {
            console.log("Could not handle locally due to", error);
          }
        }

        if (fbUser === null || fbUser.uid.length === 0) {
          return Promise.reject("No user logged in");
        }
        return new Promise((resolve, reject) => {
          console.log("Sending order by firebase", order.call, order.payload);
          const colRef = collection(
            fireDatabase,
            `robots/${selectedRobot.id}/order`
          );
          addDoc(colRef, { answered: false, order })
            .then((docRef) => {
              console.log(
                "Order sent by firebase",
                docRef.path,
                order.call,
                order.payload
              );
              let onTimeout: NodeJS.Timeout | null = null;
              const thisSub = onSnapshot(docRef, (snap) => {
                const newDoc = snap.data();
                if (
                  newDoc &&
                  newDoc.answered &&
                  newDoc.response &&
                  newDoc.response.payload
                ) {
                  if (onTimeout) {
                    clearTimeout(onTimeout);
                  }
                  resolve(newDoc.response.payload);
                  if (thisSub) {
                    thisSub();
                  }
                }
              });
              onTimeout = setTimeout(() => {
                console.log(
                  "operation timeout",
                  docRef.path,
                  order.call,
                  order.payload
                );
                thisSub();
                reject({
                  status: {
                    code: "e4019",
                    details: "Order process timed out",
                    message: "Order process timed out",
                  },
                  success: false,
                });
              }, 60000);
            })
            .catch((error) => {
              console.log(
                "unable to set order",
                error,
                order.call,
                order.payload
              );
              reject({
                status: {
                  code: "e4017",
                  details: JSON.stringify(error),
                  message: "unable to set order",
                },
                success: false,
              });
            });
        });
      }
      return Promise.reject("No selected robot");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fbUser, selectedRobot, socketConnected, socketToken]
  );

  return sendOrderToSelectedRobotID;
};

export default useRobotOrder;
