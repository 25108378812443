import { ATOMS, selectors } from "recoil/atoms";
import { ButtonGroup, Grid, Typography } from "@mui/material";
import {
  IWorkConfigEnum,
  TOOL_SIDE,
  availableMowerMode,
  availableWorkConfigEntry,
} from "const";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ModeSelector, MowerSpeedSlider } from "./MowerModeSelector.styled";
import { RecoilState, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";

import CustomDivider from "components/corrector/CustomDivider";
import { IRobotStatusCode } from "types/statusCode";
import { ISnackbarContent } from "types/snackbar";
import { IToolModesAvailable } from "types/tool";
import { useTranslation } from "react-i18next";

interface IMowerModeSelectorProps {
  forceDisabled: boolean;
  onSpeedChange: React.Dispatch<React.SetStateAction<number>>;

  currentMode: availableWorkConfigEntry;
  requestedMode: availableWorkConfigEntry;
  setMode: React.Dispatch<React.SetStateAction<availableWorkConfigEntry>>;

  currentSpeed: number;
  targetSpeed: number;

  minSpeedCorrection: number;
  maxSpeedCorrection: number;
  stepSpeedCorrection: number;
  speedCorrectionUnit: string;

  mode: IToolModesAvailable[];
  side: TOOL_SIDE;
}

const MowerModeSelector = (props: IMowerModeSelectorProps) => {
  const { t } = useTranslation(["live", "mower"]);

  const setSnackbar = useSetRecoilState<Partial<ISnackbarContent>>(
    selectors[ATOMS.SNACKBAR] as RecoilState<Partial<ISnackbarContent>>
  );

  const [availableMowerInteraction, setAvailableMowerInteraction] = useState<
    string[]
  >([]);
  const [supportedMode, setSupportedMode] = useState<
    availableWorkConfigEntry[]
  >([]);
  const [disableReason, setDisableReason] = useState<
    Map<string, IRobotStatusCode>
  >(new Map());

  useEffect(() => {
    if (Array.isArray(props.mode) && props.mode.length > 0) {
      const thisMode = props.mode[0];
      const availableInteractions: string[] = [];
      const sm: availableWorkConfigEntry[] = [];
      const dr = new Map<string, IRobotStatusCode>();
      for (let i = 0; i < thisMode.tools_mode_values.length; i++) {
        const mode = thisMode.tools_mode_values[i];
        sm.push(mode.value as availableWorkConfigEntry);
        if (mode.can_be_activated === true) {
          availableInteractions.push(mode.value);
        } else {
          dr.set(mode.value, mode.reason);
        }
      }
      setAvailableMowerInteraction(availableInteractions);
      setSupportedMode(sm);
      setDisableReason(dr);
    }
  }, [props.mode]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ position: "relative" }}
      >
        {props.side === TOOL_SIDE.right ? (
          <KeyboardArrowRight
            color="error"
            style={{ position: "absolute", left: -8 }}
          />
        ) : null}
        <Typography variant="overline" align="center" noWrap>
          {availableMowerMode[props.currentMode]
            ? t(`mower:${availableMowerMode[props.currentMode]}` as const)
            : t(`mower:${availableMowerMode.Stop}` as const)}
        </Typography>
        {props.side === TOOL_SIDE.left ? (
          <KeyboardArrowLeft
            color="error"
            style={{ position: "absolute", right: -8 }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ height: 145 }}
      >
        <ButtonGroup
          variant="outlined"
          orientation="vertical"
          disableElevation
          fullWidth
        >
          {supportedMode.map((mode) => {
            if (availableMowerMode[mode]) {
              const reason = disableReason.get(mode);
              let visibleReason = "";
              if (reason) {
                if (reason.message.includes("low")) {
                  visibleReason = t("live:toolbarsTooLow");
                } else if (reason.message.includes("high")) {
                  visibleReason = t("live:toolbarsTooHigh");
                } else {
                  visibleReason = reason.message;
                }
              }
              const isDisabled =
                props.forceDisabled ||
                !availableMowerInteraction.includes(mode);

              return (
                <ModeSelector
                  isDisabled={isDisabled}
                  key={mode}
                  color="primary"
                  variant={
                    props.requestedMode === mode ? "contained" : "outlined"
                  }
                  onClick={() => {
                    if (isDisabled) {
                      setSnackbar({
                        content: visibleReason,
                        severity: "warning",
                        duration: 2000,
                        position: "bottom-center",
                      });
                    } else {
                      props.setMode(mode as availableWorkConfigEntry);
                    }
                  }}
                >
                  <Typography variant="button" noWrap color="inherit">
                    {t(`mower:${availableMowerMode[mode]}` as const)}
                  </Typography>
                </ModeSelector>
              );
            }
            return null;
          })}
        </ButtonGroup>
      </Grid>
      <CustomDivider transparent />
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <MowerSpeedSlider
          disabled={
            props.forceDisabled ||
            !availableMowerInteraction.includes(IWorkConfigEnum.WORK) ||
            props.currentMode !== IWorkConfigEnum.WORK
          }
        >
          <input
            disabled={
              props.forceDisabled ||
              !availableMowerInteraction.includes(IWorkConfigEnum.WORK) ||
              props.currentMode !== IWorkConfigEnum.WORK
            }
            type="range"
            min={parseInt(props.minSpeedCorrection.toFixed(0), 10)}
            max={parseInt(props.maxSpeedCorrection.toFixed(0), 10)}
            step={parseInt(props.stepSpeedCorrection.toFixed(0), 10)}
            value={parseInt(props.targetSpeed.toFixed(0), 10)}
            onChange={(e) => {
              props.onSpeedChange(parseInt(e.target.value, 10));
            }}
          />
        </MowerSpeedSlider>
      </Grid>
      <CustomDivider transparent size="small" />
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography variant="body1" align="center" color="primary">
          {props.currentSpeed.toFixed(0)} {props.speedCorrectionUnit}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MowerModeSelector;
