import { ATOMS, selectors } from "recoil/atoms";
import {
  AppVersionTypography,
  CenteredTypography,
  HideIcon,
  HomeRoot,
  MainAppBar,
  MainContainer,
  TextContainer,
} from "./MultiRobotHome.styled";
import { RecoilState, useRecoilState } from "recoil";

import { Menu } from "@mui/icons-material";
import { Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoRobot = () => {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useRecoilState<boolean>(
    selectors[ATOMS.UI_DRAWER_OPEN] as RecoilState<boolean>
  );

  return (
    <HomeRoot>
      <MainContainer drawerOpen={openDrawer}>
        <MainAppBar drawerOpen={openDrawer} elevation={0} position="fixed">
          <Toolbar>
            <HideIcon
              id="menu-btn"
              color="inherit"
              aria-label="open drawer"
              data-cy="open-drawer-btn"
              onClick={() => setOpenDrawer(true)}
              edge="start"
              hide={openDrawer}
              size="large"
            >
              <Menu fontSize="large" />
            </HideIcon>
          </Toolbar>
        </MainAppBar>
        <TextContainer>
          <AppVersionTypography data-cy="current-gui-version" variant="caption">
            {`v${process.env.REACT_APP_VERSION}`}
          </AppVersionTypography>
          <CenteredTypography variant="h4" color="primary">
            {t("noBakusAvailable")}
          </CenteredTypography>
        </TextContainer>
      </MainContainer>
    </HomeRoot>
  );
};

export default NoRobot;
