import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { IRobotCapabilities } from "types/capabilities";

const retrieveVersionForCapability = (
  capabilities: IRobotCapabilities,
  capability: string
): number => {
  if (capabilities[capability]) {
    const capabilityVersion = capabilities[capability];
    if (capabilityVersion !== null && capabilityVersion !== undefined) {
      return capabilityVersion;
    }
  }

  return -1;
};

const useCapabilities = (capability: string, version: number) => {
  const robotCapabilities = useRecoilValue<IRobotCapabilities>(
    selectors[ATOMS.CAPABILITIES] as RecoilState<IRobotCapabilities>
  );

  const [canUse, setCanUse] = useState(false);

  useEffect(() => {
    setCanUse(
      retrieveVersionForCapability(robotCapabilities, capability) >= version
    );
  }, [capability, robotCapabilities, version]);

  return [canUse];
};

export default useCapabilities;
