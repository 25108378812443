import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";

interface ITurtleIconProps {
  bakusColor?: string;
  arrowColor?: string;
  fontSize?: string | number;
}

const Turtle = ({ bakusColor, arrowColor, fontSize }: ITurtleIconProps) => {
  const theme = useTheme();

  bakusColor = bakusColor ?? theme.palette.primary.main;
  arrowColor = arrowColor ?? "#CFCECE";
  fontSize = fontSize ?? "1.3rem";

  return (
    <SvgIcon viewBox="0 0 88.1 59.5" style={{ fontSize: fontSize }}>
      <g>
        <path
          fill={bakusColor}
          d="M65.9,29.9V32l-1.3,0.6v1.8c-5.6-0.5-10.5,1.4-10.5,1.4s-1.4-0.1-2.1-0.1c-0.6,0-1,0.3-1,0.3l0.1,3.8h-13
			c0,0,0.1-2.8,0-3.5c-0.1-0.6-0.5-0.6-0.5-0.6H35L32.3,35c-1.6-1.1-7.8-0.9-7.8-0.9v-1.4l-1.3-0.8v-2v-2.1l1.3-0.6v-1.8
			C30,25.9,34.9,24,34.9,24s1.4,0.1,2.1,0.1c0.6,0,1-0.3,1-0.3L37.9,20h13c0,0-0.1,2.8,0,3.5c0.1,0.6,0.5,0.6,0.5,0.6H54l2.7,0.8
			c1.6,1.1,7.8,0.9,7.8,0.9v1.4l1.3,0.8V29.9z M72.9,24.8c0,0,1.6,0.3,1.9-1.1c0.3-1.4-0.3-6.2-0.9-7.8c0,0,0.2,0-0.5-0.3
			c-0.7-0.3-3.3-1.6-5.2-2c0,0-0.4,0-2.3,2.2l-42-0.1l-1,0.1l-1.9-1.9c0,0-0.2-0.4-1.1-0.1c-0.9,0.3-4.9,1.9-5,2.2
			c0,0-0.9,1.8-0.9,7.8c0,0,0.1,1,1,1.2c0.9,0.1,2.2,0.1,2.2,0.1s1.9,0,2.3,0.3c0,0,0.2,4.6,0,9.5c0,0-0.8,0.4-2.6,0.4h-1.8
			c0,0-1,0.1-1.1,1.2C14,37.3,13.9,40.1,15,44c0,0,2.5,1.4,5.5,2.2c0,0,0.1,0.3,0.7-0.4c0.6-0.6,1.8-1.8,1.8-1.8l1.2,0
			c0,0,26,0.2,41.8,0l2.2,2.2c0,0,0.5,0.2,5.7-2.2c0,0,1.1-2.3,1.1-8c0,0-0.2-1.4-2-1.2c0,0-3,0.1-3.5-0.4c-0.1-0.8-0.1-9.3-0.1-9.3
			S69.9,24.7,72.9,24.8"
        />
        <g>
          <path
            fill={arrowColor}
            d="M12.1,49.1C6,44.2,5,39.2,5,29.9c0-9.5,1.1-14.5,7.3-19.5l-1-1.3c-6.8,5.3-8,11-8,20.8
				c0,9.6,1.2,15.1,7.7,20.4L12.1,49.1z"
          />
          <polygon fill={arrowColor} points="15.4,7 9.6,6.5 14.5,12.8" />
          <polygon fill={arrowColor} points="9.3,53 15.1,52.6 14.3,46.9" />
        </g>
        <g>
          <path
            fill={arrowColor}
            d="M76.8,9.2l-1,1.3c6.3,5,7.3,10,7.3,19.5c0,9.3-1,14.2-7.1,19.2l1,1.3c6.5-5.3,7.7-10.8,7.7-20.4
				C84.8,20.1,83.6,14.5,76.8,9.2z"
          />
          <polygon fill={arrowColor} points="78.5,6.5 72.7,7 73.6,12.8" />
          <polygon fill={arrowColor} points="73,52.6 78.8,53 73.8,46.9" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Turtle;
