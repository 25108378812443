import { Buffer } from "buffer";
import zlib from "react-zlib-js";

export const mapDeserializer = (map: any) => {
  switch (typeof map) {
    case "object":
      return map;
    case "string":
      try {
        return JSON.parse(zlib.inflateSync(Buffer.from(map, "base64")));
      } catch (error) {
        console.log("Unable to deserialize map", error);
        return;
      }
    default:
      throw new Error("Invalid map type");
  }
};

export const arrayDeserializer = (array: any) => {
  switch (typeof array) {
    case "string":
      try {
        return JSON.parse(array);
      } catch (error) {
        console.log("Unable to deserialize", error);
        return array;
      }
    default:
      return array;
  }
};

export const putDateReviver = (data: any) => {
  if (typeof data === "object") {
    data.date = new Date().getTime();
  }
  return data;
};
