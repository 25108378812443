import { Box, Stack, StackProps, styled } from "@mui/material";

import { borderRadius } from "utils/sharedStyles";

export const LiveMoveManagerContainer = styled(
  (props: StackProps) => <Stack direction="column" height="100%" {...props} />,
  {
    name: "LiveMoveManagerContainer",
  }
)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

export const SliderBox = styled(Box, {
  name: "SliderBox",
})(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0.75),
}));

export const TurtleBox = styled(Box, {
  name: "TurtleBox",
})(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  margin-top: ${theme.spacing(3)};
  padding: ${theme.spacing(1)};
`
);

export const SpeedSlider = styled("div", {
  name: "SpeedSlider",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  & input {

    &::-webkit-slider-thumb {
      appearance: none;

      background-color: ${disabled ? "#777" : theme.palette.primary.main};
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(./icons/import_export-24px.svg);

      transform: rotate(90deg);

      border: ${
        disabled ? "2px solid" : `2px solid ${theme.palette.primary.main}`
      };
      border-radius: 6px;
      cursor: pointer;

      fill: white;
      height: 64px;
      width: 64px;
    }

    appearance: none;

    transform: rotate(90deg);
    transform-origin: 32px 32px;

    background-color: ${theme.palette.background.default};

    border: 2px solid;
    border-color: ${disabled ? "#777" : theme.palette.primary.main};
    border-radius: ${borderRadius}px;

    height: 36px;
    margin: 0;
    width: 300px;
  }
  height: 300px;
  width: 128px;

  display: inline-block;
  padding: 0;
`
);

export const LateralSlider = styled("div", {
  name: "LateralSlider",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  & input {

      &::-webkit-slider-thumb {
        appearance: none;

        background-color: ${disabled ? "#777" : theme.palette.primary.main};
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(./icons/zoom_out_map-24px.svg);

        border: ${
          disabled ? "2px solid" : `2px solid ${theme.palette.primary.main}`
        };
        border-radius: 6px;
        cursor: pointer;

        fill: white;
        height: 64px;
        width: 64px;
      }

    appearance: none;

    background-color: ${theme.palette.background.default};

    border: 2px solid;
    border-color: ${disabled ? "#777" : theme.palette.primary.main};
    border-radius: ${borderRadius}px;

    height: 36px;
    margin: 0;
    width: 300px;
  }
  height: 60px;
  width: 305px;

  display: flex;
  align-items: center;
  position: relative
  padding: 0;
`
);

// SliderBase SliderHorizontal SliderBaseAngular (disabled && sliderBaseAngularDisabled)
export const AngularSlider = styled("div", {
  name: "AngularSlider",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
   & input {

      &::-webkit-slider-thumb {
        appearance: none;

        background-color: ${disabled ? "#777" : theme.palette.primary.main};
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(./icons/import_export-24px.svg);

        transform: rotate(90deg);

        border: ${
          disabled ? "2px solid" : `2px solid ${theme.palette.primary.main}`
        };
        border-radius: 6px;
        cursor: pointer;

        fill: white;
        height: 64px;
        width: 64px;
      }

    appearance: none;

    background-color: ${theme.palette.background.default};

    border: 2px solid;
    border-color: ${disabled ? "#777" : theme.palette.primary.main};
    border-radius: ${borderRadius}px;

    height: 36px;
    margin: 0;
    width: 300px;
  }
  height: 60px;
  width: 305px;

  display: flex;
  align-items: center;
  position: relative
  padding: 0;
`
);

export const CenterIndicator = styled("div", {
  name: "CenterIndicator",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  pointer-events: none;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  border-color: ${disabled ? "#777" : theme.palette.error.main};
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 6px;
  height: 64px;
  width: 66px;
  transform: translateX(calc(150px - 33px));
`
);
