import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useLang = () => {
  const { i18n } = useTranslation();
  const uiLang = useRecoilValue<string>(
    selectors[ATOMS.UI_LANG] as RecoilState<string>
  );

  useEffect(() => {
    i18n.changeLanguage(uiLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiLang]);
};

export default useLang;
