import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

const useHasAccess = (
  strategy: "all" | "any",
  requiredAccess: string[],
  verbose = false
) => {
  const roles = useRecoilValue<string[]>(
    selectors[ATOMS.USER_ACCESS] as RecoilState<string[]>
  );
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (roles && Array.isArray(requiredAccess)) {
      let result = false;
      switch (strategy) {
        case "all":
          result = requiredAccess.every((access) => roles.includes(access));
          break;
        case "any":
          result = requiredAccess.some((access) => roles.includes(access));
          break;
        default:
          result = false;
      }
      if (verbose) {
        console.log("useHasAccess", {
          roles,
          strategy,
          requiredAccess,
          result,
        });
      }
      setHasAccess(result);
      return;
    } else {
      setHasAccess(false);
    }
  }, [roles, strategy, requiredAccess, verbose]);

  return hasAccess;
};

export default useHasAccess;
