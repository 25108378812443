import { ATOMS, selectors } from "recoil/atoms";
import { AlertContent, SnackBarContainer } from "./SnackBar.styled";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { Grow } from "@mui/material";
import { ISnackbarContent } from "types/snackbar";

function GrowTransition(props: any) {
  return <Grow {...props} />;
}

const SnackBar = () => {
  const snackbar = useRecoilValue<ISnackbarContent>(
    selectors[ATOMS.SNACKBAR] as RecoilState<ISnackbarContent>
  );

  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const [severity, setSeverity] = useState<
    "error" | "info" | "success" | "warning"
  >("info");
  const [content, setContent] = useState<string | JSX.Element>("");
  const [vertical, setVertical] = useState<"bottom" | "top">("bottom");
  const [horizontal, setHorizontal] = useState<"left" | "right" | "center">(
    "center"
  );

  useEffect(() => {
    setOpen(snackbar.open);
    setDuration(snackbar.duration);
    setSeverity(snackbar.severity);
    setContent(snackbar.content);
    switch (snackbar.position) {
      case "bottom-left":
        setVertical("bottom");
        setHorizontal("left");
        break;
      case "bottom-center":
        setVertical("bottom");
        setHorizontal("center");
        break;
      case "bottom-right":
        setVertical("bottom");
        setHorizontal("right");
        break;
      case "top-left":
        setVertical("top");
        setHorizontal("left");
        break;
      case "top-center":
        setVertical("top");
        setHorizontal("center");
        break;
      case "top-right":
        setVertical("top");
        setHorizontal("right");
        break;
      default:
        setVertical("bottom");
        setHorizontal("center");
    }
  }, [snackbar]);

  const onClose = () => {
    // Ensure callback is fire once
    if (open) {
      setOpen(false);
      if (
        snackbar.onCloseCallback !== null &&
        snackbar.onCloseCallback !== undefined
      ) {
        snackbar.onCloseCallback();
      }
    }
  };

  return (
    <SnackBarContainer
      data-cy="snackbar-container"
      anchorOrigin={{ horizontal, vertical }}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      vertical={vertical}
      TransitionComponent={GrowTransition}
    >
      <AlertContent
        severity={severity}
        onClose={onClose}
        elevation={6}
        variant="filled"
      >
        {content}
      </AlertContent>
    </SnackBarContainer>
  );
};

export default SnackBar;
