import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { LatencyTypography } from "./RobotWSLatency.styled";

interface IRobotWSLatencyProps {
  forceWhite?: boolean;
}

const RobotWSLatency = ({ forceWhite = false }: IRobotWSLatencyProps) => {
  const WSLatency = useRecoilValue<number>(
    selectors[ATOMS.WS_LATENCY] as RecoilState<number>
  );

  if (WSLatency === Infinity) return null;

  return (
    <LatencyTypography
      variant="caption"
      wsLatency={WSLatency}
      forceWhite={forceWhite}
    >
      {WSLatency}
    </LatencyTypography>
  );
};

export default RobotWSLatency;
