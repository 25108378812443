import { Alert, AlertTitle, Typography } from "@mui/material";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { ROUTES } from "constants/routes";
import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type IntercepCalibrationDoneStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
};

const IntercepCalibrationDoneStep = ({
  sendAction,
}: IntercepCalibrationDoneStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  const navigate = useNavigate();

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Alert severity="success" variant="filled">
          <AlertTitle>{t("common:success")}</AlertTitle>
          <Typography>{t("intercep:calibrationStepTitleSuccess")}</Typography>
        </Alert>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          onClick={() => {
            sendAction({
              type: "CALIBRATION_FINISHED",
              step: INTERCEP_CALIBRATION_STEP.IDLE,
              callback: () => {
                navigate(ROUTES.ROOT, { replace: true });
              },
            });
          }}
        >
          {t("common:done")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationDoneStep;
