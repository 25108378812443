import { ATOMS, selectors } from "recoil/atoms";
import { Grid, Typography } from "@mui/material";
import { IToolsModesAvailable, IToolsPresent } from "types/tool";
import { RecoilState, useRecoilValue } from "recoil";
import { TOOL_ID, TOOL_SIDE, availableWorkConfigEntry } from "const";
import { useEffect, useState } from "react";

import CustomDivider from "components/corrector/CustomDivider";
import FloatingSlider from "components/orders/Floating/FloatingSlider";
import { IPartialPublishedStatus } from "types/live";
import { LiveDataEvent } from "managers/LiveDataManager";
import LiveToolsConfigSwapper from "components/orders/Live/LiveToolsConfigSwapper";
import ToolbarGroup from "components/orders/Toolbar/ToolbarGroup";
import ToolbarSlider from "components/orders/Toolbar/ToolbarSlider";
import { ToolbarSliderContainer } from "./LiveToolbarManager.styled";
import useLiveData from "hooks/live/useLiveData";
import { useTranslation } from "react-i18next";

interface ILiveToolbarManagerProps {
  setPublishedContent: (arg: IPartialPublishedStatus) => void;
  disabled: boolean;
  fallbackIntercepMode: availableWorkConfigEntry;
}

const liveFields: LiveDataEvent[] = [
  LiveDataEvent.ToolsStatusLeft,
  LiveDataEvent.ToolsStatusRight,
];

const LiveToolbarManager = ({
  disabled,
  setPublishedContent,
  fallbackIntercepMode,
}: ILiveToolbarManagerProps) => {
  const { t } = useTranslation(["live"]);

  const { initialized, toolsConstTop, toolsConstBottom } = useLiveData(
    liveFields,
    "LiveToolbarManager"
  );

  const modesAvailable = useRecoilValue<IToolsModesAvailable>(
    selectors[ATOMS.TOOLS_MODES_AVAILABLE] as RecoilState<IToolsModesAvailable>
  );
  const toolsPresent = useRecoilValue<IToolsPresent>(
    selectors[ATOMS.TOOLS_PRESENT] as RecoilState<IToolsPresent>
  );

  const [combined, setCombined] = useState(false);

  const [targetHeightLeft, targetHeightLeftSet] = useState("0");
  const [targetHeightRight, targetHeightRightSet] = useState("0");
  const [floatingLeft, setFloatingLeft] = useState(false);
  const [floatingRight, setFloatingRight] = useState(false);

  const [toolsHeightInteractingLeft, setToolsHeightInteractingLeft] =
    useState(false);
  const [toolsHeightInteractingRight, setToolsHeightInteractingRight] =
    useState(false);

  useEffect(() => {
    if (initialized === true) {
      // Compute neutral
      let neutralLeft = toolsHeightInteractingLeft;
      let neutralRight = toolsHeightInteractingRight;
      if (combined) {
        neutralLeft = neutralRight =
          toolsHeightInteractingLeft || toolsHeightInteractingRight;
      }

      setPublishedContent({
        tool: {
          left: {
            floating: floatingLeft,
            neutral: !neutralLeft,
            targetHeight:
              targetHeightLeft === "-1"
                ? toolsConstTop
                : targetHeightLeft === "1"
                ? toolsConstBottom
                : undefined,
          },
          right: {
            floating: floatingRight,
            neutral: !neutralRight,
            targetHeight:
              targetHeightRight === "-1"
                ? toolsConstTop
                : targetHeightRight === "1"
                ? toolsConstBottom
                : undefined,
          },
        },
      });
    }
  }, [
    setPublishedContent,
    initialized,
    combined,
    floatingLeft,
    floatingRight,
    targetHeightLeft,
    targetHeightRight,
    toolsConstBottom,
    toolsConstTop,
    toolsHeightInteractingLeft,
    toolsHeightInteractingRight,
    fallbackIntercepMode,
  ]);

  const handleLeftFloatingSwitch = () => {
    setFloatingLeft((prev) => !prev);
  };
  const handleRightFloatingSwitch = () => {
    setFloatingRight((prev) => !prev);
  };

  const handleChangeLeft = (event: React.ChangeEvent<HTMLInputElement>) => {
    targetHeightLeftSet(event.target.value);
    if (combined) targetHeightRightSet(event.target.value);
  };

  const handleChangeRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    targetHeightRightSet(event.target.value);
    if (combined) targetHeightLeftSet(event.target.value);
  };

  const onDragStartLeft = () => {
    setToolsHeightInteractingLeft(true);
  };
  const onDragStartRight = () => {
    setToolsHeightInteractingRight(true);
  };

  const onDragEndLeft = () => {
    targetHeightLeftSet("0");
    if (combined) targetHeightRightSet("0");
    setToolsHeightInteractingLeft(false);
  };

  const onDragEndRight = () => {
    targetHeightRightSet("0");
    if (combined) targetHeightLeftSet("0");
    setToolsHeightInteractingRight(false);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <LiveToolsConfigSwapper setPublishedContent={setPublishedContent} />
        </Grid>
        <CustomDivider transparent />
        <Grid item xs>
          <ToolbarSliderContainer>
            <ToolbarSlider
              data-cy="toolbar-slider-left"
              floating={floatingLeft}
              forceDisabled={disabled}
              onDragEnd={onDragEndLeft}
              onDragStart={onDragStartLeft}
              onTargetChange={handleChangeLeft}
              targetHeight={targetHeightLeft}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.TOOLBAR &&
                  mode?.tool_id?.side === TOOL_SIDE.left
              )}
            />
          </ToolbarSliderContainer>
        </Grid>
        <Grid item xs={2}>
          <ToolbarSliderContainer>
            <ToolbarGroup combined={combined} setCombined={setCombined} />
          </ToolbarSliderContainer>
        </Grid>
        <Grid item xs>
          <ToolbarSliderContainer>
            <ToolbarSlider
              data-cy="toolbar-slider-right"
              floating={floatingRight}
              forceDisabled={disabled}
              onDragEnd={onDragEndRight}
              onDragStart={onDragStartRight}
              onTargetChange={handleChangeRight}
              targetHeight={targetHeightRight}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.TOOLBAR &&
                  mode?.tool_id?.side === TOOL_SIDE.right
              )}
            />
          </ToolbarSliderContainer>
        </Grid>
      </Grid>
      {toolsPresent?.is_floating_toolbar === true ? (
        <Grid container justifyContent="center" alignItems="flex-start">
          <Grid item xs={5}>
            <ToolbarSliderContainer>
              <FloatingSlider
                data-cy="toolbar-floating-switch-left"
                forceDisabled={disabled}
                isFloating={floatingLeft}
                onFloatingChange={handleLeftFloatingSwitch}
                mode={modesAvailable.modes_available.filter(
                  (mode) =>
                    mode?.tool_id?.name === TOOL_ID.TOOLBAR &&
                    mode?.tool_id?.side === TOOL_SIDE.left
                )}
              />
            </ToolbarSliderContainer>
          </Grid>
          <Grid item xs={2} mt={2}>
            <Typography variant="h6" align="center">
              {t("live:floating")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <ToolbarSliderContainer>
              <FloatingSlider
                data-cy="toolbar-floating-switch-right"
                forceDisabled={disabled}
                isFloating={floatingRight}
                onFloatingChange={handleRightFloatingSwitch}
                mode={modesAvailable.modes_available.filter(
                  (mode) =>
                    mode?.tool_id?.name === TOOL_ID.TOOLBAR &&
                    mode?.tool_id?.side === TOOL_SIDE.right
                )}
              />
            </ToolbarSliderContainer>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default LiveToolbarManager;
