import { Grid, Stack, styled } from "@mui/material";

export const DevContainer = styled("div", {
  name: "DevContainer",
})`
  background-color: red;
`;

export const DevSpan = styled("span", {
  name: "DevSpan",
})`
  background-color: white;
  border-color: white;
  color: red;
`;

export const DevGrid = styled(Grid, {
  name: "DevGrid",
})`
  background-color: red;
`;

export const DevStack = styled(Stack, {
  name: "DevStack",
})`
  background-color: red;
`;
