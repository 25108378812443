import { styled } from "@mui/material";

export const LiveWrapper = styled("div", { name: "LiveWrapper" })({
  flex: 2,
  width: "100%",
  margin: "0 auto",
});

export const LiveContainer = styled("div", { name: "LiveContainer" })({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

export const LiveButtonsContainer = styled("div", {
  name: "LiveButtonsContainer",
})({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 5,
});
