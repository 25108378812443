import { Grid, styled } from "@mui/material";

export type CustomDividerSupportedSize = "small" | "extraSmall" | "default";

export const GridContainer = styled(Grid, {
  name: "CustomDividerGridContainer",
  shouldForwardProp: (prop) => prop !== "size",
})<{
  size: CustomDividerSupportedSize;
}>(({ theme, size }) => {
  let pad: string;
  switch (size) {
    case "small":
      pad = theme.spacing(1, 0);
      break;
    case "extraSmall":
      pad = theme.spacing(0.5, 0);
      break;
    default:
      pad = theme.spacing(2, 0);
      break;
  }

  return `
      padding: ${pad};
    `;
});
