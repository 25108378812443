import { ATOMS, selectors } from "recoil/atoms";
import { DrawerHeader, GuiDrawer } from "./AppDrawer.styled";
import { RecoilState, useRecoilState } from "recoil";

import { ChevronLeft } from "@mui/icons-material";
import DrawerMenu from "components/controls/DrawerMenu";
import { IconButton } from "@mui/material";

const AppDrawer = () => {
  const [openDrawer, setOpenDrawer] = useRecoilState<boolean>(
    selectors[ATOMS.UI_DRAWER_OPEN] as RecoilState<boolean>
  );

  return (
    <GuiDrawer
      variant="temporary"
      anchor="left"
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      ModalProps={{ onClose: () => setOpenDrawer(false) }}
    >
      <DrawerHeader
        id="close-drawer"
        data-cy="close-drawer-btn"
        onClick={() => setOpenDrawer(false)}
      >
        <IconButton>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <DrawerMenu />
    </GuiDrawer>
  );
};

export default AppDrawer;
