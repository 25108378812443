import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useSetRecoilState } from "recoil";
import { fireAnalytics, fireAuth } from "config/firebase";

import { IFirebaseUser } from "types/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { setUserId } from "firebase/analytics";
import { useEffect } from "react";

const useFirebaseUser = () => {
  const setFBUserSelector = useSetRecoilState<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const setWaitFirebaseInit = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_WAITING_FIREBASE_INIT] as RecoilState<boolean>
  );

  useEffect(() => {
    onAuthStateChanged(fireAuth, (currentUser) => {
      setWaitFirebaseInit(false);

      if (!currentUser) {
        setFBUserSelector(null);
      } else {
        setFBUserSelector({
          displayName: currentUser.displayName ?? "",
          email: currentUser.email ?? "",
          uid: currentUser.uid,
        });
        setUserId(fireAnalytics, currentUser.uid);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFirebaseUser;
