import { FormControl, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";

import { FloatingSwitch } from "./FloatingSlider.styled";
import { IToolModesAvailable } from "types/tool";
import { useTranslation } from "react-i18next";

interface IFloatingSliderProps {
  forceDisabled: boolean;
  isFloating: boolean;
  onFloatingChange: () => void;
  mode: IToolModesAvailable[];
  "data-cy"?: string;
}

const FloatingSlider = (props: IFloatingSliderProps) => {
  const { t } = useTranslation(["live"]);
  const [canFloat, setCanFloat] = useState(false);

  useEffect(() => {
    if (Array.isArray(props.mode) && props.mode.length > 0) {
      const thisMode = props.mode[0];
      for (let i = 0; i < thisMode.tools_mode_values.length; i++) {
        const mode = thisMode.tools_mode_values[i];
        if (mode.value === "floating") {
          setCanFloat(mode.can_be_activated);
          break;
        }
      }
    }
  }, [props.mode]);

  return (
    <FormControl>
      <FloatingSwitch
        data-cy={props["data-cy"]}
        disabled={props.forceDisabled || !canFloat}
        checked={props.isFloating}
        onChange={props.onFloatingChange}
        // value={t("live:floatingMode")}
        value={props.isFloating}
      />
      <FormHelperText
        style={{
          marginTop: 0,
          minHeight: 38,
          lineHeight: "38px",
          whiteSpace: "nowrap",
        }}
      >
        {!canFloat ? t("live:toolbarsTooHigh") : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default FloatingSlider;
