import { IWorkConfigEnum, availableWorkConfigEntry } from "const";

export const secureOrder = (
  mode: availableWorkConfigEntry
): availableWorkConfigEntry => {
  switch (mode) {
    case IWorkConfigEnum.CLEAN_END:
      return IWorkConfigEnum.IDLE;
    case IWorkConfigEnum.IDLE_END:
      return IWorkConfigEnum.IDLE;
    default:
      return mode;
  }
};

export const isModeEquals = (
  requestedMode: availableWorkConfigEntry,
  mode: availableWorkConfigEntry
): boolean => {
  if (
    (requestedMode === IWorkConfigEnum.CLEAN ||
      requestedMode === IWorkConfigEnum.CLEAN_END) &&
    (mode === IWorkConfigEnum.CLEAN || mode === IWorkConfigEnum.CLEAN_END)
  ) {
    return true;
  }

  if (
    (requestedMode === IWorkConfigEnum.IDLE ||
      requestedMode === IWorkConfigEnum.IDLE_END) &&
    (mode === IWorkConfigEnum.IDLE || mode === IWorkConfigEnum.IDLE_END)
  ) {
    return true;
  }

  return requestedMode === mode;
};
