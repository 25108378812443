import {
  IBakusWizardVersion,
  IUpdateBakusVersion,
  ImageType,
} from "types/version";
import { ILanguage, IVersionType } from "types/firebase";

import { IAutopilotVersionStatus } from "types/capabilities";
import { fullyExecutor } from "hooks/utils/useFullyKiosk";
import { isDefined } from "./object";

export const getVersionPrettyName = (
  version: IVersionType,
  language: string
): string => {
  if (!isDefined(version.public_name)) {
    return version.name;
  }
  const code = Object.keys(version.public_name).find((c) =>
    c.startsWith(language)
  ) as ILanguage["code"];
  if (!isDefined(code)) {
    return version.name;
  }
  return version.public_name[code]!;
};

export const checkShouldUpdateAutopilotVersion = (
  robotVersion: IUpdateBakusVersion,
  auto: IAutopilotVersionStatus | null
): boolean => {
  if (robotVersion.versions.length === 0) {
    return false;
  }
  if (auto == null) {
    return false;
  }

  const autopilotVersionData = robotVersion.versions.find(
    (f) => f.type === ImageType.AUTOPILOT
  );
  if (autopilotVersionData == null) {
    return false;
  }

  if (autopilotVersionData.version.version !== auto.version) {
    console.log(
      "Autopilot version mismatch",
      autopilotVersionData.version.version,
      auto.version
    );
    return true;
  }

  return false;
};

export const checkShouldUpdateBakusVersion = (
  robotVersion: IUpdateBakusVersion,
  bakus: IBakusWizardVersion | null
): boolean => {
  if (robotVersion.versions.length === 0) {
    return false;
  }
  if (bakus == null) {
    return false;
  }

  const bakusVersionData = robotVersion.versions.find(
    (f) => f.type === ImageType.WIZARD
  );
  if (bakusVersionData == null) {
    return false;
  }

  if (bakusVersionData.version.version !== bakus.version) {
    console.log(
      "Bakus version mismatch",
      bakusVersionData.version.version,
      bakus.version
    );
    return true;
  }

  return false;
};

export const checkShouldUpdateGuiVersion = (
  robotVersion: IUpdateBakusVersion
): boolean => {
  if (robotVersion.versions.length === 0) {
    return false;
  }
  try {
    const actualUrl = fullyExecutor<string>("getStartUrl");
    const expectedUrl = getExpectedGuiURL(robotVersion);

    return expectedUrl !== actualUrl;
  } catch (e) {
    return false;
  }
};

export const getExpectedGuiURL = (
  robotVersion: IUpdateBakusVersion
): string | null => {
  const guiVersion = robotVersion.versions.find(
    (v) => v.type === ImageType.GUI
  );
  if (guiVersion) {
    return guiVersion.version.endpoint;
  }
  return null;
};
