import { Button } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { WholeAppWrapper } from "./shield.styled";
import { useTranslation } from "react-i18next";

const Shield = () => {
  const { t } = useTranslation();

  return (
    <WholeAppWrapper>
      <div>
        <Warning color="error" style={{ marginBottom: 15 }} />
        <br />
        {t("errorOccurred")}
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.reload();
          }}
          style={{ marginTop: 15 }}
        >
          {t("reloadPage")}
        </Button>
      </div>
    </WholeAppWrapper>
  );
};

export default Shield;
