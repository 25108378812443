import { ATOMS, selectors } from "recoil/atoms";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";
import { RecoilValue, useRecoilValue } from "recoil";

import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type IntercepCalibrationAskPutEmergencyStopStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
  continueText?: string;
};

const IntercepCalibrationAskPutEmergencyStopStep = ({
  sendAction,
  continueText,
}: IntercepCalibrationAskPutEmergencyStopStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  const estop = useRecoilValue<number>(
    selectors[ATOMS.ESTOP] as RecoilValue<number>
  );

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Typography textAlign="center">
          {t("intercep:calibrationStepActionPutStopEmergency")}
        </Typography>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          disabled={estop === 0}
          onClick={() => {
            sendAction({
              type: "AU_ON",
            });
          }}
        >
          {continueText || t("common:continue")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationAskPutEmergencyStopStep;
