import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { ISelectedRobot } from "types/userRobot";
import { Navigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

const RequiredForLiveControl = ({ children }: { children: JSX.Element }) => {
  const socketConnected = useRecoilValue(
    selectors[ATOMS.WS_CONNECTED] as RecoilState<boolean>
  );

  const wsRobotID = useRecoilValue<string>(
    selectors[ATOMS.WS_ROBOT_ID] as RecoilState<string>
  );

  const selectedRobot = useRecoilValue<ISelectedRobot | null>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot | null>
  );

  if (!socketConnected || wsRobotID !== selectedRobot?.id) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return children;
};

export default RequiredForLiveControl;
