import {
  ILiveMoveStatus,
  ILiveStatus,
  ILiveToolsConstStatus,
  ILiveToolsStatus,
} from "types/live";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import EventEmitter from "events";
import { IWorkConfigEnum } from "const";
import { isClose } from "utils/number";

export enum LiveDataEvent {
  Init = "init",

  // Move
  MoveAngular = "move:angular_angle",
  MoveLateral = "move:lateral_angle",

  // Tools const
  ToolsConstTop = "tools_const:TOP",
  ToolsConstBottom = "tools_const:BOTTOM",

  // Tools status
  ToolsStatusInterceptWorkStatusLeft = "tools_status:intercep_work_status_left",
  ToolsStatusInterceptWorkStatusRight = "tools_status:intercep_work_status_right",
  ToolsStatusInterceptOutsideWorkStatusLeft = "tools_status:intercep_outside_work_status_left",
  ToolsStatusInterceptOutsideWorkStatusRight = "tools_status:intercep_outside_work_status_right",
  ToolsStatusLeft = "tools_status:tools_status_left",
  ToolsStatusRight = "tools_status:tools_status_right",
  ToolsStatusMowerSpeedLeft = "tools_status:mower_speed_left",
  ToolsStatusMowerSpeedRight = "tools_status:mower_speed_right",
  ToolsStatusMowerWorkStatusLeft = "tools_status:mower_work_status_left",
  ToolsStatusMowerWorkStatusRight = "tools_status:mower_work_status_right",

  // Turtle mode
  TurtleMode = "turtle_mode",
}

const fallbackIntercepMode: IWorkConfigEnum = IWorkConfigEnum.STOP;
const angularEpsilon = 0.01; // prevent jittering

class LiveDataManager extends EventEmitter {
  private static instance: LiveDataManager;

  private _status: ILiveStatus | null = null;
  private _initialized: boolean = false;

  constructor() {
    super();

    const maxListeners = Object.keys(LiveDataEvent).length * 3;

    console.log("Start live data manager with max listeners", maxListeners);

    this.setMaxListeners(maxListeners);
  }

  public get status(): ILiveStatus | null {
    return this._status;
  }

  public get initialized(): boolean {
    return this._initialized;
  }

  // Move
  public get angularAngle(): number {
    return this._status?.move?.angular_angle ?? 0;
  }
  public get lateralAngle(): number {
    return this._status?.move?.lateral_angle ?? 0;
  }

  // Tools const
  public get toolsConstTop(): number {
    return this._status?.tools_const?.TOP ?? 20;
  }
  public get toolsConstBottom(): number {
    return this._status?.tools_const?.BOTTOM ?? 360;
  }

  // Tools status
  public get toolsStatusInterceptWorkStatusLeft(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.intercep_work_status_left ??
      fallbackIntercepMode
    );
  }
  public get toolsStatusInterceptWorkStatusRight(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.intercep_work_status_right ??
      fallbackIntercepMode
    );
  }
  public get toolsStatusInterceptOutsideWorkStatusLeft(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.intercep_outside_work_status_left ??
      fallbackIntercepMode
    );
  }
  public get toolsStatusInterceptOutsideWorkStatusRight(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.intercep_outside_work_status_right ??
      fallbackIntercepMode
    );
  }
  public get toolsStatusLeft(): number {
    return this._status?.tools_status?.tools_status_left ?? 0;
  }
  public get toolsStatusRight(): number {
    return this._status?.tools_status?.tools_status_right ?? 0;
  }
  public get toolsStatusMowerSpeedLeft(): number {
    return this._status?.tools_status?.mower_speed_left ?? 0;
  }
  public get toolsStatusMowerSpeedRight(): number {
    return this._status?.tools_status?.mower_speed_right ?? 0;
  }
  public get toolsStatusMowerWorkStatusLeft(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.mower_work_status_left ?? fallbackIntercepMode
    );
  }
  public get toolsStatusMowerWorkStatusRight(): IWorkConfigEnum {
    return (
      this._status?.tools_status?.mower_work_status_right ??
      fallbackIntercepMode
    );
  }

  public get turtleMode(): boolean {
    return this._status?.turtle_mode ?? false;
  }

  public static getInstance(): LiveDataManager {
    if (!LiveDataManager.instance) {
      LiveDataManager.instance = new LiveDataManager();
    }
    return LiveDataManager.instance;
  }

  public connect = (): void => {
    console.log("LiveDataManager connect");
    AutopilotSocketManager.getInstance().socketManager.emit(
      "live:init",
      this.handleInit
    );

    AutopilotSocketManager.getInstance().socketManager.on(
      "live:status",
      this.handleNewStatus
      // handleThrottledNewStatus
    );
  };

  public dispose = (): void => {
    AutopilotSocketManager.getInstance().socketManager.off("live:status");
    this._status = null;
  };

  private handleInit = (data: ILiveStatus): void => {
    // We should replace the entire state
    this._status = data;
    this._initialized = true;
    this.emit(LiveDataEvent.Init, data);
  };

  private handleNewStatus = (data: ILiveStatus): void => {
    if (this._status === null) {
      this._status = data;
      this.emit(LiveDataEvent.Init, data);
      return;
    }

    this.handleChangeOnMove(data.move);
    this.handleChangeOnToolsConst(data.tools_const);
    this.handleChangeOnToolsStatus(data.tools_status);
    this.handleChangeOnTurtleMode(data.turtle_mode);
  };

  private handleChangeOnMove = (move: ILiveMoveStatus): void => {
    if (this._status === null) {
      return;
    }

    if (
      !isClose(
        this._status.move.angular_angle,
        move.angular_angle,
        angularEpsilon
      )
    ) {
      this._status.move.angular_angle = move.angular_angle;
      this.emit(LiveDataEvent.MoveAngular, move.angular_angle);
    }

    if (
      !isClose(
        this._status.move.lateral_angle,
        move.lateral_angle,
        angularEpsilon
      )
    ) {
      this._status.move.lateral_angle = move.lateral_angle;
      this.emit(LiveDataEvent.MoveLateral, move.lateral_angle);
    }
  };

  private handleChangeOnToolsConst = (
    toolsConst: ILiveToolsConstStatus
  ): void => {
    if (this._status === null) {
      return;
    }

    if (this._status.tools_const.TOP !== toolsConst.TOP) {
      this._status.tools_const.TOP = toolsConst.TOP;
      this.emit(LiveDataEvent.ToolsConstTop, toolsConst.TOP);
    }

    if (this._status.tools_const.BOTTOM !== toolsConst.BOTTOM) {
      this._status.tools_const.BOTTOM = toolsConst.BOTTOM;
      this.emit(LiveDataEvent.ToolsConstBottom, toolsConst.BOTTOM);
    }
  };

  private handleChangeOnToolsStatus = (toolsStatus: ILiveToolsStatus): void => {
    if (this._status === null) {
      return;
    }

    if (
      this._status.tools_status.intercep_work_status_left !==
      toolsStatus.intercep_work_status_left
    ) {
      this._status.tools_status.intercep_work_status_left =
        toolsStatus.intercep_work_status_left;
      this.emit(
        LiveDataEvent.ToolsStatusInterceptWorkStatusLeft,
        toolsStatus.intercep_work_status_left
      );
    }

    if (
      this._status.tools_status.intercep_work_status_right !==
      toolsStatus.intercep_work_status_right
    ) {
      this._status.tools_status.intercep_work_status_right =
        toolsStatus.intercep_work_status_right;
      this.emit(
        LiveDataEvent.ToolsStatusInterceptWorkStatusRight,
        toolsStatus.intercep_work_status_right
      );
    }

    if (
      this._status.tools_status.intercep_outside_work_status_left !==
      toolsStatus.intercep_outside_work_status_left
    ) {
      this._status.tools_status.intercep_outside_work_status_left =
        toolsStatus.intercep_outside_work_status_left;
      this.emit(
        LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusLeft,
        toolsStatus.intercep_outside_work_status_left
      );
    }

    if (
      this._status.tools_status.intercep_outside_work_status_right !==
      toolsStatus.intercep_outside_work_status_right
    ) {
      this._status.tools_status.intercep_outside_work_status_right =
        toolsStatus.intercep_outside_work_status_right;
      this.emit(
        LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusRight,
        toolsStatus.intercep_outside_work_status_right
      );
    }

    if (
      this._status.tools_status.tools_status_left !==
      toolsStatus.tools_status_left
    ) {
      this._status.tools_status.tools_status_left =
        toolsStatus.tools_status_left;
      this.emit(LiveDataEvent.ToolsStatusLeft, toolsStatus.tools_status_left);
    }

    if (
      this._status.tools_status.tools_status_right !==
      toolsStatus.tools_status_right
    ) {
      this._status.tools_status.tools_status_right =
        toolsStatus.tools_status_right;
      this.emit(LiveDataEvent.ToolsStatusRight, toolsStatus.tools_status_right);
    }

    if (
      this._status.tools_status.mower_speed_left !==
      toolsStatus.mower_speed_left
    ) {
      this._status.tools_status.mower_speed_left = toolsStatus.mower_speed_left;
      this.emit(
        LiveDataEvent.ToolsStatusMowerSpeedLeft,
        toolsStatus.mower_speed_left
      );
    }

    if (
      this._status.tools_status.mower_speed_right !==
      toolsStatus.mower_speed_right
    ) {
      this._status.tools_status.mower_speed_right =
        toolsStatus.mower_speed_right;
      this.emit(
        LiveDataEvent.ToolsStatusMowerSpeedRight,
        toolsStatus.mower_speed_right
      );
    }

    if (
      this._status.tools_status.mower_work_status_left !==
      toolsStatus.mower_work_status_left
    ) {
      this._status.tools_status.mower_work_status_left =
        toolsStatus.mower_work_status_left;
      this.emit(
        LiveDataEvent.ToolsStatusMowerWorkStatusLeft,
        toolsStatus.mower_work_status_left
      );
    }

    if (
      this._status.tools_status.mower_work_status_right !==
      toolsStatus.mower_work_status_right
    ) {
      this._status.tools_status.mower_work_status_right =
        toolsStatus.mower_work_status_right;
      this.emit(
        LiveDataEvent.ToolsStatusMowerWorkStatusRight,
        toolsStatus.mower_work_status_right
      );
    }
  };

  private handleChangeOnTurtleMode = (data: boolean): void => {
    if (this._status === null) {
      return;
    }

    if (this._status.turtle_mode !== data) {
      this._status.turtle_mode = data;
      this.emit(LiveDataEvent.TurtleMode, data);
    }
  };
}

export default LiveDataManager;
