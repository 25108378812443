import { RouterProvider, createBrowserRouter } from "react-router-dom";

import GuiScaffold from "pages/GuiScaffold";
import IntercepCalibrationPage from "pages/IntercepCalibrationPage";
import LiveControlPage from "pages/LiveControlPage";
import { ROUTES } from "constants/routes";
import RequireAuth from "security/RequireAuth";
import RequiredForIntercepCalibration from "security/RequiredForIntercepCalibration";
import RequiredForLiveControl from "security/RequiredForLiveControl";
import SignIn from "components/SignIn";

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <SignIn />,
  },
  {
    path: ROUTES.LIVE_CONTROL,
    element: (
      <RequireAuth>
        <RequiredForLiveControl>
          <LiveControlPage />
        </RequiredForLiveControl>
      </RequireAuth>
    ),
  },
  {
    path: ROUTES.INTERCEP_CALIBRATION,
    element: (
      <RequireAuth>
        <RequiredForIntercepCalibration>
          <IntercepCalibrationPage />
        </RequiredForIntercepCalibration>
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: (
      <RequireAuth>
        <GuiScaffold />
      </RequireAuth>
    ),
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
