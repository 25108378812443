import { ATOMS, defaultLowUpdateVersion, selectors } from "recoil/atoms";
import { IUpdateLowLevelVersion, UpdaterStatus } from "types/version";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { BOTTOM_PANE_CONTENT } from "const";
import { VersionSocketManager } from "managers/VersionSocket";
import { isDefined } from "utils/object";
import { useEffect } from "react";
import usePaneManager from "hooks/ui/usePaneManager";
import { useRef } from "react";

const useLowLevelVersionWatcher = () => {
  const { showPane } = usePaneManager();

  const socketConnected = useRecoilValue<boolean>(
    selectors[ATOMS.VERSION_WS_CONNECTED] as RecoilState<boolean>
  );

  const setShowDrawerMenu = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_DRAWER_SHOW_LOW_LEVEL_UPDATE] as RecoilState<boolean>
  );

  const [robotLowLevelVersion, setRobotLowLevelVersion] =
    useRecoilState<IUpdateLowLevelVersion>(
      selectors[
        ATOMS.VERSION_ROBOT_LOW_LEVEL_DATA
      ] as RecoilState<IUpdateLowLevelVersion>
    );
  const lowLevelVersionMessage =
    useRef<IUpdateLowLevelVersion>(robotLowLevelVersion);

  const onLowLevelVersionCallback = (data: IUpdateLowLevelVersion) => {
    setRobotLowLevelVersion(data);
    lowLevelVersionMessage.current = data;

    if (data.global === UpdaterStatus.UPDATING) {
      // Enforce during update
      showPane(BOTTOM_PANE_CONTENT.low_level_version_update);
      setShowDrawerMenu(true);
      return;
    }

    if (isDefined(data.version) && data.global !== UpdaterStatus.UP_TO_DATE) {
      setShowDrawerMenu(true);
      return;
    }

    setShowDrawerMenu(false);
  };

  const resetUpdateState = () => {
    if (lowLevelVersionMessage.current.global === UpdaterStatus.UPDATING) {
      // During the update phase when Autopilot is restarting the socket is totally overloaded and will timeout. We want to avoid the close behavior.
      return;
    }

    setShowDrawerMenu(false);
    setRobotLowLevelVersion(defaultLowUpdateVersion);
    lowLevelVersionMessage.current = defaultLowUpdateVersion;
  };

  useEffect(() => {
    if (socketConnected) {
      const manager = VersionSocketManager.getSocket();

      manager.emit("low_level_version", onLowLevelVersionCallback);
      manager.on("low_level_version", onLowLevelVersionCallback);
    }

    return () => {
      const manager = VersionSocketManager.getSocket();

      manager.off("low_level_version", onLowLevelVersionCallback);

      resetUpdateState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected]);
};

export default useLowLevelVersionWatcher;
