import { ATOMS, selectors } from "recoil/atoms";
import {
  ConfigItemName,
  ConfigTextField,
} from "./LiveToolsConfigSwapper.styled";
import { IToolsConfig, dbToolConfig } from "types/tool";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { API_ORDERS } from "const";
import { IPartialPublishedStatus } from "types/live";
import { MenuItem } from "@mui/material";
import { defaultLivePublishedToolsData } from "hooks/robot/useLiveSocketControl";
import useRobotOrder from "hooks/robot/useRobotOrder";
import { useTranslation } from "react-i18next";

interface ILiveToolsConfigSwapperProps {
  setPublishedContent: (arg: IPartialPublishedStatus) => void;
}

const LiveToolsConfigSwapper = ({
  setPublishedContent,
}: ILiveToolsConfigSwapperProps) => {
  const { t, i18n } = useTranslation(["live", "tools"]);

  const toolsConfig = useRecoilValue<IToolsConfig>(
    selectors[ATOMS.TOOLS_CONFIG] as RecoilState<IToolsConfig>
  );
  const toolsConfigAvailable = useRecoilValue<dbToolConfig[]>(
    selectors[ATOMS.TOOLS_CONFIG_AVAILABLE] as RecoilState<dbToolConfig[]>
  );

  const [isSettingConfig, setIsSettingConfig] = useState(false);

  const [selectedConfig, setSelectedConfig] = useState(
    toolsConfig?.label?.id ?? ""
  );

  useEffect(() => {
    if (toolsConfig?.label?.id === "" && selectedConfig !== "") {
      console.log("Config seems reset", selectedConfig, toolsConfig?.label?.id);
      sendSetToolsConfigOrder(selectedConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolsConfig, selectedConfig]);

  const sendOrder = useRobotOrder();

  const sendSetToolsConfigOrder = async (selectedConfigValue: string) => {
    if (selectedConfigValue && selectedConfigValue !== toolsConfig?.label?.id) {
      setIsSettingConfig(true);
      try {
        setPublishedContent({
          tool: defaultLivePublishedToolsData,
        });
        await sendOrder(API_ORDERS.ToolsSetConfig, {
          config_name: selectedConfigValue,
        });
      } catch (e) {
        console.log("Unable to process set config", e);
      } finally {
        setIsSettingConfig(false);
      }
    }
  };

  return (
    <ConfigTextField
      fullWidth
      select
      size="small"
      id="tools-config-selector"
      data-cy="tools-config-select"
      label={t("tools:tools")}
      value={selectedConfig || ""}
      disabled={
        isSettingConfig ||
        !Array.isArray(toolsConfigAvailable) ||
        toolsConfigAvailable.length === 0
      }
      onChange={(event) => {
        setSelectedConfig(event.target.value);
        sendSetToolsConfigOrder(event.target.value);
      }}
      variant="outlined"
    >
      {toolsConfigAvailable.map((config) => (
        <MenuItem key={config.id} value={config.id} id={config.id}>
          <ConfigItemName equipped={selectedConfig === toolsConfig?.label?.id}>
            {config?.translations?.find((tr) =>
              tr.locale.startsWith(i18n.language)
            )?.name ?? config.name}
          </ConfigItemName>
        </MenuItem>
      ))}
    </ConfigTextField>
  );
};

export default LiveToolsConfigSwapper;
