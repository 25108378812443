import { ATOMS, selectors } from "recoil/atoms";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { RecoilState, useRecoilState } from "recoil";

import LeftSlideTransition from "components/utils/Transitions/LeftSlideTransition";
import useAuth from "hooks/auth/useAuth";
import { useTranslation } from "react-i18next";

const SignOutDialog = () => {
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const [signOutDialogOpen, setSignOutDialogOpen] = useRecoilState<boolean>(
    selectors[ATOMS.UI_SIGN_OUT_DIALOG_OPEN] as RecoilState<boolean>
  );

  return (
    <Dialog
      open={signOutDialogOpen}
      TransitionComponent={LeftSlideTransition}
      onClose={() => setSignOutDialogOpen(false)}
    >
      <DialogContent>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              gutterBottom
            >
              {t("disconnectSure")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup color="primary" orientation="horizontal" fullWidth>
              {/* <Button onClick={() => handleDeco()}>{t("yes")}</Button> */}
              <Button onClick={async () => await signOut()}>{t("yes")}</Button>
              <Button
                variant="contained"
                onClick={() => setSignOutDialogOpen(false)}
              >
                {t("no")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SignOutDialog;
