import { styled } from "@mui/material";

export const LogoImg = styled("img", {
  name: "LogoImg",
})(`
  width: 80%;
`);
export const NoConnectionDialogContent = styled("div", {
  name: "NoConnectionDialogContent",
})(`
  align-items: center;
  display: grid;
  flex-grow: 1;
  text-align: center;
`);
