import { ATOMS, selectors } from "recoil/atoms";
import { IStatusCodeData, IStatusCodeEntry } from "types/statusCode";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { collection, onSnapshot } from "firebase/firestore";

import { fireDatabase } from "config/firebase";
import { useEffect } from "react";

const useStatusCode = () => {
  const fbUser = useRecoilValue(selectors[ATOMS.FB_USER]);
  const setStatusCodes = useSetRecoilState<IStatusCodeData>(
    selectors[ATOMS.STATUS_CODE] as RecoilState<IStatusCodeData>
  );

  useEffect(() => {
    if (fbUser) {
      const unsub = onSnapshot(
        collection(fireDatabase, "status_codes"),
        (snap) => {
          if (snap.docs.length > 0) {
            const statusCodes: IStatusCodeData = {};
            snap.docs.forEach((doc) => {
              statusCodes[doc.id] = doc.data() as IStatusCodeEntry;
            });
            setStatusCodes(statusCodes);
          } else {
            setStatusCodes({});
          }
        }
      );
      return () => unsub();
    } else {
      setStatusCodes({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser]);
};

export default useStatusCode;
