import { useEffect, useState } from "react";

import { IToolModesAvailable } from "types/tool";
import { SliderContainer } from "./ToolbarSlider.styled";

interface IToolbarSliderProps {
  "data-cy"?: string;
  floating: boolean;
  forceDisabled: boolean;
  onDragEnd: () => void;
  onDragStart: () => void;
  onTargetChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  targetHeight: number | string;
  mode: IToolModesAvailable[];
}

const ToolbarSlider = (props: IToolbarSliderProps) => {
  const [canHeightFollow, setCanHeightFollow] = useState(false);

  useEffect(() => {
    if (Array.isArray(props.mode) && props.mode.length > 0) {
      const thisMode = props.mode[0];
      for (let i = 0; i < thisMode.tools_mode_values.length; i++) {
        const mode = thisMode.tools_mode_values[i];
        if (mode.value === "height_following") {
          setCanHeightFollow(mode.can_be_activated);
          break;
        }
      }
    }
  }, [props.mode]);

  return (
    <SliderContainer
      data-cy={props["data-cy"]}
      disabled={props.forceDisabled || props.floating || !canHeightFollow}
    >
      <input
        disabled={props.forceDisabled || props.floating || !canHeightFollow}
        type="range"
        min="-1"
        max="1"
        step="1"
        value={props.targetHeight}
        onChange={props.onTargetChange}
        onTouchStart={props.onDragStart}
        onMouseDown={props.onDragStart}
        onTouchEnd={props.onDragEnd}
        onMouseLeave={props.onDragEnd}
        onMouseUp={props.onDragEnd}
      />
    </SliderContainer>
  );
};

export default ToolbarSlider;
