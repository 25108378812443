import { ATOMS, selectors } from "recoil/atoms";
import { Box, Chip, Divider } from "@mui/material";
import { FlagImage, LangContainer } from "./LangSelector.styled";
import { RecoilState, useRecoilState, useRecoilValue } from "recoil";
import { doc, setDoc } from "firebase/firestore";
import { useCallback, useState } from "react";

import { IFirebaseUser } from "types/firebase";
import { LANGUAGES } from "const";
import { fireDatabase } from "config/firebase";

type LangSelectorProps = {
  inDrawer?: boolean;
};

const LangSelector = ({ inDrawer = false }: LangSelectorProps) => {
  const [langWorking, setLangWorking] = useState(false);

  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );

  const [uiLang, setUiLang] = useRecoilState<string>(
    selectors[ATOMS.UI_LANG] as RecoilState<string>
  );

  const changeLang = useCallback(
    async (lang: string) => {
      // Locally change lang without persistence
      setUiLang(lang);
      if (fbUser) {
        setLangWorking(true);
        try {
          const userDoc = doc(fireDatabase, "users", fbUser.uid);
          await setDoc(userDoc, { lang }, { merge: true });
        } catch (error) {
          console.log("Unable to set user lang", error);
        } finally {
          setLangWorking(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fbUser]
  );

  return (
    <LangContainer direction="column" spacing={1} inDrawer={inDrawer}>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${inDrawer ? 3 : 5}, minmax(50px, auto))`}
        gap={1}
        px={inDrawer ? 1.5 : 0.5}
        my={1}
      >
        {Object.entries(LANGUAGES).map(([, langData]) => (
          <Chip
            data-cy={`lang-${langData.shortCode}-btn`}
            color="primary"
            key={langData.shortCode}
            variant={langData.shortCode === uiLang ? "filled" : "outlined"}
            disabled={langWorking}
            onClick={() => changeLang(langData.shortCode)}
            label={<FlagImage src={langData.flag} alt={langData.label} />}
          />
        ))}
      </Box>

      <Divider />
    </LangContainer>
  );
};

export default LangSelector;
