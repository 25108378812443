import { ToggleButtonGroup, styled } from "@mui/material";

export const ToolbarLinkGroup = styled(ToggleButtonGroup, {
  name: "ToolbarLinkGroup",
})(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`, // Has to be important otherwise the value is overwritten by default Mui component hover color
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    "&:not(.Mui-selected)": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}));
