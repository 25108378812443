import {
  CssBaseline,
  IconButton,
  Snackbar,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";
import InitHookContainer from "components/utils/InitHookContainer";
import ReloadIcon from "@mui/icons-material/Refresh";
import Shield from "utils/shield";
import SplashScreen from "./SplashScreen";
import { baseAppTheme } from "config/theme";
import { register } from "serviceWorkerRegistration";
import { useTranslation } from "react-i18next";

type AppScaffoldProps = {
  children: JSX.Element | JSX.Element[];
};

const AppScaffold = ({ children }: AppScaffoldProps) => {
  const { t } = useTranslation();
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const updateWorkerInterval = useRef<NodeJS.Timeout | null>(null);

  const appTheme = responsiveFontSizes(
    createTheme({
      ...baseAppTheme,
      palette: {
        ...baseAppTheme.palette,
        mode: "light",
        background: {
          paper: "#fff",
          default: "#fafafa",
        },
      },
    })
  );

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  const onSwUpdate = (registration: ServiceWorkerRegistration) => {
    console.log("[SW] onUpdate from worker");
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSwSuccess = (registration: ServiceWorkerRegistration) => {
    console.log("[SW] onSuccess from worker");
    updateWorkerInterval.current && clearInterval(updateWorkerInterval.current);
    updateWorkerInterval.current = setInterval(() => {
      console.log("[SW] interval update");
      registration
        .update()
        .then(() => {
          console.log("[SW] update successful");
        })
        .catch((err) => {
          console.error("[SW] update error", err);
        });
    }, 1000 * 60 * 1); // 1 minutes
  };

  useEffect(() => {
    console.log("[SW] register");
    register({ onUpdate: onSwUpdate, onSuccess: onSwSuccess });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={Shield}>
          <SplashScreen>
            <>{children}</>
          </SplashScreen>
          <Snackbar
            open={showReload}
            message={t("appUpdateAvailable")}
            onClick={reloadPage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={
              <IconButton onClick={reloadPage}>
                <ReloadIcon
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          />
          <div id="version" style={{ display: "none" }}>
            {process.env.REACT_APP_VERSION}
          </div>
          <InitHookContainer />
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppScaffold;
