import { Slide, SlideProps } from "@mui/material";

import React from "react";

const UpSlideTransition = React.forwardRef<unknown, SlideProps>(
  (props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
  }
);
UpSlideTransition.displayName = "UpSlideTransition";

export default UpSlideTransition;
