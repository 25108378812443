import { ATOMS, selectors } from "recoil/atoms";
import {
  RecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useEffect, useRef } from "react";

import { API_ORDERS } from "const";
import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { ISelectedRobot } from "types/userRobot";
import { socketDataConfig } from "hooks/robot/useRobotData";

const useRobotSocket = () => {
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);

  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );
  const setWSLatency = useSetRecoilState(selectors[ATOMS.WS_LATENCY]);

  const resetWSLatency = useResetRecoilState(selectors[ATOMS.WS_LATENCY]);

  const oldID = useRef<string | null>(null);
  const heartbeatHasAnswered = useRef(true);

  useEffect(() => {
    if (socketConnected) {
      console.log("trigger room leave/join", selectedRobot?.id);
      if (selectedRobot.id) {
        console.log("askJoin", selectedRobot.id, socketDataConfig);
        AutopilotSocketManager.getInstance().socketManager.emit(
          "room:join",
          selectedRobot.id,
          socketDataConfig
        );
        oldID.current = selectedRobot.id;
      } else {
        if (oldID.current) {
          console.log("leaveRoom", oldID.current);
          AutopilotSocketManager.getInstance().socketManager.emit(
            "room:leave",
            oldID.current
          );
          oldID.current = null;
        }
      }
    }
  }, [selectedRobot, socketConnected]);

  useEffect(() => {
    heartbeatHasAnswered.current = true;
    const flagRobotInterval = setInterval(() => {
      if (socketConnected === false) {
        resetWSLatency();
        return;
      }
      if (!selectedRobot.id) {
        resetWSLatency();
        return;
      }
      if (heartbeatHasAnswered.current === false) {
        return;
      }
      heartbeatHasAnswered.current = false;
      const start = new Date().getTime();
      AutopilotSocketManager.getInstance().socketManager.emit(
        API_ORDERS.EmergencyHeartbeat,
        () => {
          heartbeatHasAnswered.current = true;
          setWSLatency(new Date().getTime() - start);
        }
      );
    }, 200);

    return () => clearInterval(flagRobotInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, selectedRobot]);
};

export default useRobotSocket;
