import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilState } from "recoil";
import { useEffect, useRef } from "react";

import { VersionSocketManager } from "managers/VersionSocket";

const useVersionSocket = () => {
  const [socketConnected, setSocketConnected] = useRecoilState<boolean>(
    selectors[ATOMS.VERSION_WS_CONNECTED] as RecoilState<boolean>
  );
  const socketConnectedInternal = useRef(false);

  useEffect(() => {
    const tm = setInterval(() => {
      if (
        socketConnectedInternal.current !==
        VersionSocketManager.getInstance().isConnected
      ) {
        setSocketConnected(VersionSocketManager.getInstance().isConnected);
        socketConnectedInternal.current =
          VersionSocketManager.getInstance().isConnected;
      }
    }, 100);

    return () => {
      clearInterval(tm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    VersionSocketManager.getInstance().checkSocketState();
  }, [socketConnected]);
};

export default useVersionSocket;
