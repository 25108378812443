import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import {
  Timestamp,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { makeId, validNotEmptyString } from "utils/string";
import { useEffect, useState } from "react";

import { IFirebaseUser } from "types/firebase";
import { asyncForEach } from "utils/async";
import { fireDatabase } from "config/firebase";
import { isDefined } from "utils/object";
import zlib from "react-zlib-js";

/* Order sample
{ order: 'getCurrentLocale', arguments: [] },
{ order: 'getIp4Address', arguments: [] },
{ order: 'getIp6Address', arguments: [] },
{ order: 'getHostname', arguments: [] },
{ order: 'getHostname6', arguments: [] },
{ order: 'getMacAddress', arguments: [] },
{ order: 'getWifiSsid', arguments: [] },
{ order: 'getWifiSignalLevel', arguments: [] },
{ order: 'getSerialNumber', arguments: [] },
{ order: 'getDeviceId', arguments: [] },
{ order: 'getImei', arguments: [] },
{ order: 'getSimSerialNumber', arguments: [] },
{ order: 'getBatteryLevel', arguments: [] },
{ order: 'getScreenBrightness', arguments: [] },
{ order: 'getDisplayWidth', arguments: [] },
{ order: 'getDisplayHeight', arguments: [] },
{ order: 'getScreenOn', arguments: [] },
{ order: 'isPlugged', arguments: [] },
{ order: 'isKeyboardVisible', arguments: [] },
{ order: 'isWifiEnabled', arguments: [] },
{ order: 'isBluetoothEnabled', arguments: [] },
{ order: 'getFullyVersion', arguments: [] },
{ order: 'getFullyVersionCode', arguments: [] },
{ order: 'getWebviewVersion', arguments: [] },
{ order: 'getAndroidVersion', arguments: [] },
{ order: 'getAndroidSdk', arguments: [] },
{ order: 'getDeviceModel', arguments: [] },
{ order: 'getInternalStorageTotalSpace', arguments: [] },
{ order: 'getInternalStorageFreeSpace', arguments: [] },
{ order: 'vibrate', arguments: [] },
{ order: 'getStartUrl', arguments: [] },
{ order: 'setScreenBrightness', arguments: [85] },
{ order: 'showNotification', arguments: ['notification test', 'test body', '', false] },
{ order: 'showToast', arguments: ['toaster :D'] },
{ order: 'getScreenshotPngBase64', arguments: [] },
{ order: 'vibrate', arguments: [] },
{ order: 'getBooleanSetting', arguments: ['showActionBar'] },
{ order: 'setBooleanSetting', arguments: ['showActionBar', false] },
{ order: 'getStringSetting', arguments: ['actionBarTitle'] },
{ order: 'setStringSetting', arguments: ['actionBarTitle', 'Fully Kiosk Browser'] },
{ order: 'textToSpeech', arguments: ['un phrase'] }
*/

const executeFunctionByName = <T>(
  functionName: string,
  context: any,
  args: unknown[] = []
): T => {
  try {
    const namespaces = functionName.split(".");
    const func: string = namespaces.pop() as string;
    for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
    }
    return context[func].apply(context, args) as T;
  } catch (error) {
    throw new Error(`internal error ${error}`);
  }
};

/**
 *
 * @param order Order to execute
 * @param args args to pass to the order
 * @returns The result of the order
 * @throws Error if the Fully Kiosk Browser is not available
 */
export const fullyExecutor = <T>(order: string, args: unknown[] = []): T => {
  if (typeof FullyKiosk === "undefined") {
    console.log("Unsupported fully");
    throw new Error("FullyKiosk is not defined");
  }
  try {
    if (order === "disableWifi") {
      setTimeout(() => {
        executeFunctionByName("fully.enableWifi", window, args);
      }, 10000);
    }
    if (order === "getScreenshotPngBase64") {
      const screenshotResponse = executeFunctionByName<string>(
        `fully.${order}`,
        window,
        args
      );
      return zlib
        .deflateSync(Buffer.from(screenshotResponse))
        .toString("base64");
    }
    return executeFunctionByName<T>(`fully.${order}`, window, args);
  } catch (error) {
    // Rethrow the error
    throw error;
  }
};

const addResponse = async (
  firebaseUserID: string,
  id: string,
  deviceID: string | null,
  response: any
) => {
  if (!isDefined(deviceID) || !validNotEmptyString(deviceID)) {
    return;
  }
  if (!response) {
    response = "no-response";
  }
  try {
    await setDoc(
      doc(
        fireDatabase,
        `users/${firebaseUserID}/kiosk_order/${id}/response/${deviceID}`
      ),
      {
        date: serverTimestamp(),
        response,
      }
    );
  } catch {
    // Do nothing
  }
};

const flagAnswered = async (firebaseUserID: string, ids: string[] = []) => {
  try {
    let batch = writeBatch(fireDatabase);
    let processLength = 0;
    await asyncForEach(ids, async (id) => {
      processLength++;
      batch.update(
        doc(fireDatabase, `users/${firebaseUserID}/kiosk_order/${id}`),
        {
          answer: true,
        }
      );

      if (processLength > 499) {
        await batch.commit();
        batch = writeBatch(fireDatabase);
        processLength = 0;
      }
    });
    await batch.commit();
  } catch {
    // Do nothing
  }
};

const askIsValid = (date: Timestamp) => {
  return (
    Math.abs(new Date(date.seconds * 1000).getTime() - Date.now()) < 200000
  ); // Consider request has been done for less than 200 seconds
};

const useFullyKiosk = () => {
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );

  const [fullyKiosk, setFullyKiosk] = useState(null);
  if (typeof FullyKiosk !== "undefined" && !fullyKiosk) {
    // eslint-disable-next-line no-undef
    setFullyKiosk(fully);
  }

  useEffect(() => {
    // eslint-disable-next-line no-unreachable
    if (fbUser) {
      // Grab data to inject
      const snapshot = onSnapshot(
        query(
          collection(fireDatabase, `users/${fbUser.uid}/kiosk_order`),
          where("answer", "==", false),
          orderBy("date", "desc"),
          limit(10)
        ),
        async (snap) => {
          if (snap.size > 0) {
            await asyncForEach(snap.docChanges(), async (change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added": // Only react when data is added to the snapshot
                  await flagAnswered(fbUser.uid, [change.doc.id]);
                  try {
                    if (!fullyKiosk) {
                      await addResponse(
                        fbUser.uid,
                        change.doc.id,
                        makeId(5),
                        "FullyKiosk not available"
                      );
                    } else if (askIsValid(data.date)) {
                      await addResponse(
                        fbUser.uid,
                        change.doc.id,
                        fullyExecutor<string>("getDeviceId"),
                        JSON.stringify(
                          fullyExecutor(data.order, data.arguments)
                        )
                      );
                    }
                  } catch (error) {
                    console.log("Unable to execute fully order", error);
                  }
                  break;
                default:
                // Do nothing
              }
            });
          }
        }
      );
      // eslint-disable-next-line no-unreachable
      return () => snapshot();
    }
  }, [fullyKiosk, fbUser]);
};

export default useFullyKiosk;
