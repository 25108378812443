import { Box, styled } from "@mui/material";

export const ToolbarSliderContainer = styled(Box, {
  name: "ToolbarSliderContainer",
})(`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0;
  padding: 12px;
`);
