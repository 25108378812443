import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { IToolsConfig } from "types/tool";
import { TOOL_ID } from "const";

const evalEquippedTool = (robotToolsConfig: IToolsConfig, model: string) => {
  if (
    robotToolsConfig?.tool_settings_left?.tools &&
    Array.isArray(robotToolsConfig.tool_settings_left.tools) &&
    robotToolsConfig.tool_settings_left.tools.length > 0
  ) {
    for (const tool of robotToolsConfig.tool_settings_left.tools) {
      if (tool?.tool_id?.name === model) {
        return true;
      }
    }
  }
  if (
    robotToolsConfig?.tool_settings_right?.tools &&
    Array.isArray(robotToolsConfig.tool_settings_right.tools) &&
    robotToolsConfig.tool_settings_right.tools.length > 0
  ) {
    for (const tool of robotToolsConfig.tool_settings_right.tools) {
      if (tool?.tool_id?.name === model) {
        return true;
      }
    }
  }

  if (
    robotToolsConfig?.general_manager?.tools_id &&
    Array.isArray(robotToolsConfig.general_manager.tools_id) &&
    robotToolsConfig.general_manager.tools_id.length > 0
  ) {
    for (const tool of robotToolsConfig.general_manager.tools_id) {
      if (tool?.name === model) {
        return true;
      }
    }
  }

  return false;
};

const isOneOfConfig = (actualConfig: IToolsConfig, configIds: string[]) => {
  return configIds.includes(actualConfig.label.id);
};

const fourIntercepConfig = ["FOUR_INTERCEPS"];

const useToolsMetadata = () => {
  const robotToolsConfig = useRecoilValue<IToolsConfig>(
    selectors[ATOMS.TOOLS_CONFIG] as RecoilState<IToolsConfig>
  );

  const [isIntercepSelected, setIsIntercepSelected] = useState(
    evalEquippedTool(robotToolsConfig, TOOL_ID.INTERCEP)
  );
  const [isIntercepOutsideSelected, setIsIntercepOutsideSelected] = useState(
    evalEquippedTool(robotToolsConfig, TOOL_ID.INTERCEP_OUTSIDE)
  );
  const [isMowerSelected, setIsMowerSelected] = useState(
    evalEquippedTool(robotToolsConfig, TOOL_ID.MOWER)
  );
  const [isToolbarSelected, setIsToolbarSelected] = useState(
    evalEquippedTool(robotToolsConfig, TOOL_ID.TOOLBAR)
  );
  const [isFourIntercepSelected, setIsFourIntercepSelected] = useState(
    isOneOfConfig(robotToolsConfig, fourIntercepConfig)
  );

  useEffect(() => {
    if (robotToolsConfig) {
      setIsIntercepSelected(
        evalEquippedTool(robotToolsConfig, TOOL_ID.INTERCEP)
      );
      setIsIntercepOutsideSelected(
        evalEquippedTool(robotToolsConfig, TOOL_ID.INTERCEP_OUTSIDE)
      );
      setIsMowerSelected(evalEquippedTool(robotToolsConfig, TOOL_ID.MOWER));
      setIsToolbarSelected(evalEquippedTool(robotToolsConfig, TOOL_ID.TOOLBAR));
      setIsFourIntercepSelected(
        isOneOfConfig(robotToolsConfig, fourIntercepConfig)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotToolsConfig]);

  return {
    isIntercepSelected,
    isIntercepOutsideSelected,
    isMowerSelected,
    isToolbarSelected,
    isFourIntercepSelected,
  };
};

export default useToolsMetadata;
