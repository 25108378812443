import { IWorkConfigEnum, availableWorkConfigEntry } from "const";
import { useEffect, useState } from "react";

import { LiveDataEvent } from "managers/LiveDataManager";
import { secureOrder } from "utils/live";
import useLiveData from "hooks/live/useLiveData";

const liveFields: LiveDataEvent[] = [
  LiveDataEvent.ToolsStatusInterceptWorkStatusLeft,
  LiveDataEvent.ToolsStatusInterceptWorkStatusRight,
];

const useIntercepInsideLiveHandlingManager = (verbose = false) => {
  // Current Mode
  const {
    interceptWorkStatusLeft: leftInsideCurrent,
    interceptWorkStatusRight: rightInsideCurrent,
  } = useLiveData(liveFields, "useIntercepInsideLiveHandlingManager");

  // User requested mode
  const [leftInsideUserRequestMode, setLeftInsideUserRequestMode] =
    useState<availableWorkConfigEntry>(leftInsideCurrent);
  const [rightInsideUserRequestMode, setRightInsideUserRequestMode] =
    useState<availableWorkConfigEntry>(rightInsideCurrent);

  // requested mode for setMessage use
  const [leftInsideRequestToRobotMode, setLeftInsideRequestToRobotMode] =
    useState<availableWorkConfigEntry>(leftInsideCurrent);
  const [rightInsideRequestToRobotMode, setRightInsideRequestToRobotMode] =
    useState<availableWorkConfigEntry>(rightInsideCurrent);

  // Interface only working
  const [askInsideWorking, setAskInsideWorking] = useState(false);

  useEffect(() => {
    if (verbose) {
      console.log(
        `leftInside -> current: ${leftInsideCurrent} || asked: ${leftInsideUserRequestMode}`
      );
    }

    // Si demande d'un clean à gauche
    if (
      leftInsideUserRequestMode === IWorkConfigEnum.CLEAN &&
      leftInsideCurrent !== IWorkConfigEnum.CLEAN_END
    ) {
      if (verbose) {
        console.log(
          "leftInside -> User ask to go in Clean, rightInside is in",
          rightInsideCurrent
        );
      }
      // Si droite est en fin de repli
      if (rightInsideCurrent === IWorkConfigEnum.IDLE_END) {
        if (verbose) {
          console.log(
            "leftInside -> RightInside is in the good state, ask leftInside to clean"
          );
        }
        setAskInsideWorking(false);
        setLeftInsideRequestToRobotMode(IWorkConfigEnum.CLEAN);
        return;
      }

      // Sinon on le demande
      if (verbose) {
        console.log(
          "leftInside -> RightInside is not in good state, ask rightInside to go in Idle"
        );
      }
      setAskInsideWorking(true);
      setRightInsideUserRequestMode(IWorkConfigEnum.IDLE); // AutoSelect Idle for client
      setRightInsideRequestToRobotMode(IWorkConfigEnum.IDLE);
      return;
    }

    if (!askInsideWorking) {
      const securedOrder = secureOrder(leftInsideUserRequestMode);
      if (verbose) {
        console.log(
          "leftInside -> User is allowed to perform action since it's not cleaning",
          leftInsideUserRequestMode,
          securedOrder
        );
      }
      // Enforce the secure order
      if (leftInsideUserRequestMode !== securedOrder) {
        if (verbose) {
          console.log("leftInside -> Enforce the secure order", securedOrder);
        }
        setLeftInsideUserRequestMode(securedOrder);
      }
      setLeftInsideRequestToRobotMode(securedOrder);
      return;
    }

    if (verbose) {
      console.log("leftInside -> something is already working");
    }
  }, [
    leftInsideCurrent,
    rightInsideCurrent,
    leftInsideUserRequestMode,
    askInsideWorking,
    verbose,
  ]);

  useEffect(() => {
    if (verbose) {
      console.log(
        `rightInside -> current: ${rightInsideCurrent} || asked: ${rightInsideUserRequestMode}`
      );
    }

    // Si demande d'un clean à droite
    if (
      rightInsideUserRequestMode === IWorkConfigEnum.CLEAN &&
      rightInsideCurrent !== IWorkConfigEnum.CLEAN_END
    ) {
      if (verbose) {
        console.log(
          "rightInside -> User ask to go in Clean, leftInside is in",
          leftInsideCurrent
        );
      }
      // Si gauche est en fin de repli
      if (leftInsideCurrent === IWorkConfigEnum.IDLE_END) {
        // console.log("rightInside -> LeftInside is in good state, ask rightInside to clean");
        setAskInsideWorking(false);
        setRightInsideRequestToRobotMode(IWorkConfigEnum.CLEAN);
        return;
      }
      if (verbose) {
        console.log(
          "rightInside -> LeftInside is not in good state, ask leftInside to go in Idle"
        );
      }
      setAskInsideWorking(true);
      setLeftInsideUserRequestMode(IWorkConfigEnum.IDLE); // AutoSelect Idle for client
      setLeftInsideRequestToRobotMode(IWorkConfigEnum.IDLE);
      return;
    }
    if (!askInsideWorking) {
      const secured = secureOrder(rightInsideUserRequestMode);
      if (verbose) {
        console.log(
          "rightInside -> User is allowed to perform action since it's not cleaning",
          rightInsideUserRequestMode,
          secured
        );
      }
      // Enforce the secure order
      if (rightInsideUserRequestMode !== secured) {
        if (verbose) {
          console.log("rightInside -> Enforce the secure order", secured);
        }
        setRightInsideUserRequestMode(secured);
      }
      setRightInsideRequestToRobotMode(secured);
      return;
    }
    if (verbose) {
      console.log("rightInside -> something is already working");
    }
  }, [
    leftInsideCurrent,
    rightInsideCurrent,
    rightInsideUserRequestMode,
    askInsideWorking,
    verbose,
  ]);

  return {
    leftInsideCurrent,
    rightInsideCurrent,
    leftInsideUserRequestMode,
    setLeftInsideUserRequestMode,
    rightInsideUserRequestMode,
    setRightInsideUserRequestMode,
    leftInsideRequestToRobotMode,
    rightInsideRequestToRobotMode,
    askInsideWorking,
  };
};

export default useIntercepInsideLiveHandlingManager;
