import { ATOMS, selectors } from "recoil/atoms";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  IUpdateBakusVersion,
  IUpdateLowLevelVersion,
  UpdaterStatus,
} from "types/version";
import { RecoilState, useRecoilValue } from "recoil";

import UpdatingLowLevelVersionProgress from "./versions/UpdatingLowLevelVersionProgress";
import UpdatingVersionProgress from "./versions/UpdatingVersionProgress";
import { useTranslation } from "react-i18next";

const showStatus = [
  UpdaterStatus.UPDATING,
  UpdaterStatus.UPDATING_WAITING_RETRY,
];

const InstallUpdateDialog = () => {
  const { t } = useTranslation(["version"]);

  const robotVersion = useRecoilValue<IUpdateBakusVersion>(
    selectors[ATOMS.VERSION_ROBOT_DATA] as RecoilState<IUpdateBakusVersion>
  );
  const robotLowLevelVersion = useRecoilValue<IUpdateLowLevelVersion>(
    selectors[
      ATOMS.VERSION_ROBOT_LOW_LEVEL_DATA
    ] as RecoilState<IUpdateLowLevelVersion>
  );

  const isOpen =
    showStatus.includes(robotVersion.global.status) ||
    showStatus.includes(robotLowLevelVersion.global);

  const getContent = () => {
    if (showStatus.includes(robotVersion.global.status)) {
      return <UpdatingVersionProgress robotVersion={robotVersion} />;
    } else if (showStatus.includes(robotLowLevelVersion.global)) {
      return (
        <UpdatingLowLevelVersionProgress robotVersion={robotLowLevelVersion} />
      );
    }
    return null;
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle textAlign="center">{t("version:updating")}</DialogTitle>
      <DialogContent>{getContent()}</DialogContent>
    </Dialog>
  );
};

export default InstallUpdateDialog;
