import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemTextProps,
} from "@mui/material";
import { ListItemElement, ListItemElementAction } from "./ElementBar.styled";

import { isDefined } from "utils/object";

interface IElementBarProps {
  "data-cy"?: string;
  avatar?: boolean;
  avatarRounded?: boolean;
  dense?: boolean;
  disabled?: boolean;
  id?: string;
  leading?: JSX.Element[]; // lasts elements
  leadingFlex?: boolean;
  leadingInteractive?: boolean;
  minHeight?: number;
  noPadding?: boolean;
  onClick?: () => void;
  subline?: ListItemTextProps["secondary"];
  title?: ListItemTextProps["primary"];
  titleColor?: string;
  trailing?: JSX.Element; // first element
  style?: any;
}

const ElementBar = (props: IElementBarProps) => {
  let disabled = false;
  let avatar = false;
  let avatarRounded = false;
  let dense = false;
  let titleColor = "primary";

  let minHeight = 0;
  let noPadding = false;
  if (props.avatar !== null && props.avatar !== undefined) {
    avatar = props.avatar;
  }
  if (props.avatarRounded !== null && props.avatarRounded !== undefined) {
    avatarRounded = props.avatarRounded;
  }
  if (props.titleColor !== null && props.titleColor !== undefined) {
    titleColor = props.titleColor;
  }
  if (props.dense !== null && props.dense !== undefined) {
    dense = props.dense;
  }
  if (props.minHeight !== null && props.minHeight !== undefined) {
    minHeight = props.minHeight;
  }
  if (props.noPadding !== null && props.noPadding !== undefined) {
    noPadding = props.noPadding;
  }
  if (props.disabled !== null && props.disabled !== undefined) {
    disabled = props.disabled;
  }

  const content = (
    <ListItemText
      color={titleColor}
      primary={props.title}
      secondary={isDefined(props.subline) ? props.subline : null}
    />
  );

  let trailing = null;
  if (props.trailing !== null && props.trailing !== undefined) {
    if (avatar === true || avatarRounded === true) {
      // We are in the case where a rounded or a circled avatar is required
      trailing = (
        <ListItemAvatar>
          <Avatar variant={avatarRounded ? "rounded" : "circular"}>
            {props.trailing}
          </Avatar>
        </ListItemAvatar>
      );
    } else {
      trailing = (
        <ListItemAvatar sx={{ display: "flex" }}>
          {props.trailing}
        </ListItemAvatar>
      );
    }
  }
  let leading = null;
  if (
    props.leading !== null &&
    props.leading !== undefined &&
    Array.isArray(props.leading)
  ) {
    leading = (
      <ListItemElementAction
        leadingFlex={props.leadingFlex}
        leadingInteractive={props.leadingInteractive}
      >
        {props.leading.map((e) => e)}
      </ListItemElementAction>
    );
  }

  const style = {
    ...props.style,
    minHeight,
  };
  if (noPadding === true) {
    style.padding = 0;
  }

  return (
    <ListItemElement
      id={props.id}
      data-cy={props["data-cy"]}
      dense={dense}
      disabled={disabled}
      onClick={props.onClick}
      style={style}
      ContainerComponent="div"
      ContainerProps={{
        style: {
          position: "absolute",
          display: "block",
          bottom: 0,
          height: 60,
          width: "100%",
        },
      }}
    >
      {trailing}
      {content}
      {leading}
    </ListItemElement>
  );
};

export default ElementBar;
