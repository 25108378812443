import { ATOMS, selectors } from "recoil/atoms";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";
import { RecoilValue, useRecoilValue } from "recoil";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type IntercepCalibrationAskRemoveEmergencyStopStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
  continueText?: string;
  nextStep?: INTERCEP_CALIBRATION_STEP;
};

const IntercepCalibrationAskRemoveEmergencyStopStep = ({
  sendAction,
  continueText,
  nextStep,
}: IntercepCalibrationAskRemoveEmergencyStopStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  const estop = useRecoilValue<number>(
    selectors[ATOMS.ESTOP] as RecoilValue<number>
  );

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Typography textAlign="center">
          {t("intercep:calibrationStepActionRemoveStopEmergency")}
        </Typography>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          disabled={estop !== 0}
          onClick={() => {
            sendAction({
              type: "AU_OFF",
              step: nextStep,
            });
          }}
        >
          {continueText || t("common:continue")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationAskRemoveEmergencyStopStep;
