import { styled } from "@mui/material/styles";

export const SplashContainer = styled("div", {
  name: "SplashContainer",
})(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.palette.background.paper};
  position: absolute;
  inset: 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  z-index: 10000;
`
);
