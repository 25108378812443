import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilState } from "recoil";

import { ISelectedRobot } from "types/userRobot";
import { deepEqual } from "utils/object";
import { useCallback } from "react";

const useSelectRobot = () => {
  const [selectedRobot, setSelectedRobot] = useRecoilState<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );

  const selectRobot = useCallback(
    (newRobot: ISelectedRobot) => {
      // Check if new robot is the same
      if (!selectedRobot) {
        setSelectedRobot(newRobot);
        return;
      }
      if (!deepEqual(newRobot, selectedRobot)) {
        setSelectedRobot(newRobot);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRobot]
  );

  return {
    selectRobot,
  };
};

export default useSelectRobot;
