import { LatLngExpression } from "leaflet";
import Victor from "victor";

export const clamp = (num: number, min: number, max: number): number => {
  return num <= min ? min : num >= max ? max : num;
};

export const normalize = (value: number, max: number): number =>
  ((value - 0) * 100) / (max - 0);

export const percentageInRange = (
  value: number,
  min: number,
  max: number
): number => ((value - min) * 100) / (max - min);

export const copySign = (a: number, b: number): number => {
  return (a < 0 || 1 / a < 0) === (b < 0 || 1 / b < 0) ? a : -a;
};

export const quaternionToEuler = (q: {
  x: number;
  y: number;
  z: number;
  w: number;
}): {
  pitch: number;
  roll: number;
  yaw: number;
} => {
  const angles = {
    pitch: 0,
    roll: 0,
    yaw: 0,
  };
  const sinRCosP = 2 * (q.w * q.x + q.y * q.z);
  const cosRCosP = 1 - 2 * (q.x * q.x + q.y * q.y);

  angles.roll = Math.atan2(sinRCosP, cosRCosP);

  const sinP = 2 * (q.w * q.y - q.z * q.x);
  if (Math.abs(sinP) >= 1) {
    angles.pitch = copySign(Math.PI / 2, sinP);
  } else {
    angles.pitch = Math.asin(sinP);
  }

  const sinYCosP = 2 * (q.w * q.z + q.x * q.y);
  const cosYCosP = 1 - 2 * (q.y * q.y + q.z * q.z);
  angles.yaw = Math.atan2(sinYCosP, cosYCosP);

  return angles;
};

export const victorToArray = (vc: Victor): LatLngExpression => {
  return [vc.x, vc.y];
};

export const roundHalf = (num: number): number => {
  return Math.round(num * 2) / 2;
};

export const isClose = (
  a: number,
  b: number,
  epsilon: number = 0.000001
): boolean => {
  return Math.abs(a - b) < epsilon;
};
