import { Link, LinkOff } from "@mui/icons-material";

import { ToggleButton } from "@mui/material";
import { ToolbarLinkGroup } from "./ToolbarGroup.styled";

interface IToolbarGroupProps {
  combined: boolean;
  setCombined: (arg0: boolean) => void;
}

const ToolbarGroup = (props: IToolbarGroupProps) => {
  return (
    <ToolbarLinkGroup orientation="vertical" value={props.combined} exclusive>
      <ToggleButton onClick={() => props.setCombined(true)} value={true}>
        <Link />
      </ToggleButton>
      <ToggleButton onClick={() => props.setCombined(false)} value={false}>
        <LinkOff />
      </ToggleButton>
    </ToolbarLinkGroup>
  );
};

export default ToolbarGroup;
