import { Typography, styled } from "@mui/material";

export const LatencyTypography = styled(Typography, {
  name: "LatencyTypography",
  shouldForwardProp: (prop) => prop !== "forceWhite" && prop !== "wsLatency",
})<{
  forceWhite?: boolean;
  wsLatency: number;
}>(
  ({ forceWhite, wsLatency }) => `
  ${
    wsLatency <= 100
      ? forceWhite
        ? `color: #ffffff;`
        : `color: #373a36;`
      : `color: #ff0000;`
  }
`
);
