import { ATOMS, selectors } from "recoil/atoms";
import { ButtonBar, CloseButton, PaneIcon } from "./LivePaneManager.styled";
import { RecoilState, useRecoilState } from "recoil";

import { Close } from "@mui/icons-material";
import IntercepIcon from "resources/icon/IntercepIcon";
import { LIVE_PANE_CONTENT } from "const";
import { LiveButtonsContainer } from "./LiveManager.styled";
import Mower from "resources/icon/mower";
import Steering from "resources/icon/steering";
import ToolLift from "resources/icon/toolLift";
import useCapabilities from "hooks/robot/useCapabilities";
import { useTheme } from "@mui/material";
import useToolsMetadata from "hooks/tools/useToolsMetadata";

interface ILivePaneManagerProps {
  onClose: () => void;
}

const LivePaneManager = ({ onClose }: ILivePaneManagerProps) => {
  const theme = useTheme();

  const [liveControlPane, setLiveControlPane] =
    useRecoilState<LIVE_PANE_CONTENT>(
      selectors[ATOMS.UI_LIVE_PANE_CONTENT] as RecoilState<LIVE_PANE_CONTENT>
    );

  const [canUseMowerInLive] = useCapabilities("live_version", 2);

  const { isIntercepSelected, isMowerSelected } = useToolsMetadata();

  return (
    <LiveButtonsContainer>
      <CloseButton
        data-cy="close-live-control-page-btn"
        onClick={() => onClose()}
        size="large"
      >
        <Close />
      </CloseButton>
      <ButtonBar variant="outlined" disableElevation>
        <PaneIcon
          data-cy="move-pane-btn"
          active={liveControlPane === LIVE_PANE_CONTENT.move}
          onClick={() => setLiveControlPane(LIVE_PANE_CONTENT.move)}
          size="large"
        >
          <Steering
            fontSize={24}
            frontColor={
              liveControlPane === LIVE_PANE_CONTENT.move
                ? "white"
                : theme.palette.primary.main
            }
          />
        </PaneIcon>
        <PaneIcon
          data-cy="toolbars-pane-btn"
          active={liveControlPane === LIVE_PANE_CONTENT.toolbar}
          onClick={() => setLiveControlPane(LIVE_PANE_CONTENT.toolbar)}
          size="large"
        >
          <ToolLift
            fontSize={24}
            color={
              liveControlPane === LIVE_PANE_CONTENT.toolbar
                ? "white"
                : theme.palette.primary.main
            }
          />
        </PaneIcon>
        {isIntercepSelected ? (
          <PaneIcon
            data-cy="interceps-pane-btn"
            active={liveControlPane === LIVE_PANE_CONTENT.tools}
            style={{ padding: "5px 8px" }}
            onClick={() => setLiveControlPane(LIVE_PANE_CONTENT.tools)}
            size="large"
          >
            <IntercepIcon
              fontColor={
                liveControlPane === LIVE_PANE_CONTENT.tools
                  ? "white"
                  : theme.palette.primary.main
              }
            />
          </PaneIcon>
        ) : null}
        {isMowerSelected && canUseMowerInLive ? (
          <PaneIcon
            active={liveControlPane === LIVE_PANE_CONTENT.mower}
            style={{ padding: "5px 8px", width: 52 }}
            onClick={() => setLiveControlPane(LIVE_PANE_CONTENT.mower)}
            size="large"
          >
            <Mower
              frontColor={
                liveControlPane === LIVE_PANE_CONTENT.mower
                  ? "white"
                  : theme.palette.primary.main
              }
            />
          </PaneIcon>
        ) : null}
      </ButtonBar>
    </LiveButtonsContainer>
  );
};

export default LivePaneManager;
