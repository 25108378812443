import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageDivider,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { ROUTES } from "constants/routes";
import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type IntercepCalibrationIdleStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
};

const IntercepCalibrationIdleStep = ({
  sendAction,
}: IntercepCalibrationIdleStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  const navigate = useNavigate();

  const navigateToHomePage = useCallback(
    () => navigate(ROUTES.ROOT, { replace: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Typography textAlign="center">
          {t("intercep:calibrationStepTitleIdle")}
        </Typography>

        <Stack spacing={2}>
          <Alert variant="outlined" severity="info">
            <AlertTitle>{t("common:info")}</AlertTitle>
            <Typography fontWeight="regular" fontSize={14}>
              {t("intercep:calibrationProcessInfo")}
            </Typography>
          </Alert>

          <IntercepCalibrationPageDivider />

          <Alert variant="outlined" severity="warning">
            <AlertTitle>{t("common:warning")}</AlertTitle>
            <Typography fontWeight="regular" fontSize={14} mb={1}>
              {t("intercep:calibrationProcessWarningPart1")}
            </Typography>

            <Typography fontWeight="regular" fontSize={14}>
              {t("intercep:calibrationProcessWarningPart2")}
            </Typography>
          </Alert>
        </Stack>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <Stack direction="row" spacing={1} alignItems="center">
          <IntercepCalibrationActionButton
            variant="contained"
            color="warning"
            onClick={navigateToHomePage}
            sx={{ flex: 2 }}
          >
            {t("common:cancel")}
          </IntercepCalibrationActionButton>
          <IntercepCalibrationActionButton
            variant="contained"
            onClick={() => {
              sendAction({
                type: "CLIENT_START",
                step: INTERCEP_CALIBRATION_STEP.STARTED,
              });
            }}
            sx={{ flex: 3 }}
          >
            {t("common:start")}
          </IntercepCalibrationActionButton>
        </Stack>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationIdleStep;
