import { ATOMS, selectors } from "recoil/atoms";
import { MowerSliderContainer, Title } from "./LiveMowerManager.styled";
import { RecoilState, useRecoilValue } from "recoil";
import { TOOL_ID, TOOL_SIDE, availableWorkConfigEntry } from "const";
import { useEffect, useState } from "react";

import CustomDivider from "components/corrector/CustomDivider";
import { Grid } from "@mui/material";
import { IPartialPublishedStatus } from "types/live";
import { IToolsModesAvailable } from "types/tool";
import { IWorkMowerCorrections } from "types/work";
import MowerSlider from "components/orders/Mower/MowerModeSelector";
import { emptyArray } from "utils/array";
import useLiveData from "hooks/live/useLiveData";
import useMowerLiveHandlingManager from "hooks/robot/useMowerLiveHandlingManager";
import { useTranslation } from "react-i18next";

interface ILiveMowerManagerProps {
  setPublishedContent: (arg: IPartialPublishedStatus) => void;
  disabled: boolean;
}

const LiveMowerManager = ({
  disabled,
  setPublishedContent,
}: ILiveMowerManagerProps) => {
  const { t } = useTranslation(["live"]);

  const { initialized } = useLiveData(emptyArray(), "LiveMowerManager");

  const {
    leftCurrentSpeed,
    leftCurrentMode,
    rightCurrentSpeed,
    rightCurrentMode,

    leftRequestToRobotMode,
    rightRequestToRobotMode,
    leftRequestToRobotSpeed,
    rightRequestToRobotSpeed,

    setLeftRequestToRobotMode,
    setRightRequestToRobotMode,
    setLeftRequestToRobotSpeed,
    setRightRequestToRobotSpeed,
  } = useMowerLiveHandlingManager();

  const modesAvailable = useRecoilValue<IToolsModesAvailable>(
    selectors[ATOMS.TOOLS_MODES_AVAILABLE] as RecoilState<IToolsModesAvailable>
  );

  const robotMowerCorrections = useRecoilValue<IWorkMowerCorrections>(
    selectors[
      ATOMS.TOOLS_MOWERS_CORRECTIONS
    ] as RecoilState<IWorkMowerCorrections>
  );

  const [minSpeedCorrection, setMinSpeedCorrection] = useState(0);
  const [maxSpeedCorrection, setMaxSpeedCorrection] = useState(0);
  const [stepSpeedCorrection] = useState(1);
  const [speedCorrectionUnit, setSpeedCorrectionUnit] = useState("rad/s");

  useEffect(() => {
    setMinSpeedCorrection(-robotMowerCorrections.max);
    setMaxSpeedCorrection(robotMowerCorrections.max);
    if (
      robotMowerCorrections.unit !== null &&
      robotMowerCorrections.unit !== undefined &&
      robotMowerCorrections.unit.length !== 0
    ) {
      setSpeedCorrectionUnit(robotMowerCorrections.unit);
    }
  }, [robotMowerCorrections]);

  useEffect(() => {
    if (initialized === true) {
      setPublishedContent({
        tool: {
          left: {
            mowerSpeed: leftRequestToRobotSpeed,
            mowerWorkConfig: leftRequestToRobotMode,
          },
          right: {
            mowerSpeed: rightRequestToRobotSpeed,
            mowerWorkConfig: rightRequestToRobotMode,
          },
        },
      });
    }
  }, [
    setPublishedContent,
    initialized,
    leftRequestToRobotMode,
    rightRequestToRobotMode,
    leftRequestToRobotSpeed,
    rightRequestToRobotSpeed,
  ]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <CustomDivider size="small" transparent />
          <Title variant="h6" align="center">
            {t("live:mowers")}
          </Title>
        </Grid>
        <Grid item xs={6}>
          <MowerSliderContainer>
            <MowerSlider
              forceDisabled={disabled}
              onSpeedChange={setLeftRequestToRobotSpeed}
              currentSpeed={leftCurrentSpeed}
              targetSpeed={leftRequestToRobotSpeed}
              minSpeedCorrection={minSpeedCorrection}
              maxSpeedCorrection={maxSpeedCorrection}
              stepSpeedCorrection={stepSpeedCorrection}
              currentMode={leftCurrentMode as availableWorkConfigEntry}
              side={TOOL_SIDE.left}
              requestedMode={leftRequestToRobotMode}
              setMode={setLeftRequestToRobotMode}
              speedCorrectionUnit={speedCorrectionUnit}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.MOWER &&
                  mode?.tool_id?.side === TOOL_SIDE.left
              )}
            />
          </MowerSliderContainer>
        </Grid>
        <Grid item xs={6}>
          <MowerSliderContainer>
            <MowerSlider
              forceDisabled={disabled}
              onSpeedChange={setRightRequestToRobotSpeed}
              currentSpeed={rightCurrentSpeed}
              targetSpeed={rightRequestToRobotSpeed}
              minSpeedCorrection={minSpeedCorrection}
              maxSpeedCorrection={maxSpeedCorrection}
              stepSpeedCorrection={stepSpeedCorrection}
              currentMode={rightCurrentMode as availableWorkConfigEntry}
              side={TOOL_SIDE.right}
              requestedMode={rightRequestToRobotMode}
              setMode={setRightRequestToRobotMode}
              speedCorrectionUnit={speedCorrectionUnit}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.MOWER &&
                  mode?.tool_id?.side === TOOL_SIDE.right
              )}
            />
          </MowerSliderContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default LiveMowerManager;
