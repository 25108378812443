import { AppBar, IconButton, Typography, styled } from "@mui/material";
import { borderRadius, borderSize } from "utils/sharedStyles";

import { drawerWidth } from "config/theme";

export const HomeRoot = styled("div", {
  name: "HomeRoot",
})(`
  display: flex;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`);

export const MainContainer = styled("main", {
  name: "MainContainer",
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})<{
  drawerOpen: boolean;
}>(
  ({ theme, drawerOpen }) => `
  flex-grow: 1;
  transition: ${theme.transitions.create(["margin"], {
    duration: drawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
    easing: drawerOpen
      ? theme.transitions.easing.easeOut
      : theme.transitions.easing.sharp,
  })};

  margin-left: ${drawerOpen ? drawerWidth : 0}px;
`
);

export const MainAppBar = styled(AppBar, {
  name: "MainAppBar",
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})<{
  drawerOpen: boolean;
}>(
  ({ theme, drawerOpen }) => `
  transition: ${theme.transitions.create(["margin", "width"], {
    duration: drawerOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
    easing: drawerOpen
      ? theme.transitions.easing.easeOut
      : theme.transitions.easing.sharp,
  })};
  border-radius: ${borderRadius}px;
  position: absolute;
  bottom: ${borderSize}px;
  left: ${borderSize}px;
  right: ${borderSize}px;
  top: auto;
  width: unset;
  height: 64px;
  z-index: 1151;
  background-color: ${theme.palette.primary.main};

  margin-left: ${drawerOpen ? drawerWidth : 0}px;
`
);

export const TextContainer = styled("div", {
  name: "HomeTextContainer",
})(
  ({ theme }) => `
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  background-color: ${theme.palette.background.default};
`
);

export const AppVersionTypography = styled(Typography, {
  name: "HomeAppVersionTypography",
})(
  ({ theme }) => `
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${theme.palette.primary.main}
`
);

export const CenteredTypography = styled(Typography, {
  name: "HomeCenteredTypography",
})(`
  text-align: center;
`);

export const HideIcon = styled(IconButton, {
  name: "HideIcon",
  shouldForwardProp: (prop) => prop !== "hide",
})<{ hide: boolean }>(
  ({ hide }) => `
  ${hide ? "display: none;" : ""}
`
);

export const RobotListContainer = styled("div", {
  name: "HomeRobotListContainer",
})(
  ({ theme }) => `
  max-height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  min-width: 345px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  background-color: ${theme.palette.background.default};
`
);
