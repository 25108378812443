import { ATOMS, defaultBakusUpdateVersion, selectors } from "recoil/atoms";
import {
  IBakusVersion,
  IBakusWizardVersion,
  IUpdateBakusVersion,
  UpdaterStatus,
} from "types/version";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  checkShouldUpdateAutopilotVersion,
  checkShouldUpdateBakusVersion,
  checkShouldUpdateGuiVersion,
} from "utils/version";
import { useEffect, useRef } from "react";

import { BOTTOM_PANE_CONTENT } from "const";
import { IAutopilotVersionStatus } from "types/capabilities";
import { VersionSocketManager } from "managers/VersionSocket";
import usePaneManager from "hooks/ui/usePaneManager";

const useUpdateBakusWatcher = () => {
  const { showPane } = usePaneManager();

  const socketConnected = useRecoilValue<boolean>(
    selectors[ATOMS.VERSION_WS_CONNECTED] as RecoilState<boolean>
  );
  const setShouldUpdateNotification = useSetRecoilState<boolean>(
    selectors[
      ATOMS.VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION
    ] as RecoilState<boolean>
  );

  const setBakusVersion = useSetRecoilState<IBakusVersion | null>(
    selectors[
      ATOMS.ROBOT_ACTUAL_BAKUS_VERSION
    ] as RecoilState<IBakusVersion | null>
  );
  const autopilotVersion = useRecoilValue<IAutopilotVersionStatus | null>(
    selectors[
      ATOMS.AUTOPILOT_VERSION
    ] as RecoilState<IAutopilotVersionStatus | null>
  );
  const bakusWizardVersion = useRecoilValue<IBakusWizardVersion | null>(
    selectors[
      ATOMS.BAKUS_WIZARD_VERSION
    ] as RecoilState<IBakusWizardVersion | null>
  );
  const [robotVersion, setRobotVersion] = useRecoilState<IUpdateBakusVersion>(
    selectors[ATOMS.VERSION_ROBOT_DATA] as RecoilState<IUpdateBakusVersion>
  );

  const versionMessage = useRef<IUpdateBakusVersion>(robotVersion);

  const isDevEnv = useRecoilValue<boolean>(
    selectors[ATOMS.IS_DEV_ENV] as RecoilState<boolean>
  );

  const onVersionCallback = (data: IUpdateBakusVersion) => {
    setRobotVersion(data);
    versionMessage.current = data;

    if (data.global.status === UpdaterStatus.UPDATING) {
      // Enforce during update
      showPane(BOTTOM_PANE_CONTENT.version_update);
    }
  };

  const onBakusVersionCallback = (data: IBakusVersion) => {
    setBakusVersion(data);
  };

  const resetUpdateState = () => {
    if (versionMessage.current.global.status === UpdaterStatus.UPDATING) {
      // During the update phase when Autopilot is restarting the socket is totally overloaded and will timeout. We want to avoid the close behavior.
      return;
    }

    setShouldUpdateNotification(false);

    setRobotVersion(defaultBakusUpdateVersion);
    versionMessage.current = defaultBakusUpdateVersion;
  };

  useEffect(() => {
    if (socketConnected) {
      const manager = VersionSocketManager.getSocket();

      manager.emit("update_manager_version", onVersionCallback);
      manager.emit("bakus_version", onBakusVersionCallback);

      manager.on("update_manager_version", onVersionCallback);
      manager.on("bakus_version", onBakusVersionCallback);
    }

    return () => {
      const manager = VersionSocketManager.getSocket();

      manager.off("update_manager_version", onVersionCallback);
      manager.off("bakus_version", onBakusVersionCallback);

      resetUpdateState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected]);

  useEffect(() => {
    if (!isDevEnv) {
      // Avoid this check when mode is dev
      if (autopilotVersion == null || bakusWizardVersion == null) {
        console.log(
          "Abort version checking since we have no version data for autopilot or bakus wizard",
          {
            autopilot: autopilotVersion,
            bakusWizard: bakusWizardVersion,
          }
        );
        setShouldUpdateNotification(false);
        return;
      }
    }

    if (robotVersion.versions.length === 0) {
      console.log("Abort version checking since we have no robot version data");
      setShouldUpdateNotification(false);
      return;
    }

    // Checking gui
    if (checkShouldUpdateGuiVersion(robotVersion) === true) {
      // ! the bakus can be up to date but the gui can be outdated
      setShouldUpdateNotification(true);
      return;
    }

    if (robotVersion.global.status === UpdaterStatus.UP_TO_DATE) {
      // ? Enforce the notification to disappear
      setShouldUpdateNotification(false);
      return;
    }

    // Checking autopilot
    if (
      checkShouldUpdateAutopilotVersion(robotVersion, autopilotVersion) === true
    ) {
      setShouldUpdateNotification(true);
      return;
    }

    // Checking bakus wizard
    if (
      checkShouldUpdateBakusVersion(robotVersion, bakusWizardVersion) === true
    ) {
      setShouldUpdateNotification(true);
      return;
    }

    setShouldUpdateNotification(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autopilotVersion, bakusWizardVersion, robotVersion, socketConnected]);
};

export default useUpdateBakusWatcher;
