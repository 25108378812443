import { ATOMS, selectors } from "recoil/atoms";
import {
  IPartialPublishedStatus,
  IPublishedStatus,
  IPublishedToolStatus,
  MoveMode,
} from "types/live";
import { isDefinedOrNull, isNull } from "utils/object";
import { useEffect, useRef } from "react";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { useRecoilValue } from "recoil";

export const defaultLivePublishedToolsData: IPublishedToolStatus = {
  left: {
    floating: false,
    intercepWorkConfig: null,
    intercepOutsideWorkConfig: null,
    neutral: true,
    targetHeight: undefined,
    mowerSpeed: 0,
    mowerWorkConfig: null,
  },
  right: {
    floating: false,
    intercepWorkConfig: null,
    intercepOutsideWorkConfig: null,
    neutral: true,
    targetHeight: undefined,
    mowerSpeed: 0,
    mowerWorkConfig: null,
  },
};

export const defaultLivePublishedData: IPublishedStatus = {
  move: {
    angularAngle: 0,
    lateralAngle: 0,
    mode: MoveMode.Neutral,
    speedNorm: 0,
  },
  tool: defaultLivePublishedToolsData,
};

const useLiveSocketControl = () => {
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);

  const publishLoop = useRef<NodeJS.Timeout | null>(null);
  const publishedData = useRef<IPublishedStatus>(
    JSON.parse(JSON.stringify(defaultLivePublishedData)) // Ugly trick to remove some references
  );
  const publishReady = useRef(true);

  useEffect(() => {
    publishedData.current = JSON.parse(
      JSON.stringify(defaultLivePublishedData)
    );
  }, []);

  const setPublishedContent = (updateData: IPartialPublishedStatus) => {
    if (updateData.move) {
      if (isDefinedOrNull(updateData.move.angularAngle)) {
        publishedData.current.move.angularAngle = updateData.move
          .angularAngle as number;
      }
      if (isDefinedOrNull(updateData.move.lateralAngle)) {
        publishedData.current.move.lateralAngle = updateData.move
          .lateralAngle as number;
      }
      if (isDefinedOrNull(updateData.move.mode)) {
        publishedData.current.move.mode = updateData.move.mode as MoveMode;
      }
      if (isDefinedOrNull(updateData.move.speedNorm)) {
        publishedData.current.move.speedNorm = updateData.move
          .speedNorm as number;
      }
    }
    if (updateData.tool) {
      if (updateData.tool.left) {
        if (isDefinedOrNull(updateData.tool.left.floating)) {
          publishedData.current.tool.left.floating = updateData.tool.left
            .floating as boolean;
        }
        if (isDefinedOrNull(updateData.tool.left.intercepWorkConfig)) {
          publishedData.current.tool.left.intercepWorkConfig = updateData.tool
            .left.intercepWorkConfig as string;
        }
        if (isDefinedOrNull(updateData.tool.left.intercepOutsideWorkConfig)) {
          publishedData.current.tool.left.intercepOutsideWorkConfig = updateData
            .tool.left.intercepOutsideWorkConfig as string;
        }
        if (isDefinedOrNull(updateData.tool.left.neutral)) {
          publishedData.current.tool.left.neutral = updateData.tool.left
            .neutral as boolean;
        }
        if (!isNull(updateData.tool.left.targetHeight)) {
          publishedData.current.tool.left.targetHeight = updateData.tool.left
            .targetHeight as number;
        }
        if (isDefinedOrNull(updateData.tool.left.mowerSpeed)) {
          publishedData.current.tool.left.mowerSpeed = updateData.tool.left
            .mowerSpeed as number;
        }
        if (isDefinedOrNull(updateData.tool.left.mowerWorkConfig)) {
          publishedData.current.tool.left.mowerWorkConfig = updateData.tool.left
            .mowerWorkConfig as string;
        }
      }
      if (updateData.tool.right) {
        if (isDefinedOrNull(updateData.tool.right.floating)) {
          publishedData.current.tool.right.floating = updateData.tool.right
            .floating as boolean;
        }
        if (isDefinedOrNull(updateData.tool.right.intercepWorkConfig)) {
          publishedData.current.tool.right.intercepWorkConfig = updateData.tool
            .right.intercepWorkConfig as string;
        }
        if (isDefinedOrNull(updateData.tool.right.intercepOutsideWorkConfig)) {
          publishedData.current.tool.right.intercepOutsideWorkConfig =
            updateData.tool.right.intercepOutsideWorkConfig as string;
        }
        if (isDefinedOrNull(updateData.tool.right.neutral)) {
          publishedData.current.tool.right.neutral = updateData.tool.right
            .neutral as boolean;
        }
        if (!isNull(updateData.tool.right.targetHeight)) {
          publishedData.current.tool.right.targetHeight = updateData.tool.right
            .targetHeight as number;
        }
        if (isDefinedOrNull(updateData.tool.right.mowerSpeed)) {
          publishedData.current.tool.right.mowerSpeed = updateData.tool.right
            .mowerSpeed as number;
        }
        if (isDefinedOrNull(updateData.tool.right.mowerWorkConfig)) {
          publishedData.current.tool.right.mowerWorkConfig = updateData.tool
            .right.mowerWorkConfig as string;
        }
      }
    }
  };

  useEffect(() => {
    if (socketConnected === true) {
      if (publishLoop.current) {
        clearInterval(publishLoop.current);
        publishLoop.current = null;
      }

      publishLoop.current = setInterval(() => {
        if (publishReady.current === false) {
          return;
        }
        publishReady.current = false;
        AutopilotSocketManager.getInstance().socketManager.emit(
          "live:publish",
          publishedData.current,
          () => {
            publishReady.current = true;
          }
        );
      }, 15);

      return () => {
        if (publishLoop.current) {
          clearInterval(publishLoop.current);
          publishLoop.current = null;
        }
      };
    }
  }, [socketConnected]);

  return {
    setPublishedContent,
  };
};

export default useLiveSocketControl;
