import { Box, Typography, styled } from "@mui/material";

export const MowerSliderContainer = styled(Box, {
  name: "MowerSliderContainer",
})(`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0;
  padding: 12px;
`);

export const Title = styled(Typography, {
  name: "MowerSliderTitle",
})(`
  text-transform: uppercase;
`);
