import {
  AngularSlider,
  CenterIndicator,
  LateralSlider,
  LiveMoveManagerContainer,
  SliderBox,
  SpeedSlider,
  TurtleBox,
} from "./LiveMoveManager.styled";
import { IPartialPublishedStatus, MoveMode } from "types/live";
import { useEffect, useMemo, useState } from "react";

import { LiveDataEvent } from "managers/LiveDataManager";
import { Stack } from "@mui/material";
import TurtleSwitch from "components/controls/TurtleSwitch";
import useLiveData from "hooks/live/useLiveData";

const GAP_FILTER = 32;

interface ISlideParam {
  DEAD_ZONE: number;
  EPSILON: number;
  MAX: number;
  POW_SCALE: number;
}

const SPEED: ISlideParam = {
  DEAD_ZONE: 0.1,
  EPSILON: 0.01,
  MAX: 1.4,
  POW_SCALE: 1.2,
};

const ANGULAR: ISlideParam = {
  DEAD_ZONE: 0.1,
  EPSILON: 0.01,
  MAX: 0.907571, // ~ 52 deg
  POW_SCALE: 1.2,
};

const LATERAL: ISlideParam = {
  DEAD_ZONE: 0.1,
  EPSILON: 0.01,
  MAX: 0.907571, // ~ 52 deg
  POW_SCALE: 1.2,
};

const sliderToVal = (x: number, sliderSize: number, param: ISlideParam) => {
  const xAbs = Math.abs(x / sliderSize);
  const coeff = Math.max((xAbs - param.DEAD_ZONE) / (1 - param.DEAD_ZONE), 0);
  const value = Math.pow(coeff, param.POW_SCALE) * param.MAX;
  return -Math.sign(x) * value;
};

const valToSlider = (val: number, sliderSize: number, param: ISlideParam) => {
  const valAbs = Math.abs(val);
  const coeff = Math.exp(Math.log(valAbs / param.MAX) / param.POW_SCALE);
  const xAbsTemp = coeff * (1 - param.DEAD_ZONE);
  const xAbs = xAbsTemp > param.EPSILON ? xAbsTemp + param.DEAD_ZONE : 0;
  return -Math.sign(val) * xAbs * sliderSize;
};

interface ILiveMoveManagerProps {
  setPublishedContent: (arg: IPartialPublishedStatus) => void;
  disabled: boolean;
}

const liveFields: LiveDataEvent[] = [
  LiveDataEvent.MoveAngular,
  LiveDataEvent.MoveLateral,
];

const LiveMoveManager = ({
  disabled,
  setPublishedContent,
}: ILiveMoveManagerProps) => {
  const {
    initialData: initialState,
    initialized,
    angularAngle: actualAngularAngle,
    lateralAngle: actualLateralAngle,
  } = useLiveData(liveFields, "LiveMoveManager");

  const [enabledAngle, setEnabledAngle] = useState(true);

  const [speedSliderValue, setSpeedSliderValue] = useState(0);
  const [lateralSliderValue, setLateralSliderValue] = useState(
    sliderToVal(actualLateralAngle, 120, LATERAL)
  );
  const [angularSliderValue, setAngularSliderValue] = useState(
    sliderToVal(actualAngularAngle, 120, ANGULAR)
  );

  const [onInit, setOnInit] = useState(true);

  const speedNorm = useMemo(
    () => sliderToVal(speedSliderValue, 120, SPEED),
    [speedSliderValue]
  );

  const angular = useMemo(
    () => sliderToVal(angularSliderValue, 120, ANGULAR),
    [angularSliderValue]
  );

  const lateral = useMemo(
    () => sliderToVal(lateralSliderValue, 120, LATERAL),
    [lateralSliderValue]
  );

  useEffect(() => {
    if (initialized === true) {
      if (onInit === true) {
        handleRemoteChangeAngularAngle(actualAngularAngle);
        handleRemoteChangeLateralAngle(actualLateralAngle);
      } else {
        setPublishedContent({
          move: {
            angularAngle: angular,
            lateralAngle: lateral,
            mode: MoveMode.remote,
            speedNorm: speedNorm,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    speedNorm,
    onInit,
    lateral,
    angular,
    initialized,
    actualAngularAngle,
    actualLateralAngle,
  ]);

  // // ? Should this be removed
  // useEffect(() => {
  //   if (initialState) {
  //     setAngularSliderValue(
  //       valToSlider(initialState.move.angular_angle, 120, ANGULAR)
  //     );
  //     setLateralSliderValue(
  //       valToSlider(initialState.move.lateral_angle, 120, LATERAL)
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [initialState]);

  /**
   * SPEED
   */
  const handleChangeSpeed = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      Math.abs(speedSliderValue - parseFloat(event.target.value)) < GAP_FILTER
    )
      setSpeedSliderValue(parseFloat(event.target.value));
    if (onInit === true) setOnInit(false);
  };

  /**
   * ANGULAR
   */
  const handleChangeAngularAngle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFloatValue = parseFloat(event.target.value);
    if (
      Math.abs(angularSliderValue - newFloatValue) < GAP_FILTER ||
      Math.abs(initialState?.move?.angular_angle || 0) > ANGULAR.MAX
    ) {
      setAngularSliderValue(newFloatValue);
      if (onInit === true) setOnInit(false);
    }
  };

  const handleResetAngularAngle = () => {
    const angularAbs = Math.abs(angular);
    if (angularAbs !== 0 && angularAbs < ANGULAR.DEAD_ZONE) {
      console.log(">>> Debug - reset ANGULAR", angularAbs, "->", 0);
      setAngularSliderValue(0);
    }
  };

  const handleRemoteChangeAngularAngle = (remoteValue: number) => {
    setAngularSliderValue(valToSlider(remoteValue, 120, ANGULAR));
  };

  /**
   * LATERAL
   */
  const handleChangeLateralAngle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFloatValue = parseFloat(event.target.value);
    if (
      Math.abs(lateralSliderValue - newFloatValue) < GAP_FILTER ||
      Math.abs(initialState?.move?.lateral_angle || 0) > LATERAL.MAX
    ) {
      setLateralSliderValue(newFloatValue);
      if (onInit === true) setOnInit(false);
    }
  };

  const handleResetLateralAngle = () => {
    const lateralAbs = Math.abs(lateral);
    if (lateralAbs !== 0 && lateralAbs < LATERAL.DEAD_ZONE) {
      console.log(">>> Debug - reset LATERAL", lateralAbs, "->", 0);
      setLateralSliderValue(0);
    }
  };

  const handleRemoteChangeLateralAngle = (remoteValue: number) => {
    setLateralSliderValue(valToSlider(remoteValue, 120, ANGULAR));
  };

  return (
    <LiveMoveManagerContainer>
      <Stack direction="row" justifyContent="space-between">
        <SliderBox
          onContextMenu={(e) => e.preventDefault()}
          paddingTop={1.5}
          paddingLeft={1.5}
        >
          <SpeedSlider disabled={disabled}>
            <input
              data-cy="speed-slider"
              disabled={disabled}
              type="range"
              min="-120"
              max="120"
              step="any"
              value={speedSliderValue}
              onChange={handleChangeSpeed}
              onTouchEnd={() => setSpeedSliderValue(0)}
              onMouseUp={() => setSpeedSliderValue(0)}
              onDragEnd={() => setSpeedSliderValue(0)}
            />
          </SpeedSlider>
        </SliderBox>

        <TurtleBox>
          <TurtleSwitch disabled={disabled} setEnabledAngle={setEnabledAngle} />
        </TurtleBox>
      </Stack>

      <Stack direction="column" justifyContent="center" flex={2} spacing={1.5}>
        <SliderBox>
          <LateralSlider
            onContextMenu={(e) => e.preventDefault()}
            disabled={disabled || !enabledAngle}
          >
            <input
              data-cy="lateral-wheels-slider"
              disabled={disabled || !enabledAngle}
              type="range"
              min="-120"
              max="120"
              step="any"
              value={lateralSliderValue}
              onChange={handleChangeLateralAngle}
              onTouchEnd={handleResetLateralAngle}
            />
            <CenterIndicator disabled={disabled} />
          </LateralSlider>
        </SliderBox>
        <SliderBox>
          <AngularSlider
            onContextMenu={(e) => e.preventDefault()}
            disabled={disabled || !enabledAngle}
          >
            <input
              data-cy="angular-wheels-slider"
              disabled={disabled || !enabledAngle}
              type="range"
              min="-120"
              max="120"
              step="any"
              value={angularSliderValue}
              onChange={handleChangeAngularAngle}
              onTouchEnd={handleResetAngularAngle}
              onMouseUp={handleResetAngularAngle}
              onDragEnd={handleResetAngularAngle}
            />
            <CenterIndicator disabled={disabled} />
          </AngularSlider>
        </SliderBox>
      </Stack>
    </LiveMoveManagerContainer>
  );
};

export default LiveMoveManager;
