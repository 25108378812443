import { ATOMS, selectors } from "recoil/atoms";
import { Alert, AlertTitle, Typography } from "@mui/material";
import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";
import { RecoilValue, useRecoilValue } from "recoil";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { useTranslation } from "react-i18next";

type IntercepCalibrationStartStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
};

const IntercepCalibrationStartStep = ({
  sendAction,
}: IntercepCalibrationStartStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  const estop = useRecoilValue<number>(
    selectors[ATOMS.ESTOP] as RecoilValue<number>
  );

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        {estop !== 0 ? (
          <Typography textAlign="center">
            {t("intercep:calibrationStepActionRemoveStopEmergency")}
          </Typography>
        ) : (
          <Alert variant="outlined" severity="warning">
            <AlertTitle>{t("common:warning")}</AlertTitle>
            <Typography fontWeight="regular" fontSize={14}>
              {t("intercep:calibrationWarningIntercepDeployment")}
            </Typography>
          </Alert>
        )}
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          disabled={estop !== 0}
          onClick={() => {
            sendAction({
              type: "AU_OFF",
              step: INTERCEP_CALIBRATION_STEP.INITIALIZING,
            });
          }}
        >
          {t("intercep:calibrationStepActionDeployInterceps")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationStartStep;
