import { API_URL, VERSION_PORT } from "const";
import { Socket, io } from "socket.io-client";

export class VersionSocketManager {
  private static instance: VersionSocketManager;

  private socketManager: Socket;

  public isOpen = false;
  public isConnected = false;

  public get socketId(): string {
    return this.socketManager.id;
  }

  constructor() {
    this.socketManager = io(`https://${API_URL}:${VERSION_PORT}`, {
      randomizationFactor: 0,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 500,
      reconnectionDelayMax: 550,
      timeout: 20000,
      transports: ["websocket"],
    });

    this.socketManager.io.on("reconnect_attempt", () => {
      this.socketManager.io.opts.transports = ["polling", "websocket"];
    });

    this.socketManager.io.on("open", () => {
      console.log(">>> Debug - socket manager open");
      this.isOpen = true;
    });
    this.socketManager.io.on("close", () => {
      console.log(">>> Debug - socket manager close");
      this.isOpen = false;
    });

    this.attachEvent();
  }

  public static getSocket(): Socket {
    if (!VersionSocketManager.instance) {
      VersionSocketManager.instance = new VersionSocketManager();
    }
    return VersionSocketManager.instance.socketManager;
  }

  public static getInstance(): VersionSocketManager {
    if (!VersionSocketManager.instance) {
      VersionSocketManager.instance = new VersionSocketManager();
    }
    return VersionSocketManager.instance;
  }

  public disconnect = () => {
    this.socketManager.disconnect();
  };

  public connect = () => {
    this.socketManager.connect();
  };

  private attachEvent = () => {
    console.log(">>> re-attach event");

    this.socketManager.on("connect", () => {
      console.log(">>> Debug - socket connected");
      this.isConnected = true;
    });
    this.socketManager.on("disconnect", () => {
      console.log(">>> Debug - socket disconnected");
      this.isConnected = false;
    });
    this.socketManager.on("error", () => {
      console.log(">>> Debug - socket error");
    });
  };

  public checkSocketState = (): void => {
    console.log(">>> Debug - version socket manager check state");

    if (!this.socketManager.connected) {
      this.refreshSocket();
    }
  };

  private refreshSocket = (): void => {
    console.log(">>> Debug - disconnect socket");
    this.socketManager.disconnect();

    console.log(">>> Enforce socket reconnection");
    this.socketManager.connect();
  };
}
