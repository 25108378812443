import "config/firebase";
import "i18n/i18n";

import { fireDatabase, fireFunction } from "config/firebase";

import AnyDialogContainer from "components/Global/AnyDialogContainer";
import AppScaffold from "components/Global/AppScaffold";
import { RecoilRoot } from "recoil";
import Routes from "Routes";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";

if (
  process.env.NODE_ENV !== "production" ||
  process.env.REACT_APP_DEV_BUILD === "true"
) {
  const hostname = !process.env.REACT_APP_CI
    ? window.location.hostname // "localhost"
    : "backend";

  // Note that the Firebase Web SDK must connect to the WebChannel port
  connectFirestoreEmulator(fireDatabase, hostname, 8090);

  connectFunctionsEmulator(fireFunction, hostname, 5090);

  // connectAuthEmulator(fireAuth, `http://${hostname}:6090`);
}

const App = () => {
  return (
    <RecoilRoot>
      <AppScaffold>
        <AnyDialogContainer />
        {/* This is the only dialog container, should always be on top */}
        <Routes />
      </AppScaffold>
    </RecoilRoot>
  );
};

export default App;
