import {
  Box,
  Button,
  IconButton,
  Stack,
  StackProps,
  TextField,
  TextFieldProps,
  Typography,
  styled,
} from "@mui/material";

import VitibotLogo from "resources/logo/logo_carre.png";

export const SignInContainer = styled(
  (props: StackProps) => <Stack direction="column" spacing={4} {...props} />,
  { name: "SignInContainer" }
)(({ theme }) => ({
  maxWidth: 400,
  height: "100%",
  margin: "0 auto",
  alignItems: "center",
  padding: theme.spacing(1, 2),
}));

export const LangSelectContainer = styled(Box, { name: "LangSelectContainer" })(
  {
    position: "absolute",
    top: 16,
    right: 16,
  }
);

export const Logo = () => (
  <img src={VitibotLogo} alt="Vitibot logo" width={200} />
);

export const SignInTitle = styled(
  (props) => <Typography component="h1" variant="h5" {...props} />,
  { name: "SignInTitle" }
)(() => ({ fontSize: "1.3rem" }));

export const SignInContentWrapper = styled(Box, {
  name: "SignInContentWrapper",
})(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const SignInButton = styled(Button, {
  name: "SignInButton",
})(() => ({
  height: 48,
}));

export const SignInForm = styled("form", {
  name: "SignInForm",
})(() => ({
  width: "100%",
}));

export const SignInFormInput = styled(
  (props: TextFieldProps) => <TextField margin="none" {...props} />,
  { name: "SignInFormInput" }
)(() => ({
  margin: 0,
}));

export const SnackbarClose = styled(IconButton, {
  name: "SnackbarClose",
})(({ theme }) => ({
  padding: theme.spacing(1),
}));
