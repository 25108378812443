import { ATOMS, selectors } from "recoil/atoms";
import { LangButton, LangMenu, LangMenuItem } from "./LangSelect.styled";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  capitalize,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";

import { FlagImage } from "./controls/Drawer/LangSelector.styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LANGUAGES } from "const";
import { useTranslation } from "react-i18next";

const LangSelect = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [uiLang, setUiLang] = useRecoilState<string>(
    selectors[ATOMS.UI_LANG] as RecoilState<string>
  );

  const changeLang = (lang: string) => {
    // Locally change lang without persistence
    setUiLang(lang);
    handleClose();
  };

  const currentLangData = Object.values(LANGUAGES).find(
    (lang) => lang.shortCode === uiLang
  );

  return (
    <div>
      <LangButton
        id="change-lang-btn"
        data-cy="change-lang-btn"
        variant="contained"
        color="inherit"
        size="large"
        aria-controls={open ? "lang-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={
          <FlagImage src={currentLangData?.flag} alt="user-current-lang-flag" />
        }
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        <Typography fontWeight="inherit" minWidth={22}>
          {i18n.language}
        </Typography>
      </LangButton>
      <LangMenu
        id="change-lang-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {Object.entries(LANGUAGES).map(([, langData]) => (
          <LangMenuItem
            data-cy={`lang-${langData.shortCode}-btn`}
            onClick={() => changeLang(langData.shortCode)}
          >
            <ListItemIcon>
              <FlagImage src={langData.flag} alt={langData.label} />
            </ListItemIcon>
            <ListItemText>
              <Typography
                fontWeight={langData.shortCode === uiLang ? "bold" : "regular"}
              >
                {capitalize(langData.label)}
              </Typography>
            </ListItemText>
          </LangMenuItem>
        ))}
      </LangMenu>
    </div>
  );
};

export default LangSelect;
