import { Button, styled } from "@mui/material";

export const ModeSelector = styled(Button, {
  name: "ModeSelector",
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled: boolean }>(({ isDisabled }) => {
  if (isDisabled) {
    return `
      border: 1px solid #0000001f !important;
      color: #0000008c !important;
      background-color: #00000042 !important;
    `;
  }

  return "";
});
