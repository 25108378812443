import { ATOMS, selectors } from "recoil/atoms";
import { ButtonGroup, Grid, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { RecoilState, useSetRecoilState } from "recoil";
import {
  TOOL_SIDE,
  availableIntercepMode,
  availableWorkConfigEntry,
} from "const";
import { useEffect, useState } from "react";

import { IRobotStatusCode } from "types/statusCode";
import { ISnackbarContent } from "types/snackbar";
import { IToolModesAvailable } from "types/tool";
import { ModeSelector } from "./IntercepModeSelector.styled";
import { isModeEquals } from "utils/live";
import { useTranslation } from "react-i18next";

interface IIntercepModeSelectorProps {
  currentMode: availableWorkConfigEntry;
  forceDisabled: boolean;
  side: string;
  mode: IToolModesAvailable[];
  setMode: React.Dispatch<React.SetStateAction<availableWorkConfigEntry>>;
  requestedMode: string;
  "data-cy"?: string;
}

const IntercepModeSelector = (props: IIntercepModeSelectorProps) => {
  const { t } = useTranslation(["live", "intercep"]);

  const [availableIntercepInteraction, setAvailableIntercepInteraction] =
    useState<string[]>([]);
  const [supportedMode, setSupportedMode] = useState<
    availableWorkConfigEntry[]
  >([]);
  const [disableReason, setDisableReason] = useState<
    Map<string, IRobotStatusCode>
  >(new Map());

  const setSnackbar = useSetRecoilState<Partial<ISnackbarContent>>(
    selectors[ATOMS.SNACKBAR] as RecoilState<Partial<ISnackbarContent>>
  );

  useEffect(() => {
    if (Array.isArray(props.mode) && props.mode.length > 0) {
      const thisMode = props.mode[0];
      const availableInteractions: string[] = [];
      const sm: availableWorkConfigEntry[] = [];
      const dr = new Map<string, IRobotStatusCode>();
      for (let i = 0; i < thisMode.tools_mode_values.length; i++) {
        const mode = thisMode.tools_mode_values[i];
        sm.push(mode.value as availableWorkConfigEntry);
        if (mode.can_be_activated === true) {
          availableInteractions.push(mode.value);
        } else {
          dr.set(mode.value, mode.reason);
        }
      }
      setAvailableIntercepInteraction(availableInteractions);
      setSupportedMode(sm);
      setDisableReason(dr);
    }
  }, [props.mode]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ position: "relative" }}
      >
        {props.side === TOOL_SIDE.right ? (
          <KeyboardArrowRight
            color="error"
            style={{ position: "absolute", left: -8 }}
          />
        ) : null}
        <Typography variant="overline" align="center" noWrap>
          {availableIntercepMode[props.currentMode]
            ? t(`intercep:${availableIntercepMode[props.currentMode]}` as const)
            : t(`intercep:${availableIntercepMode.Stop}` as const)}
        </Typography>
        {props.side === TOOL_SIDE.left ? (
          <KeyboardArrowLeft
            color="error"
            style={{ position: "absolute", right: -8 }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ height: 145 }}
        data-cy={props["data-cy"]}
      >
        <ButtonGroup
          variant="outlined"
          orientation="vertical"
          disableElevation
          fullWidth
        >
          {supportedMode.map((mode) => {
            if (availableIntercepMode[mode]) {
              const reason = disableReason.get(mode);
              let visibleReason = "";
              if (reason) {
                if (reason.message.includes("low")) {
                  visibleReason = t("live:toolbarsTooLow");
                } else if (reason.message.includes("high")) {
                  visibleReason = t("live:toolbarsTooHigh");
                } else {
                  visibleReason = reason.message;
                }
              }
              const isDisabled =
                props.forceDisabled ||
                !availableIntercepInteraction.includes(mode);

              return (
                <ModeSelector
                  key={mode}
                  data-cy={isDisabled ? "disabled" : "enabled"}
                  color="primary"
                  variant={
                    isModeEquals(
                      props.requestedMode as availableWorkConfigEntry,
                      mode
                    )
                      ? "contained"
                      : "outlined"
                  }
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (isDisabled) {
                      setSnackbar({
                        content: visibleReason,
                        severity: "warning",
                        duration: 2000,
                        position: "top-center",
                      });
                    } else {
                      props.setMode(mode);
                    }
                  }}
                >
                  <Typography variant="button" noWrap color="inherit">
                    {t(`intercep:${availableIntercepMode[mode]}` as const)}
                  </Typography>
                </ModeSelector>
              );
            }
            return null;
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default IntercepModeSelector;
