import { ATOMS, selectors } from "recoil/atoms";
import {
  BottomAppBar,
  BottomToolbar,
  Footer,
  FooterContent,
  LatencyContainer,
  LiveControlPageContainer,
  LiveControlPageWrapper,
  StopButton,
} from "./LiveControlPage.styled";
import { LIVE_PANE_CONTENT, ROLE_DEV_ONLY } from "const";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useMemo, useState } from "react";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { DevSpan } from "styled/Dev";
import { ICurrentEmergency } from "types/emergency";
import { IGuiConfig } from "types/guiConfig";
import LiveManager from "components/orders/Live/LiveManager";
import RobotWSLatency from "components/dev/RobotWSLatency";
import { Stop } from "@mui/icons-material";
import useGetStatusByCode from "hooks/statusCodes/useGetStatusByCode";
import useHasAccess from "hooks/user/useHasAccess";
import useHttpApi from "hooks/network/useHttpApi";
import { useNavigate } from "react-router-dom";
import useParcelCheck from "hooks/map/useParcelCheck";
import usePhoneInfo from "hooks/user/usePhoneInfo";
import usePrevious from "hooks/utils/usePrevious";
import useStopOrder from "hooks/robot/useStopOrder";
import { useTranslation } from "react-i18next";

const LiveControlPage = () => {
  const { t } = useTranslation(["live", "alert"]);

  const [stopOrder] = useStopOrder();
  const { doParcelCheck } = useParcelCheck();
  const { httpRobotManager } = useHttpApi();
  const navigate = useNavigate();

  const remoteConfig = useRecoilValue<IGuiConfig>(
    selectors[ATOMS.USERS_CONFIG] as RecoilState<IGuiConfig>
  );
  const statusCodes = useRecoilValue(selectors[ATOMS.STATUS_CODE]);
  const socketConnected = useRecoilValue<boolean>(
    selectors[ATOMS.WS_CONNECTED] as RecoilState<boolean>
  );
  const currentEmergency = useRecoilValue<ICurrentEmergency>(
    selectors[ATOMS.CURRENT_EMERGENCY] as RecoilState<ICurrentEmergency>
  );
  const setLiveControlPane = useSetRecoilState<LIVE_PANE_CONTENT>(
    selectors[ATOMS.UI_LIVE_PANE_CONTENT] as RecoilState<LIVE_PANE_CONTENT>
  );
  const setSnackBar = useSetRecoilState(selectors[ATOMS.SNACKBAR]);

  const isDev = useHasAccess("all", ROLE_DEV_ONLY);

  const prevController = usePrevious(currentEmergency?.user?.name ?? "");

  const [voluntarilyClose, setVoluntarilyClose] = useState(false);

  const getStatus = useGetStatusByCode();
  const { batteryLevel } = usePhoneInfo();

  useEffect(() => {
    return () => {
      setLiveControlPane(LIVE_PANE_CONTENT.move);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!socketConnected) {
      navigate(-1);
    }
  }, [navigate, socketConnected]);

  // Phone low battery detection
  useEffect(() => {
    const isLocked =
      batteryLevel <= remoteConfig.requiredBatteryLevelForManuelControl;
    if (isLocked) {
      setSnackBar({
        content: t("live:unableToTakeControlBecauseOfBatteryLevel", {
          batteryLevel: remoteConfig.requiredBatteryLevelForManuelControl,
        }),
        duration: 6000,
        severity: "warning",
      });
      // Ask to release the control
      httpRobotManager.releaseControl();
      navigate(-1);
      setLiveControlPane(LIVE_PANE_CONTENT.move);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteConfig, batteryLevel, t]);

  // Loose control detector
  useMemo(() => {
    if (
      prevController === AutopilotSocketManager.getInstance().socketId &&
      currentEmergency.user?.name !==
        AutopilotSocketManager.getInstance().socketId &&
      voluntarilyClose === false
    ) {
      const status = getStatus(currentEmergency.reason?.code);
      let errorMessage = status.message || "controlLoose";
      if (status.resolution) errorMessage += ` ${status.resolution}`;

      if (
        isDev &&
        currentEmergency.reason?.code &&
        currentEmergency.reason?.message
      ) {
        const devErrorMessage = (
          <>
            {errorMessage}
            <br />
            <DevSpan>
              {currentEmergency.reason.code} : {currentEmergency.reason.message}
            </DevSpan>
          </>
        );
        setSnackBar({
          content: devErrorMessage,
          duration: 6000,
          severity: "warning",
        });
      } else {
        setSnackBar({
          content: errorMessage,
          duration: 6000,
          severity: "warning",
        });
      }
      navigate(-1);
      setLiveControlPane(LIVE_PANE_CONTENT.move);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentEmergency,
    prevController,
    isDev,
    voluntarilyClose,
    statusCodes,
    t,
  ]);

  const handleClose = async () => {
    setVoluntarilyClose(true);
    doParcelCheck();
    if (socketConnected === true) {
      const response = await httpRobotManager.releaseControl();
      if (
        response.status &&
        (response.status.code === "i3008" || response.status.code === "i3002")
      ) {
        navigate(-1);
        setLiveControlPane(LIVE_PANE_CONTENT.move);
      } else {
        // Do not navigate
      }
    } else {
      navigate(-1);
      setLiveControlPane(LIVE_PANE_CONTENT.move);
    }
  };

  return (
    <LiveControlPageWrapper>
      <LiveControlPageContainer>
        <LatencyContainer>
          <RobotWSLatency />
        </LatencyContainer>

        <LiveManager onClose={handleClose} />

        <Footer>
          <FooterContent>
            <BottomAppBar position="fixed" color="primary">
              <BottomToolbar disableGutters>
                <StopButton
                  id="stop-order-btn"
                  onClick={() => {
                    stopOrder();
                    handleClose();
                  }}
                  focusRipple
                >
                  <Stop fontSize="large" />
                </StopButton>
              </BottomToolbar>
            </BottomAppBar>
          </FooterContent>
        </Footer>
      </LiveControlPageContainer>
    </LiveControlPageWrapper>
  );
};

export default LiveControlPage;
