import { Slide, SlideProps } from "@mui/material";

import React from "react";

const LeftSlideTransition = React.forwardRef<unknown, SlideProps>(
  (props, ref) => {
    return <Slide direction="left" {...props} ref={ref} />;
  }
);
LeftSlideTransition.displayName = "LeftSlideTransition";

export default LeftSlideTransition;
