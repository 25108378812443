import { API_PORT, API_URL } from "const";
import { ATOMS, selectors } from "recoil/atoms";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  UserCredential,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useCallback, useEffect } from "react";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { IFirebaseUser } from "types/firebase";
import { IUserIdentity } from "types/user";
import { fireAuth } from "config/firebase";
import ky from "ky";
import { storeUtils } from "recoil/atomUtils";
import { validNotEmptyString } from "utils/string";

const endpoint = `https://${API_URL}:${API_PORT}`;

/**
 * Sign-in to Firebase
 */
const signInFirebase = async ({ username, password }: IUserIdentity) => {
  try {
    const res = await signInWithEmailAndPassword(fireAuth, username, password);
    console.log(">>> signInFirebase()", true);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Log into the robot using HTTP to establish and connect to the socket
 */
const logInRobot = async ({
  username,
  password,
  socketToken,
  setSocketToken,
}: IUserIdentity & {
  socketToken: string | null;
  setSocketToken: (token: string) => void;
}) => {
  try {
    /**
     * Connect to the robot (HTTP)
     */
    const res = await ky.post(`${endpoint}/gui/login`, {
      json: { username, password },
      throwHttpErrors: true, // We want to catch errors
    });

    /**
     * Set token
     */
    if (res.status === 200) {
      const data: { token: string } = await res.json();
      if (!socketToken) setSocketToken(data.token);
    }

    console.log(">>> logInRobot():", true);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Manages the authentication logic and handles
 */
const useAuth = () => {
  /**
   * Api socket hook
   */

  const socketConnected = useRecoilValue<boolean>(
    selectors[ATOMS.WS_CONNECTED] as RecoilState<boolean>
  );

  /**
   * Recoil Value
   */
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const isUserOnline = useRecoilValue<boolean>(
    selectors[ATOMS.IS_USER_ONLINE] as RecoilState<boolean>
  );

  /**
   * Recoil set State
   */
  const setSignOutDialogOpen = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_SIGN_OUT_DIALOG_OPEN] as RecoilState<boolean>
  );
  // const popSnackbar = useSetRecoilState(selectors[ATOMS.SNACKBAR]);

  /**
   * Recoil State
   */
  const [hasUserLoggedOut, setHasUserLoggedOut] = useRecoilState<boolean>(
    selectors[ATOMS.HAS_USER_LOGGED_OUT] as RecoilState<boolean>
  );
  const [socketToken, setSocketToken] = useRecoilState<string | null>(
    selectors[ATOMS.UI_USER_TOKEN] as RecoilState<string | null>
  );
  const [userIdentity, setUserIdentity] = useRecoilState<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );

  /**
   * Recoil reset
   */
  const resetSelectedRobot = useResetRecoilState(
    selectors[ATOMS.SELECTED_ROBOT]
  );
  const resetUserCredentials = useResetRecoilState(
    selectors[ATOMS.UI_USER_CREDENTIALS]
  );
  const resetUserToken = useResetRecoilState(selectors[ATOMS.UI_USER_TOKEN]);
  const resetWsRobotId = useResetRecoilState(selectors[ATOMS.WS_ROBOT_ID]);
  const resetWsRobotDiscovery = useResetRecoilState(
    selectors[ATOMS.WS_ROBOT_DISCOVERY]
  );
  const resetRobotAvailable = useResetRecoilState(
    selectors[ATOMS.ROBOTS_AVAILABLE]
  );

  /**
   * UseEffect loop
   * Executes every 10s to check if there is a userIdentity without a socketToken
   * If it does, try to re-login to the robot
   */
  useEffect(() => {
    console.log(">>> init authLoopCheck");

    const interval = setInterval(() => {
      // console.log("Debug - authLoopCheck");
      if (
        !hasUserLoggedOut &&
        !validNotEmptyString(socketToken) &&
        userIdentity
      ) {
        console.log(
          "Debug - authLoopCheck: found userIdentity without a valid socketToken"
        );
        logInRobot({
          username: userIdentity.username,
          password: userIdentity.password,
          socketToken,
          setSocketToken,
        }).catch(() => {
          console.log(">>> authLoopCheck: Unable to login user to robot");

          // popSnackbar(
          //   createSnackBarContent({
          //     content: err.message,
          //     duration: 6000,
          //     open: true,
          //     severity: "error",
          //     position: "top-center",
          //   })
          // );
        });
        return;
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketToken, socketConnected]);

  /**
   * Main useEffect
   * Manages the auth logic
   */
  useEffect(() => {
    console.log(">>> useAuth() -> socket", { socketConnected });

    /**
     * If there is a valid userIdentity...
     */
    if (userIdentity && userIdentity.username && userIdentity.password) {
      const { username, password } = userIdentity;
      /**
       * If there is a userIdentity and the user requested to logout
       * (userIdentity + hasUserLoggedOut)
       */
      if (hasUserLoggedOut) {
        console.log("Debug - userIdentity should be reset");
        // resetUserCredentials();
        setUserIdentity(null);
        return;
      }

      /**
       * If there is a userIdentity and the user did not request to logout...
       */
      if (!hasUserLoggedOut) {
        /**
         * The user did not request to logout but there is no socket token
         * (userIdentity + !hasUserLoggedOut + !socketToken)
         */
        if (!validNotEmptyString(socketToken)) {
          console.log("Debug - should have a socketToken");
          logInRobot({
            username,
            password,
            socketToken,
            setSocketToken,
          }).catch(() => {
            console.log(
              ">>> useAuth without secretToken: Unable to login user to robot"
            );
            // popSnackbar(
            //   createSnackBarContent({
            //     content: err.message,
            //     duration: 6000,
            //     open: true,
            //     severity: "error",
            //     position: "top-center",
            //   })
            // );
          });
          return;
        }

        /**
         * The user did not request to logout and there is a socket token...
         */
        if (validNotEmptyString(socketToken)) {
          /**
           * There is a socket token but the socket is disconnected
           * (userIdentity + !hasUserLoggedOut + socketToken + !socketConnected)
           */
          if (!socketConnected) {
            console.log("Debug - should have an established socket connection");
            AutopilotSocketManager.getInstance().resetUsedToken({
              withRefresh: true,
              token: socketToken,
            });
            return;
          }

          /**
           * There is a socket token and internet access but no Firebase user
           * (userIdentity + !hasUserLoggedOut + socketToken + isUserOnline + !fbUser)
           */
          if (isUserOnline && !fbUser) {
            console.log("Debug - should have a Firebase user");
            signInFirebase({
              username,
              password,
            }).catch(() => {
              console.log(
                ">>> useAuth without firebase connection: Unable to login user to firebase"
              );
              // popSnackbar(
              //   createSnackBarContent({
              //     content: err.message,
              //     duration: 6000,
              //     open: true,
              //     severity: "error",
              //     position: "top-center",
              //   })
              // );
            });
            return;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserLoggedOut, userIdentity, socketConnected, fbUser, isUserOnline]);

  /**
   * Global sign-in function
   */
  const signIn = useCallback(
    async ({ username, password }: IUserIdentity, callback: VoidFunction) => {
      try {
        await Promise.any([
          logInRobot({
            username,
            password,
            socketToken,
            setSocketToken,
          }) as Promise<Response>,
          signInFirebase({
            username,
            password,
          }) as Promise<Response | UserCredential>,
        ]);

        /**
         * At least one connection has been established
         */
        setHasUserLoggedOut(false);
        setUserIdentity({ username, password });
        callback();
      } catch (aggregateError: any) {
        /**
         * Both promises failed to login the user
         */
        return Promise.reject(aggregateError.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketToken]
  );

  /**
   * Global sign-out function
   */
  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(fireAuth);
    } catch (error) {
      console.log("Unable to logout", error);
    }

    if (socketConnected) {
      AutopilotSocketManager.getInstance().disconnect();
    }
    AutopilotSocketManager.getInstance().resetSocketQueryParameters();

    setHasUserLoggedOut(true);

    AutopilotSocketManager.getInstance().resetUsedToken();
    resetUserToken();
    resetUserCredentials();

    resetSelectedRobot();
    resetRobotAvailable();
    resetWsRobotId();
    resetWsRobotDiscovery();

    setSignOutDialogOpen(false);

    storeUtils.clearStorage();

    AutopilotSocketManager.getInstance().connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser, socketConnected, userIdentity]);

  /**
   * Firebase reset email
   */
  const resetEmail = useCallback(async (email: string) => {
    return await sendPasswordResetEmail(fireAuth, email);
  }, []);

  return { signIn, signOut, resetEmail };
};

export default useAuth;
