import { Alert, Stack, Typography } from "@mui/material";
import {
  IUpdateBakusVersion,
  UpdateStatus,
  UpdaterStatus,
} from "types/version";

import { DevStack } from "styled/Dev";
import { LinearProgressWithLabel } from "components/utils/Progress";
import { ROLE_DEV_ONLY } from "const";
import { clamp } from "utils/number";
import useHasAccess from "hooks/user/useHasAccess";
import { useTranslation } from "react-i18next";

type TUpdatingVersionProgressProps = {
  robotVersion: IUpdateBakusVersion;
};

const UpdatingVersionProgress = ({
  robotVersion,
}: TUpdatingVersionProgressProps) => {
  const { t } = useTranslation(["version"]);

  const isDev = useHasAccess("all", ROLE_DEV_ONLY);

  return (
    <>
      <Stack spacing={1} sx={{ flex: 1 }}>
        {isDev
          ? robotVersion.versions
              .filter((v) => v.updateStatus !== UpdateStatus.DONE)
              .map((version, index) => {
                return (
                  <DevStack key={version.type}>
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2">{version.type}</Typography>
                      <Typography variant="body2" textAlign="right">
                        {version.version.version}
                      </Typography>
                    </Stack>
                    <LinearProgressWithLabel
                      variant="determinate"
                      value={clamp(
                        version.installProgress,
                        0,
                        version.updateStatus === UpdateStatus.UPDATING
                          ? 99
                          : 100
                      )}
                      color={
                        version.updateStatus === UpdateStatus.ERROR
                          ? "error"
                          : "primary"
                      }
                      height={16}
                      error={version.updateStatus === UpdateStatus.ERROR}
                    />
                  </DevStack>
                );
              })
          : null}
        <Alert severity="warning">{t("version:updateWarning")}</Alert>
        <LinearProgressWithLabel
          variant="determinate"
          value={clamp(robotVersion.global.installProgress, 0, 100)}
          color={
            robotVersion.global.status === UpdaterStatus.UPDATING_WAITING_RETRY
              ? "warning"
              : "primary"
          }
          height={16}
        />
      </Stack>
    </>
  );
};

export default UpdatingVersionProgress;
