import { ATOMS, selectors } from "recoil/atoms";
import { Dialog, Typography } from "@mui/material";
import { LogoImg, NoConnectionDialogContent } from "./NoConnectivity.styled";
import { RecoilState, useRecoilValue } from "recoil";

import UpSlideTransition from "components/utils/Transitions/UpSlideTransition";
import VitibotLogo from "resources/logo/logo_carre.png";
import { useTranslation } from "react-i18next";

const NoConnectivity = () => {
  const { t } = useTranslation();
  const openConnectivity = useRecoilValue<boolean>(
    selectors[ATOMS.UI_CONNECTIVITY_OPEN] as RecoilState<boolean>
  );

  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      open={openConnectivity}
      TransitionComponent={UpSlideTransition}
    >
      <NoConnectionDialogContent>
        <div>
          <LogoImg src={VitibotLogo} alt="logo" />
        </div>
        <div>
          <Typography variant="h4" color="primary">
            {t("noConnection")}
          </Typography>
        </div>
      </NoConnectionDialogContent>
    </Dialog>
  );
};

export default NoConnectivity;
