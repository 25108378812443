import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { IRobotUser } from "types/emergency";
import { Stop } from "@mui/icons-material";
import { StopButton } from "pages/LiveControlPage.styled";
import { StopButtonWrapper } from "./IntercepCalibrationStopButton.styled";
import { colors } from "@mui/material";
import useStopOrder from "hooks/robot/useStopOrder";

const IntercepCalibrationStopButton = () => {
  const robotCurrentController = useRecoilValue<IRobotUser>(
    selectors[ATOMS.CURRENT_CONTROLLER] as RecoilState<IRobotUser>
  );

  const [stopOrder] = useStopOrder();

  const currentController = robotCurrentController.type !== "NONE";

  return (
    <StopButtonWrapper>
      <StopButton
        id="stop-order-btn"
        onClick={() => {
          stopOrder();
        }}
        disabled={!currentController}
        sx={{
          backgroundColor: currentController
            ? colors.red[500]
            : colors.grey[400],
        }}
      >
        <Stop fontSize="large" sx={{ color: "white" }} />
      </StopButton>
    </StopButtonWrapper>
  );
};

export default IntercepCalibrationStopButton;
