import { ATOMS, selectors } from "recoil/atoms";
import { Grid, Typography } from "@mui/material";
import { RecoilState, useRecoilValue } from "recoil";
import { TOOL_ID, TOOL_SIDE } from "const";

import CustomDivider from "components/corrector/CustomDivider";
import { IPartialPublishedStatus } from "types/live";
import { IToolsModesAvailable } from "types/tool";
import IntercepModeSelector from "components/orders/Intercep/IntercepModeSelector";
import { ModeSelectorBox } from "./LiveIntercepManager.styled";
import { emptyArray } from "utils/array";
import useCapabilities from "hooks/robot/useCapabilities";
import { useEffect } from "react";
import useIntercepInsideLiveHandlingManager from "hooks/robot/useIntercepInsideLiveHandlingManager";
import useIntercepOutsideLiveHandlingManager from "hooks/robot/useIntercepOutsideLiveHandlingManager";
import useLiveData from "hooks/live/useLiveData";
import useToolsMetadata from "hooks/tools/useToolsMetadata";
import { useTranslation } from "react-i18next";

interface ILiveIntercepManagerProps {
  setPublishedContent: (arg: IPartialPublishedStatus) => void;
  disabled: boolean;
}

const LiveIntercepManager = ({
  disabled,
  setPublishedContent,
}: ILiveIntercepManagerProps) => {
  const { t } = useTranslation(["live"]);

  const { initialized } = useLiveData(emptyArray(), "LiveIntercepManager");

  const [canUseIntercepOutside] = useCapabilities("live_version", 3);

  const { isIntercepOutsideSelected } = useToolsMetadata();

  const isIntercepOutsideAvailableAndSelected =
    isIntercepOutsideSelected && canUseIntercepOutside;

  const modesAvailable = useRecoilValue<IToolsModesAvailable>(
    selectors[ATOMS.TOOLS_MODES_AVAILABLE] as RecoilState<IToolsModesAvailable>
  );

  const {
    // current mode
    leftInsideCurrent: leftRobotIntercepModeInside,
    rightInsideCurrent: rightRobotIntercepModeInside,

    // requested by user
    leftInsideUserRequestMode: leftIntercepUserRequestModeInside,
    rightInsideUserRequestMode: rightIntercepUserRequestModeInside,
    setLeftInsideUserRequestMode: setLeftIntercepUserRequestModeInside,
    setRightInsideUserRequestMode: setRightIntercepUserRequestModeInside,

    // Interface only
    askInsideWorking: isIntercepInternalWorking,

    // requested mode for setMessage use
    leftInsideRequestToRobotMode: leftIntercepRequestToRobotModeInside,
    rightInsideRequestToRobotMode: rightIntercepRequestToRobotModeInside,
  } = useIntercepInsideLiveHandlingManager(true);

  const {
    // current mode
    leftOutsideCurrent: leftRobotIntercepModeOutside,
    rightOutsideCurrent: rightRobotIntercepModeOutside,

    // requested by user
    leftOutsideUserRequestMode: leftIntercepUserRequestModeOutside,
    rightOutsideUserRequestMode: rightIntercepUserRequestModeOutside,
    setLeftOutsideUserRequestMode: setLeftIntercepUserRequestModeOutside,
    setRightOutsideUserRequestMode: setRightIntercepUserRequestModeOutside,

    // requested mode for setMessage use
    leftOutsideRequestToRobotMode: leftIntercepRequestToRobotModeOutside,
    rightOutsideRequestToRobotMode: rightIntercepRequestToRobotModeOutside,
  } = useIntercepOutsideLiveHandlingManager();

  useEffect(() => {
    if (initialized === true) {
      setPublishedContent({
        tool: {
          left: {
            intercepWorkConfig: leftIntercepRequestToRobotModeInside,
            intercepOutsideWorkConfig: leftIntercepRequestToRobotModeOutside,
          },
          right: {
            intercepWorkConfig: rightIntercepRequestToRobotModeInside,
            intercepOutsideWorkConfig: rightIntercepRequestToRobotModeOutside,
          },
        },
      });
    }
  }, [
    setPublishedContent,
    initialized,
    leftIntercepRequestToRobotModeInside,
    rightIntercepRequestToRobotModeInside,
    leftIntercepRequestToRobotModeOutside,
    rightIntercepRequestToRobotModeOutside,
  ]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <CustomDivider size="small" transparent />
          <Typography variant="h6" align="center">
            {isIntercepOutsideAvailableAndSelected
              ? t("live:intercepModeInside")
              : t("live:intercepModeStandalone")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ModeSelectorBox>
            <IntercepModeSelector
              data-cy="intercep-mode-selector-left"
              side={TOOL_SIDE.left}
              currentMode={leftRobotIntercepModeInside}
              forceDisabled={disabled || isIntercepInternalWorking}
              requestedMode={leftIntercepUserRequestModeInside}
              setMode={setLeftIntercepUserRequestModeInside}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.INTERCEP &&
                  mode?.tool_id?.side === TOOL_SIDE.left
              )}
            />
          </ModeSelectorBox>
        </Grid>
        <Grid item xs={6}>
          <ModeSelectorBox>
            <IntercepModeSelector
              data-cy="intercep-mode-selector-right"
              side={TOOL_SIDE.right}
              currentMode={rightRobotIntercepModeInside}
              forceDisabled={disabled || isIntercepInternalWorking}
              requestedMode={rightIntercepUserRequestModeInside}
              setMode={setRightIntercepUserRequestModeInside}
              mode={modesAvailable.modes_available.filter(
                (mode) =>
                  mode?.tool_id?.name === TOOL_ID.INTERCEP &&
                  mode?.tool_id?.side === TOOL_SIDE.right
              )}
            />
          </ModeSelectorBox>
        </Grid>
      </Grid>
      {isIntercepOutsideAvailableAndSelected && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <CustomDivider size="small" transparent />
            <Typography variant="h6" align="center">
              {t("live:intercepModeOutside")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ModeSelectorBox>
              <IntercepModeSelector
                side={TOOL_SIDE.left}
                currentMode={leftRobotIntercepModeOutside}
                forceDisabled={disabled}
                requestedMode={leftIntercepUserRequestModeOutside}
                setMode={setLeftIntercepUserRequestModeOutside}
                mode={modesAvailable.modes_available.filter(
                  (mode) =>
                    mode?.tool_id?.name === TOOL_ID.INTERCEP_OUTSIDE &&
                    mode?.tool_id?.side === TOOL_SIDE.left
                )}
              />
            </ModeSelectorBox>
          </Grid>
          <Grid item xs={6}>
            <ModeSelectorBox>
              <IntercepModeSelector
                side={TOOL_SIDE.right}
                currentMode={rightRobotIntercepModeOutside}
                forceDisabled={disabled}
                requestedMode={rightIntercepUserRequestModeOutside}
                setMode={setRightIntercepUserRequestModeOutside}
                mode={modesAvailable.modes_available.filter(
                  (mode) =>
                    mode?.tool_id?.name === TOOL_ID.INTERCEP_OUTSIDE &&
                    mode?.tool_id?.side === TOOL_SIDE.right
                )}
              />
            </ModeSelectorBox>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LiveIntercepManager;
