import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  Snackbar,
  SnackbarCloseReason,
  Stack,
} from "@mui/material";
import {
  LangSelectContainer,
  Logo,
  SignInButton,
  SignInContainer,
  SignInContentWrapper,
  SignInForm,
  SignInFormInput,
  SignInTitle,
  SnackbarClose,
} from "./SignIn.styled";

import { AuthError } from "firebase/auth";
import LangSelect from "./LangSelect";
import { ROUTES } from "constants/routes";
import { TFunction } from "i18next";
import useAuth from "hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email: string) => {
  return emailRegex.test(String(email).toLowerCase());
};

const retrieveCleanMail = (email: string) => {
  if (!email.includes("@")) {
    return `${email}@vitibot.fr`;
  }
  return email;
};

const getErrorMessageByCode = (code: string, t: TFunction) => {
  switch (code) {
    case "auth/invalid-email":
      return t("invalidEmail");
    case "auth/user-disabled":
      return t("userDisabled");
    case "auth/user-not-found":
      return t("userNotFound");
    case "auth/wrong-password":
      return t("wrongPassword");
    case "auth/network-request-failed":
      return t("networkRequestFailed");
    default:
      // TODO: Maybe we should precise that it is a Firebase error ?
      return t("errorOccurred");
  }
};

enum PANE {
  LOGIN,
  NEW_PASSWORD,
}

const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signIn, resetEmail } = useAuth();

  const [pane, setPane] = useState<PANE>(PANE.LOGIN);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleMail = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handlePassword = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  const handleSignIn = async () => {
    if (!email) {
      setFeedback(t("resetEmailRequiredField"));
      setOpen(true);
      return;
    }
    if (!password) {
      setFeedback(t("resetPasswordRequiredField"));
      setOpen(true);
      return;
    }

    let userCleanEmail = retrieveCleanMail(email);
    if (validateEmail(userCleanEmail)) {
      try {
        await signIn({ username: userCleanEmail, password }, () => {
          navigate(ROUTES.ROOT, { replace: true });
        });
      } catch (errors: any) {
        let feedback = t("errorOccurred");
        errors.forEach((err: Error) => {
          if (err.name.toUpperCase().includes("FIREBASE")) {
            console.log("Debug - error from firebase...");
            feedback = getErrorMessageByCode((err as AuthError).code, t);
          } else {
            console.log("Debug - error from robot...");
            feedback = t("couldNotAuthenticateToRobot");
          }
        });
        setOpen(true);
        setFeedback(feedback);
      }
    } else {
      setFeedback(t("invalidEmail"));
      setOpen(true);
    }
  };

  const handleClose = (_: any, reason: SnackbarCloseReason | string) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setFeedback("");
  };

  const handleClickShowPassword = () => {
    // setState((state) => ({ showPassword: !state.showPassword }))
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async () => {
    let userCleanEmail = retrieveCleanMail(email);
    if (validateEmail(userCleanEmail)) {
      try {
        await resetEmail(email);
        setOpen(true);
        setFeedback(t("resetEmailSent"));
      } catch {
        setOpen(true);
        setFeedback(t("resetEmailError"));
      }
    } else {
      setOpen(true);
      setFeedback(t("resetEmailRequiredField"));
    }
  };

  return (
    <>
      <SignInContainer
        id="sign-in-form"
        position="relative"
        justifyContent="center"
        data-cy="sign-in-form"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            return pane === PANE.LOGIN
              ? handleSignIn()
              : pane === PANE.NEW_PASSWORD
              ? handleResetPassword()
              : null;
          }
        }}
      >
        <LangSelectContainer>
          <LangSelect />
        </LangSelectContainer>
        <SignInContentWrapper flex={2} alignItems="flex-end">
          <Logo />
        </SignInContentWrapper>
        {pane === PANE.LOGIN ? (
          <>
            <SignInContentWrapper>
              <SignInTitle>{t("signInHeader")}</SignInTitle>
            </SignInContentWrapper>
            <SignInContentWrapper>
              <SignInForm>
                <Stack direction="column" spacing={2}>
                  <SignInFormInput
                    autoFocus
                    fullWidth
                    id="Email"
                    data-cy="input-email"
                    label={t("email")}
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleMail}
                    margin="normal"
                    variant="outlined"
                  />

                  <SignInFormInput
                    fullWidth
                    id="current-password"
                    data-cy="input-password"
                    autoComplete="current-password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    label={t("password")}
                    value={password}
                    onChange={handlePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                            size="large"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Stack direction="column" spacing={0.5}>
                    <SignInButton
                      id="connect-btn"
                      data-cy="sign-in-btn"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSignIn}
                    >
                      {t("signInBtn")}
                    </SignInButton>
                    <SignInButton
                      fullWidth
                      size="small"
                      onClick={() => setPane(PANE.NEW_PASSWORD)}
                    >
                      {t("newPassword")}
                    </SignInButton>
                  </Stack>
                </Stack>
              </SignInForm>
            </SignInContentWrapper>
          </>
        ) : pane === PANE.NEW_PASSWORD ? (
          <>
            <SignInContentWrapper>
              <SignInTitle>{t("newPassword")}</SignInTitle>
            </SignInContentWrapper>
            <SignInContentWrapper>
              <SignInForm>
                <Stack direction="column" spacing={2}>
                  <SignInFormInput
                    autoFocus
                    fullWidth
                    id="Email"
                    label={t("email")}
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleMail}
                    margin="normal"
                    variant="outlined"
                  />

                  <Stack direction="column" spacing={0.5}>
                    <SignInButton
                      id="connect-btn"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleResetPassword}
                    >
                      {t("confirm")}
                    </SignInButton>
                    <SignInButton
                      fullWidth
                      size="small"
                      onClick={() => setPane(PANE.LOGIN)}
                    >
                      {t("cancel")}
                    </SignInButton>
                  </Stack>
                </Stack>
              </SignInForm>
            </SignInContentWrapper>
          </>
        ) : null}{" "}
      </SignInContainer>

      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{feedback}</span>}
        action={[
          <SnackbarClose
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={(evt) => handleClose(evt, "")}
            size="large"
          >
            <Close />
          </SnackbarClose>,
        ]}
      />
    </>
  );
};

export default SignIn;
