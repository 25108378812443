import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useRef } from "react";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { IUserIdentity } from "types/user";

const useApiSocket = () => {
  const [socketConnected, setSocketConnected] = useRecoilState<boolean>(
    selectors[ATOMS.WS_CONNECTED] as RecoilState<boolean>
  );
  const socketConnectedInternal = useRef(false);

  const socketToken = useRecoilValue<string | null>(
    selectors[ATOMS.UI_USER_TOKEN] as RecoilState<string | null>
  );
  const userIdentity = useRecoilValue<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );

  useEffect(() => {
    const tm = setInterval(() => {
      if (
        socketConnectedInternal.current !==
        AutopilotSocketManager.getInstance().isConnected
      ) {
        setSocketConnected(AutopilotSocketManager.getInstance().isConnected);
        socketConnectedInternal.current =
          AutopilotSocketManager.getInstance().isConnected;
      }
    }, 100);

    return () => {
      clearInterval(tm);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AutopilotSocketManager.getInstance().checkSocketState(
      socketToken,
      userIdentity
    );
  }, [socketToken, socketConnected, userIdentity]);
};

export default useApiSocket;
