import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { IFirebaseUser } from "types/firebase";
import { useEffect } from "react";
import useHttpApi from "hooks/network/useHttpApi";

const getFoufiInfo = () => {
  const foufi = fully;
  const kiosk_info: { [Key: string]: any } = {};
  try {
    kiosk_info.android_version = foufi.getAndroidVersion() ?? "";
    kiosk_info.battery = foufi.getBatteryLevel() ?? -1;
    kiosk_info.device_id = foufi.getDeviceId() ?? "";
    kiosk_info.device_model = foufi.getDeviceModel() ?? "";
    kiosk_info.device_serial = foufi.getSerialNumber() ?? "";
    kiosk_info.imei = foufi.getImei() ?? "";
    kiosk_info.ip = foufi.getIp4Address() ?? "";
    kiosk_info.sim_serial = foufi.getSimSerialNumber() ?? "";
    kiosk_info.ssid = foufi.getWifiSsid() ?? "";
    kiosk_info.version_code = foufi.getFullyVersionCode() ?? "";
    kiosk_info.version = foufi.getFullyVersion() ?? "";
    kiosk_info.wifi_enabled = foufi.isWifiEnabled() ?? false;
    kiosk_info.wifi_signal = foufi.getWifiSignalLevel() ?? -1;
  } catch (error) {
    console.log(error);
  }
  return kiosk_info;
};

const useRobotUser = () => {
  const {
    httpRobotManager: { setRobotUser },
  } = useHttpApi();
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );
  const secretToken = useRecoilValue<string | null>(
    selectors[ATOMS.UI_USER_TOKEN] as RecoilState<string | null>
  );

  useEffect(() => {
    let has_kiosk = false;
    let kiosk_info = {};
    if (typeof FullyKiosk !== "undefined") {
      has_kiosk = true;
      kiosk_info = getFoufiInfo();
    }

    setRobotUser({
      app_version: process.env.REACT_APP_VERSION,
      env: process.env.NODE_ENV,
      has_kiosk,
      kiosk_info,
      start_url: window.location.href,
      user: fbUser ? `${fbUser.email} ( ${fbUser.uid} )` : "NONE",
      sid: AutopilotSocketManager.getInstance().socketId,
    }).catch(() => {
      console.log("Unable to set user info");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser, secretToken]);
};

export default useRobotUser;
