import { Alert, Snackbar, styled } from "@mui/material";

export const SnackBarContainer = styled(Snackbar, {
  name: "SnackBarContainer",
  shouldForwardProp: (prop) => prop !== "top",
})<{
  vertical: "bottom" | "top";
}>(
  ({ theme, vertical }) => `
  box-shadow: none;


  ${[theme.breakpoints.down("xl")]} :{
    ${vertical === "bottom" ? "bottom: 127px;" : "top: 16px;"}
  }
`
);

export const AlertContent = styled(Alert, {
  name: "AlertContent",
})(`
  box-shadow: none;
`);
