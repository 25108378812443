import { Box, Stack } from "@mui/material";

import { FixedPageCenter } from "styled/Global/container.styled";
import ProgressLoader from "../ProgressLoader";
import { validNotEmptyString } from "utils/string";

type FixedSpinnerProps = {
  text?: string;
};

const FixedSpinner = ({ text }: FixedSpinnerProps) => (
  <FixedPageCenter>
    <Stack direction="column" spacing={2} alignItems="center">
      <Box height={100} width={100}>
        <ProgressLoader />
      </Box>
      {validNotEmptyString(text) ? text : null}
    </Stack>
  </FixedPageCenter>
);

export default FixedSpinner;
