import { API_ORDERS, ROLE_DEV_ONLY } from "const";
import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useSetRecoilState } from "recoil";
import { useCallback, useRef } from "react";

import { IApiResponse } from "types/api";
import { IRobotStatusCode } from "types/statusCode";
import useGetStatusByCode from "hooks/statusCodes/useGetStatusByCode";
import useHasAccess from "hooks/user/useHasAccess";
import useRobotOrder from "hooks/robot/useRobotOrder";
import { validNotEmptyString } from "utils/string";

const parcelCheckNotifierShowingTimeout = 15000;

const useParcelCheck = () => {
  const setSnackBar = useSetRecoilState(selectors[ATOMS.SNACKBAR]);
  const setMapCheckError = useSetRecoilState<IRobotStatusCode | null>(
    selectors[ATOMS.MAP_CHECK_ERROR] as RecoilState<IRobotStatusCode | null>
  );

  const parcelCheckNotifierShowingTimeoutRef = useRef<NodeJS.Timeout | null>(
    null
  );

  const sendOrder = useRobotOrder();
  const isDev = useHasAccess("any", ROLE_DEV_ONLY);
  const getStatus = useGetStatusByCode();

  const notifyDevUser = useCallback(
    (response: IApiResponse) => {
      console.log("Check for notification", {
        isDev,
        valid: validNotEmptyString(response.status.code),
      });
      if (!isDev) return;
      if (!validNotEmptyString(response.status.code)) return;

      const status = getStatus(response.status.code);
      setSnackBar({
        content: `${response.status.code}: ${status.message}, ${status.resolution}, ${response.status.message}`,
        duration: 5000,
        severity: "error",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDev]
  );

  const doParcelCheck = useCallback(async () => {
    try {
      if (parcelCheckNotifierShowingTimeoutRef.current) {
        clearTimeout(parcelCheckNotifierShowingTimeoutRef.current);
        setMapCheckError(null);
      }
      const response = await sendOrder(API_ORDERS.MapCheck, {});
      notifyDevUser(response);
      if (response.success) {
        setMapCheckError(null);
      } else {
        setMapCheckError(response.status);
        parcelCheckNotifierShowingTimeoutRef.current = setTimeout(() => {
          setMapCheckError(null);
        }, parcelCheckNotifierShowingTimeout);
      }
    } catch (error) {
      console.log(error);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyDevUser]);

  return { doParcelCheck };
};

export default useParcelCheck;
