import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, RecoilValue, useRecoilValue } from "recoil";
import { useEffect, useMemo, useRef, useState } from "react";

import { INTERCEP_CALIBRATION_STEP } from "const";
import { intercepCalibrationMachine } from "stateMachine/IntercepCalibration";
import useCapabilities from "hooks/robot/useCapabilities";
import useHttpApi from "hooks/network/useHttpApi";
import { useMachine } from "@xstate/react";

const moveFromIdle: Map<INTERCEP_CALIBRATION_STEP, string> = new Map([
  [INTERCEP_CALIBRATION_STEP.STARTED, "ROBOT_ON_STEP_1"],
  [INTERCEP_CALIBRATION_STEP.INITIALIZING, "ROBOT_ON_STEP_2"],
  [INTERCEP_CALIBRATION_STEP.WAIT_CALIBRATION, "ROBOT_ON_STEP_3"],
  [INTERCEP_CALIBRATION_STEP.CALIBRATION, "ROBOT_ON_STEP_4"],
  [INTERCEP_CALIBRATION_STEP.SAVE_CONFIG, "ROBOT_ON_STEP_5"],
  [INTERCEP_CALIBRATION_STEP.SUCCESS, "ROBOT_ON_STEP_6"],
  [INTERCEP_CALIBRATION_STEP.ERROR, "ERROR"],
]);

const noTimerSteps = [
  INTERCEP_CALIBRATION_STEP.IDLE,
  INTERCEP_CALIBRATION_STEP.ERROR,
  INTERCEP_CALIBRATION_STEP.SUCCESS,
];

export const MAX_INTERCEP_CALIBRATION_STEP_TIME = 60 * 1000;

const useIntercepCalibration = () => {
  const {
    httpRobotManager: { setIntercepCalibrationStep },
  } = useHttpApi();

  const [state, send] = useMachine(intercepCalibrationMachine, {
    services: {
      sendCalibrationStep: (_, e) =>
        setIntercepCalibrationStep(e.step, e.callback),
    },
  });

  const socketConnected = useRecoilValue<boolean>(
    selectors[ATOMS.WS_CONNECTED] as RecoilValue<boolean>
  );

  const intercepCalibrationStep = useRecoilValue<INTERCEP_CALIBRATION_STEP>(
    selectors[
      ATOMS.INTERCEP_CALIBRATION_STEP
    ] as RecoilValue<INTERCEP_CALIBRATION_STEP>
  );

  const canPerformIntercepCalibration = useRecoilValue<boolean>(
    selectors[ATOMS.CAN_PERFORM_INTERCEP_CALIBRATION] as RecoilState<boolean>
  );

  const [canAutopilotPerformIntercepCalibration] = useCapabilities(
    "sensor_calibration",
    1
  );

  const canPerformCalibration =
    canAutopilotPerformIntercepCalibration && canPerformIntercepCalibration;

  const [stepTimerLeft, setStepTimerLeft] = useState(
    MAX_INTERCEP_CALIBRATION_STEP_TIME
  );
  const stepTimerInterval = useRef<NodeJS.Timer | null>(null);

  const isPerformingIntercepCalibration = useMemo(
    () =>
      socketConnected === true && // Required for sensor calibration
      canPerformCalibration === true &&
      intercepCalibrationStep !== INTERCEP_CALIBRATION_STEP.IDLE &&
      intercepCalibrationStep !== INTERCEP_CALIBRATION_STEP.ERROR,
    [canPerformCalibration, intercepCalibrationStep, socketConnected]
  );

  useEffect(() => {
    if (socketConnected === false) return; // Avoid any action if socket is not connected

    console.log("SM --", {
      step: intercepCalibrationStep,
      state: state.value,
    });

    // State machine is in idle state and the robot is not in idle state
    if (state.value === "idle" && moveFromIdle.has(intercepCalibrationStep)) {
      if (intercepCalibrationStep === INTERCEP_CALIBRATION_STEP.ERROR) {
        send(moveFromIdle.get(INTERCEP_CALIBRATION_STEP.ERROR)!);
        return;
      }

      send(moveFromIdle.get(intercepCalibrationStep)!);
      return;
    }

    // User has send init action and the robot is waiting for user action
    if (
      state.value === "robot_step_2" &&
      intercepCalibrationStep === INTERCEP_CALIBRATION_STEP.WAIT_CALIBRATION
    ) {
      send("NEXT");
      return;
    }

    // User has send calibration action and the robot is currently saving the config
    // or
    // robot has already moved to the done step without user interaction
    if (
      ["robot_step_4", "robot_step_5"].includes(state.value.toString()) &&
      (intercepCalibrationStep === INTERCEP_CALIBRATION_STEP.SAVE_CONFIG ||
        intercepCalibrationStep === INTERCEP_CALIBRATION_STEP.SUCCESS)
    ) {
      send("NEXT");
      return;
    }

    // Enforce pass to the error state
    if (
      state.value !== "error" &&
      intercepCalibrationStep === INTERCEP_CALIBRATION_STEP.ERROR
    ) {
      send("ERROR");
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercepCalibrationStep, state.value]);

  useEffect(() => {
    if (stepTimerInterval.current) clearInterval(stepTimerInterval.current);

    if (noTimerSteps.includes(intercepCalibrationStep)) {
      setStepTimerLeft(-1); // -1 means no timer to
      return;
    }
    setStepTimerLeft(MAX_INTERCEP_CALIBRATION_STEP_TIME);

    stepTimerInterval.current = setInterval(() => {
      setStepTimerLeft((prev) => {
        const newTime = prev - 1000; // decrease by 1 second
        if (newTime <= 0) {
          clearInterval(stepTimerInterval.current!);
        }
        return newTime;
      });
    }, 1000);
  }, [intercepCalibrationStep]);

  return {
    canPerformIntercepCalibration: canPerformCalibration,
    isPerformingIntercepCalibration,
    setIntercepCalibrationStep,
    intercepCalibrationStep,
    currentState: state.value,
    sendAction: send,
    stepTimerLeft,
  };
};

export default useIntercepCalibration;
