import { Switch, colors, styled } from "@mui/material";

export const FloatingSwitch = styled(Switch, {
  name: "FloatingSwitch",
})(({ theme }) => ({
  alignSelf: "center",
  "& .MuiSwitch-switchBase": {
    margin: "auto",
    // Ready but not checked (+ general)
    color: theme.palette.primary.main,
    transition: "color .2s ease-out",
    "& + .MuiSwitch-track": {
      backgroundColor: colors.grey[500],
      transition: "background-color .2s ease-out",
    },

    // Disabled
    "&.Mui-disabled": {
      color: colors.grey[300],
      "& ~ .MuiSwitch-track": {
        backgroundColor: colors.grey[200],
        opacity: 1,
      },
    },

    // Checked
    "&.Mui-checked": {
      color: theme.palette.primary.main,
      "& ~ .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
