import { Stack, StackProps, styled } from "@mui/material";

export const FlagImage = styled("img", { name: "FlagImage" })({
  verticalAlign: "middle",
  maxWidth: 24,
  maxHeight: 24,
});

type LangContainerProps = StackProps & {
  inDrawer?: boolean;
};

export const LangContainer = styled(
  ({ inDrawer, ...props }: LangContainerProps) => {
    return <Stack direction="column" spacing={1} flex={1} {...props} />;
  },
  {
    name: "LangContainer",
    shouldForwardProp: (prop) => prop !== "inDrawer",
  }
)(({ inDrawer = false }) => {
  if (inDrawer) {
    return {
      position: "absolute",
      bottom: 82,
      left: 0,
      right: 0,
    };
  }

  return ``;
});
