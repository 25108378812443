import { ThemeOptions } from "@mui/material";

export const drawerWidth = 240;

export const baseAppTheme: ThemeOptions = {
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 4,
        },
      },
    },
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontSize: 14,
    fontWeightMedium: 500,
  },
  palette: {
    secondary: {
      main: "#373a36",
      // dark: will be calculated from palette.secondary.main,
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#0957c3",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
};
