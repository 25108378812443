import { Button, styled } from "@mui/material";

import { borderRadius } from "utils/sharedStyles";

export const ModeSelector = styled(Button, {
  name: "ModeSelector",
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{ isDisabled: boolean }>(({ isDisabled }) => {
  if (isDisabled) {
    return `
  border: 1px solid #0000001f !important;
  color: #0000008c !important;
  backgroundColor: #00000042 !important;
`;
  }
  return "";
});

// SliderBase, SliderHorizontal, SliderMower, SliderMowerDisabled
export const MowerSpeedSlider = styled("div", {
  name: "MowerSpeedSlider",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
   & input {
    &::-webkit-slider-thumb {
      appearance: none;
      background-color: ${disabled ? "#777" : theme.palette.primary.main};
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(./icons/autorenew_black_24dp.svg);
      border: ${disabled ? "unset" : `2px solid ${theme.palette.primary.main}`};
      border-radius: 6px;
      cursor: pointer;
      fill: white;
      height: 30px;
      width: 30px;
    }

    appearance: none;
    border: 2px solid;
    border-color: ${
      disabled ? theme.palette.text.disabled : theme.palette.primary.main
    };
    border-radius: ${borderRadius}px;
    background-color: ${theme.palette.background.default};
    height: 15px;
    margin: 0;
    width: 150px;
  }

  display: inline-block;
  padding: 0;
  height: 30px;
  width: 156px;
`
);
