import { borderRadius, borderSize } from "utils/sharedStyles";

import { styled } from "@mui/material/styles";

export const FeedbackContainer = styled("div", {
  name: "FeedbackContainer",
  shouldForwardProp: (prop) => prop !== "isAlert",
})<{ isAlert: boolean }>(
  ({ theme, isAlert }) => `
  background-color: ${isAlert ? theme.palette.warning.main : "#fff"};
  border: #fff;
  border-radius: ${borderRadius}px;
  position: absolute;
  top: ${borderSize}px;
  left: ${borderSize}px;
  right: ${borderSize}px;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - ${2 * borderSize}px);
  text-overflow: ellipsis;
  text-align: center;
  z-index: 7;
  color: ${
    isAlert ? theme.palette.warning.contrastText : theme.palette.primary.main
  };
`
);
