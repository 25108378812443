import { Button, Divider, Stack, StackProps, styled } from "@mui/material";

/**
 * * PAGE CONTAINER
 */
export const IntercepCalibrationPageContainer = styled(Stack, {
  name: "IntercepCalibrationPageWrapper",
})({
  height: "100%",
});

/**
 * * PAGE DIVIDER
 */
export const IntercepCalibrationPageDivider = styled(Divider, {
  name: "IntercepCalibrationPageDivider",
})({
  alignSelf: "center",
  width: "85%",
  opacity: 0.5,
});

/**
 * * HEADER
 */
export const IntercepCalibrationPageHeader = styled(
  (props: StackProps) => (
    <>
      <Stack {...props} />
      <Divider />
    </>
  ),
  {
    name: "IntercepCalibrationPageHeader",
  }
)(({ theme }) => ({ padding: theme.spacing(2) }));

/**
 * * BODY CONTAINER
 */
export const IntercepCalibrationPageBodyContainer = styled(Stack, {
  name: "IntercepCalibrationPageBodyContainer",
})(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  alignItems: "stretch",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: "#f5f5f5",
}));

/**
 * * BODY CONTENT
 */
export const IntercepCalibrationPageBodyContent = styled(Stack, {
  name: "IntercepCalibrationPagePageBodyContent",
})(({ theme }) => ({
  flex: 1,
  gap: theme.spacing(3),
}));

/**
 * * FOOTER
 */
export const IntercepCalibrationPageFooter = styled(Stack, {
  name: "IntercepCalibrationPagePageFooter",
})(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: "stretch",
  backgroundColor: "#f5f5f5",
}));

/**
 * * BUTTONS
 */
export const IntercepCalibrationActionButton = styled(Button)({
  height: 40,
});
