import { IWorkConfigEnum } from "const";

export interface ILiveMoveStatus {
  angular_angle: number;
  lateral_angle: number;
}
export interface ILiveToolsConstStatus {
  TOP: number;
  BOTTOM: number;
}
export interface ILiveToolsStatus {
  intercep_work_status_left: IWorkConfigEnum;
  intercep_work_status_right: IWorkConfigEnum;
  intercep_outside_work_status_left: IWorkConfigEnum;
  intercep_outside_work_status_right: IWorkConfigEnum;
  tools_status_left: number;
  tools_status_right: number;
  mower_speed_left: number;
  mower_speed_right: number;
  mower_work_status_left: IWorkConfigEnum;
  mower_work_status_right: IWorkConfigEnum;
}

export interface ILiveStatus {
  move: ILiveMoveStatus;
  tools_const: ILiveToolsConstStatus;
  tools_status: ILiveToolsStatus;
  turtle_mode: boolean;
}

export interface IPartialPublishedStatus {
  move?: IPartialPublishedMoveStatus;
  tool?: IPartialPublishedToolStatus;
}
interface IPartialPublishedMoveStatus {
  speedNorm?: number;
  lateralAngle?: number;
  angularAngle?: number;
  mode?: MoveMode; // Must be 2 normally
}

export enum MoveMode {
  Neutral = 0,
  autonomous = 1,
  remote = 2,
  newAutonomous = 3,
}

interface IPartialPublishedToolStatus {
  left?: IPartialPublishedToolSideStatus;
  right?: IPartialPublishedToolSideStatus;
}
interface IPartialPublishedToolSideStatus {
  targetHeight?: number;
  floating?: boolean;
  intercepWorkConfig?: string | null;
  intercepOutsideWorkConfig?: string | null;
  mowerSpeed?: number;
  neutral?: boolean;
  mowerWorkConfig?: string | null;
}

export interface IPublishedStatus {
  move: IPublishedMoveStatus;
  tool: IPublishedToolStatus;
}

export interface IPublishedToolStatus {
  left: IPublishedToolSideStatus;
  right: IPublishedToolSideStatus;
}

interface IPublishedToolSideStatus {
  targetHeight: number | undefined;
  floating: boolean;
  intercepWorkConfig: string | null;
  intercepOutsideWorkConfig: string | null;
  neutral: boolean;
  mowerSpeed: number;
  mowerWorkConfig: string | null;
}

interface IPublishedMoveStatus {
  speedNorm: number;
  lateralAngle: number;
  angularAngle: number;
  mode: MoveMode; // Must be 2 normally
}

export interface ITurtleResponse {
  turtle: boolean;
}
