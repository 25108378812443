import { ATOMS, selectors } from "recoil/atoms";
import {
  AddTask,
  Build,
  Error,
  GpsNotFixed,
  Info,
  Lock,
  Map,
  Person,
  SignalCellularConnectedNoInternet0Bar,
  Update,
  Warning,
  WifiLock,
  WifiOff,
} from "@mui/icons-material";
import { MAP_OFFSET, ROLE_DEV_SUPERVISOR } from "const";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";

import { DevSpan } from "styled/Dev";
import { IAlertData } from "types/alert";
import { IAutonomousStatus } from "types/autonomous";
import { IRobotCapabilities } from "types/capabilities";
import { IRobotGPS } from "types/gps";
import { IRobotLockStatus } from "types/emergency";
import { IRobotStatusCode } from "types/statusCode";
import { ISelectedRobot } from "types/userRobot";
import { IUserIdentity } from "types/user";
import { IWorkSomething } from "types/work";
import { dbToolConfig } from "types/tool";
import { isDefined } from "utils/object";
import { makeId } from "utils/string";
import useGetStatusByCode from "hooks/statusCodes/useGetStatusByCode";
import useHasAccess from "hooks/user/useHasAccess";
import usePhoneInfo from "hooks/user/usePhoneInfo";
import { useTranslation } from "react-i18next";

// Order by error first, then warning, then info, then success
const orderByImportance = (a: IAlertData, b: IAlertData) => {
  if (a.severity === "error" && b.severity !== "error") {
    return -1;
  } else if (a.severity !== "error" && b.severity === "error") {
    return 1;
  } else if (a.severity === "warning" && b.severity !== "warning") {
    return -1;
  } else if (a.severity !== "warning" && b.severity === "warning") {
    return 1;
  } else if (a.severity === "info" && b.severity !== "info") {
    return -1;
  } else if (a.severity !== "info" && b.severity === "info") {
    return 1;
  } else if (a.severity === "success" && b.severity !== "success") {
    return -1;
  } else if (a.severity !== "success" && b.severity === "success") {
    return 1;
  } else {
    return 0;
  }
};

const toSkipAlert: { code: string; message?: string }[] = [
  { code: "e3040", message: "No Work" },
  { code: "i3001" }, // End of path
  { code: "i4001" }, // GUI cut the power
  { code: "i3019" }, // Initial emergency
];

const shouldFilterAlert = (lockReason: IRobotStatusCode): boolean => {
  for (const alert of toSkipAlert) {
    if (alert.code !== lockReason.code) {
      continue;
    }
    if (isDefined(alert.message) && alert.message !== lockReason.message) {
      continue;
    }
    return false;
  }

  return true;
};

const useAlertManager = () => {
  const { t } = useTranslation(["alert", "tools", "common", "version"]);

  const autonomous = useRecoilValue<IAutonomousStatus>(
    selectors[ATOMS.AUTONOMOUS] as RecoilState<IAutonomousStatus>
  );
  const fbUser = useRecoilValue(selectors[ATOMS.FB_USER]);
  const userIdentity = useRecoilValue<IUserIdentity | null>(
    selectors[ATOMS.UI_USER_CREDENTIALS] as RecoilState<IUserIdentity | null>
  );
  const isUserOnline = useRecoilValue(selectors[ATOMS.IS_USER_ONLINE]);
  const statusCodes = useRecoilValue(selectors[ATOMS.STATUS_CODE]);
  const selectedRobot = useRecoilValue<ISelectedRobot>(
    selectors[ATOMS.SELECTED_ROBOT] as RecoilState<ISelectedRobot>
  );

  const robotToolsConfig = useRecoilValue(selectors[ATOMS.TOOLS_CONFIG]);
  const toolsConfigAvailable = useRecoilValue<dbToolConfig[]>(
    selectors[ATOMS.TOOLS_CONFIG_AVAILABLE] as RecoilState<dbToolConfig[]>
  );
  const gps = useRecoilValue<IRobotGPS>(
    selectors[ATOMS.GPS] as RecoilState<IRobotGPS>
  );
  const workPath = useRecoilValue<IWorkSomething[]>(
    selectors[ATOMS.WORK_PATH] as RecoilState<IWorkSomething[]>
  );
  const lockStatus = useRecoilValue<IRobotLockStatus>(
    selectors[ATOMS.LOCK_STATUS] as RecoilState<IRobotLockStatus>
  );
  const robotCapabilities = useRecoilValue<IRobotCapabilities>(
    selectors[ATOMS.CAPABILITIES] as RecoilState<IRobotCapabilities>
  );
  const robotEstop = useRecoilValue<number>(
    selectors[ATOMS.ESTOP] as RecoilState<number>
  );
  const robotBumpers = useRecoilValue<number>(
    selectors[ATOMS.BUMPERS] as RecoilState<number>
  );
  const robotBumpersShunted = useRecoilValue<boolean>(
    selectors[ATOMS.BUMPERS_SHUNTED] as RecoilState<boolean>
  );
  const robotWsID = useRecoilValue<string>(
    selectors[ATOMS.WS_ROBOT_ID] as RecoilState<string>
  );
  const robotLowLevelVersionMismatch = useRecoilValue<boolean>(
    selectors[ATOMS.LOW_LEVEL_VERSION_MISMATCH] as RecoilState<boolean>
  );
  const robotParcelCheckFeedback = useRecoilValue<IRobotStatusCode | null>(
    selectors[ATOMS.MAP_CHECK_ERROR] as RecoilState<IRobotStatusCode | null>
  );

  const hasBakusUpdateAvailable = useRecoilValue<boolean>(
    selectors[
      ATOMS.VERSION_SHOULD_SHOW_UPDATE_NOTIFICATION
    ] as RecoilState<boolean>
  );

  const setMapOffset = useSetRecoilState<number[]>(
    selectors[ATOMS.UI_MAP_OFFSET] as RecoilState<number[]>
  );
  const setHasAlertFeedback = useSetRecoilState<boolean>(
    selectors[ATOMS.UI_HAS_ALERT_FEEDBACK] as RecoilState<boolean>
  );
  const setLockList = useSetRecoilState<IAlertData[]>(
    selectors[ATOMS.ALERT_FEEDBACK_LIST] as RecoilState<IAlertData[]>
  );
  const isDev = useHasAccess("any", ROLE_DEV_SUPERVISOR);

  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);

  const [autonomousFb, setAutonomousFb] = useState<IAlertData | null>(null);
  const [bumpers, setBumpers] = useState<IAlertData | null>(null);
  const [bumpersShunted, setBumpersShunted] = useState<IAlertData | null>(null);
  const [emergencyFb, setEmergency] = useState<IAlertData | null>(null);
  const [estop, setEstop] = useState<IAlertData | null>(null);
  const [lowLevelVersionMismatch, setLowLevelVersionMismatch] =
    useState<IAlertData | null>(null);
  const [online, setOnline] = useState<IAlertData | null>(null);
  const [rtkBack, setRtkBack] = useState<IAlertData | null>(null);
  const [rtkFront, setRtkFront] = useState<IAlertData | null>(null);
  const [socket, setSocket] = useState<IAlertData | null>(null);
  const [tools, setTools] = useState<IAlertData | null>(null);
  const [userFb, setUserFb] = useState<IAlertData | null>(null);
  const [work, setWork] = useState<IAlertData | null>(null);
  const [mapFeedback, setMapFeedback] = useState<IAlertData | null>(null);
  const [bakusUpdateAvailable, setBakusUpdateAvailable] =
    useState<IAlertData | null>(null);

  const getStatus = useGetStatusByCode();
  const { batteryError: battery } = usePhoneInfo();

  useEffect(() => {
    if (gps.status_back && gps.status_front) {
      if (gps.status_back === "FIXED_RTK") {
        setRtkFront(null);
      } else {
        setRtkFront({
          id: makeId(),
          helper: t("common:contactSupport"),
          icon: <GpsNotFixed />,
          severity: "error",
          text: t("alert:rtkError"),
          title: t("alert:rtkBack"),
        });
      }
      if (gps.status_front !== "FIXED_RTK") {
        setRtkBack({
          id: makeId(),
          helper: t("common:contactSupport"),
          icon: <GpsNotFixed />,
          severity: "error",
          text: t("alert:rtkError"),
          title: t("alert:rtkFront"),
        });
      } else {
        setRtkBack(null);
      }
    }
  }, [gps.status_back, gps.status_front, t]);

  useEffect(() => {
    if (socketConnected === false) {
      setSocket({
        id: makeId(),
        helper: t("alert:checkPhone"),
        icon: <WifiOff />,
        severity: "error",
        text: t("alert:directConnectionError"),
        title: t("alert:wifi"),
      });
    } else if (socketConnected && robotWsID !== selectedRobot.id) {
      setSocket({
        id: makeId(),
        helper: t("alert:checkSelectedBakus"),
        icon: <WifiLock />,
        severity: "warning",
        text: t("alert:selectedBakusDifferent"),
        title: t("alert:wifi"),
      });
    } else {
      setSocket(null);
    }
  }, [selectedRobot, robotWsID, socketConnected, t]);

  useEffect(() => {
    if (autonomous.reason?.code) {
      const isInfo = autonomous.reason.code.startsWith("i");
      const status = getStatus(autonomous?.reason?.code);
      setAutonomousFb({
        id: makeId(),
        extra:
          status.extraMessage || isDev ? (
            <>
              {status.extraMessage}
              {isDev && (
                <>
                  <br />
                  <DevSpan>{autonomous?.reason?.message}</DevSpan>
                </>
              )}
            </>
          ) : null,
        helper: status.resolution,
        icon: isInfo ? <Info /> : <Error />,
        severity: isInfo ? "info" : "error",
        text: status.message
          ? `${autonomous?.reason?.code}: ${status.message}`
          : autonomous?.reason?.code,
        title: t("alert:navigation"),
      });
    } else {
      setAutonomousFb(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autonomous, statusCodes, t]);

  useEffect(() => {
    if (!robotToolsConfig) {
      setTools({
        id: makeId(),
        helper: t("alert:useToolsConfigSettings"),
        icon: <Build />,
        severity: "warning",
        text: t("alert:checkToolsConfigurationSettings"),
        title: t("tools:tools"),
      });
    } else if (toolsConfigAvailable.length === 0) {
      setTools({
        id: makeId(),
        helper: t("common:contactSupport"),
        icon: <Build />,
        severity: "warning",
        text: t("alert:noToolsConfigurationAvailable"),
        title: t("tools:tools"),
      });
    } else {
      setTools(null);
    }

    if (workPath.length === 0) {
      setWork({
        id: makeId(),
        helper: t("alert:useStartButtonToPlanTask"),
        icon: <AddTask />,
        severity: "info",
        text: t("alert:noTaskPlanned"),
        title: t("common:task"),
      });
    } else {
      setWork(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workPath, robotCapabilities, robotToolsConfig, toolsConfigAvailable, t]);

  useEffect(() => {
    if (
      lockStatus.locks &&
      Array.isArray(lockStatus.locks) &&
      lockStatus.locks.length > 0
    ) {
      const filtered = lockStatus.locks.filter((lock) =>
        shouldFilterAlert(lock.reason)
      );

      if (filtered.length > 0) {
        // ! This is pretty dumb to look at the 0th lock, this is not sorted in Autopilot
        const reason = filtered[0].reason;
        const status = getStatus(reason.code);

        setEmergency({
          id: makeId(),
          extra:
            status.extraMessage || isDev ? (
              <>
                {status.extraMessage}
                {isDev && (
                  <>
                    <br />
                    <DevSpan>{reason.message}</DevSpan>
                  </>
                )}
              </>
            ) : null,
          helper:
            status.lock_resolution ||
            status.resolution ||
            t("common:contactSupport"),
          icon: <Lock />,
          severity: "error",
          text: `${reason.code}: ${status.message}`,
          title: t("alert:navigation"),
        });
      }
    } else {
      setEmergency(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDev, statusCodes, lockStatus.locks, t]);

  useEffect(() => {
    if (robotEstop === 1) {
      setEstop({
        id: makeId(),
        helper: t("alert:removeEsStopToStartTask"),
        icon: <Lock />,
        severity: "error",
        text: t("alert:esStopActivated"),
        title: t("common:esStop"),
      });
    } else {
      setEstop(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotEstop, t]);

  useEffect(() => {
    if (robotBumpers === 1) {
      setBumpers({
        id: makeId(),
        helper: t("alert:checkBumperState"),
        icon: <Lock />,
        severity: "error",
        text: t("alert:bumpersHit"),
        title: t("common:bumpers"),
      });
    } else {
      setBumpers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotBumpers, t]);

  useEffect(() => {
    if (robotBumpersShunted === true) {
      setBumpersShunted({
        id: makeId(),
        helper: t("alert:reactivateBumpers"),
        icon: <Warning />,
        severity: "warning",
        text: t("alert:bumpersAreShunted"),
        title: t("alert:bumpersShunted"),
      });
    } else {
      setBumpersShunted(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotBumpersShunted, t]);

  useEffect(() => {
    if (robotLowLevelVersionMismatch === true) {
      setLowLevelVersionMismatch({
        id: makeId(),
        helper: t("common:contactSupport"),
        icon: <Warning />,
        severity: "warning",
        text: t("alert:lowLevelVersionMismatchMessage"),
        title: t("alert:lowLevelVersionMismatch"),
      });
    } else {
      setLowLevelVersionMismatch(null);
    }
  }, [t, robotLowLevelVersionMismatch]);

  useEffect(() => {
    if (!isUserOnline) {
      setOnline({
        id: makeId(),
        helper: t("alert:checkCellularConnectivity"),
        icon: <SignalCellularConnectedNoInternet0Bar />,
        severity: "error",
        text: t("alert:noCellularConnectivity"),
        title: t("alert:internet"),
      });
    } else {
      setOnline(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserOnline, t]);

  useEffect(() => {
    if (!fbUser && !userIdentity) {
      setUserFb({
        id: makeId(),
        helper: t("alert:authenticateByMenu"),
        icon: <Person />,
        severity: "error",
        text: t("alert:notAuthenticated"),
        title: t("alert:user"),
      });
    } else {
      setUserFb(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser, t, userIdentity]);

  useEffect(() => {
    if (robotParcelCheckFeedback !== null) {
      setMapFeedback({
        id: makeId(),
        helper: t("alert:plotHelper"),
        icon: <Map />,
        severity: "warning",
        text: t("alert:plotText"),
        title: t("common:plot"),
      });
    } else {
      setMapFeedback(null);
    }
  }, [t, robotParcelCheckFeedback]);

  useEffect(() => {
    if (hasBakusUpdateAvailable === true) {
      setBakusUpdateAvailable({
        id: makeId(),
        helper: t("alert:useLateralMenuToUpdate"),
        icon: <Update />,
        severity: "info",
        text: t("version:drawerMenuTitleUpdateAvailable"),
        title: t("version:drawerMenuTitle"),
      });
    } else {
      setBakusUpdateAvailable(null);
    }
  }, [t, hasBakusUpdateAvailable]);

  useEffect(() => {
    // Feedback list by order priority
    const list: (IAlertData | null)[] = [
      autonomousFb,
      battery,
      bakusUpdateAvailable,
      bumpers,
      bumpersShunted,
      emergencyFb,
      estop,
      lowLevelVersionMismatch,
      mapFeedback,
      online,
      rtkBack,
      rtkFront,
      socket,
      tools,
      userFb,
      work,
    ];
    const copy: IAlertData[] = [];
    list.forEach((lock) => {
      if (lock !== null) copy.push(lock);
    });
    copy.sort(orderByImportance);

    setLockList(copy);
    setMapOffset(
      copy.length > 0 ? MAP_OFFSET.withAlert : MAP_OFFSET.withoutAlert
    );
    setHasAlertFeedback(copy.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autonomousFb,
    battery,
    bakusUpdateAvailable,
    bumpers,
    bumpersShunted,
    emergencyFb,
    estop,
    lowLevelVersionMismatch,
    mapFeedback,
    online,
    rtkBack,
    rtkFront,
    socket,
    tools,
    userFb,
    work,
  ]);
};

export default useAlertManager;
