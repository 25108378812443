import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const supportedLanguages = ["fr", "en", "it", "es", "cs", "hu"];

const i18nNamespaces = [
  "alert",
  "common",
  "depth",
  "incident",
  "intercep",
  "lateral",
  "legend",
  "live",
  "mower",
  "row-extremity",
  "speed",
  "tools",
  "version",
  "work",
];

const getI18nResources = (languages: string[], namespaces: string[]) => {
  const resources: { [key: string]: any } = {};

  languages.forEach((language) => {
    const lang: { [key: string]: any } = {};
    namespaces.forEach((namespace) => {
      try {
        // console.log(
        //   "Try to load locale file",
        //   `./locales/${language}/${namespace}.json`
        // );
        const langData = require(`i18n/locales/${language}/${namespace}.json`);
        lang[namespace] = langData;
      } catch (err) {
        console.log(
          "Unable to load language data for",
          language,
          namespace,
          err
        );
      }
    });
    resources[language] = lang;
  });

  return resources;
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3", // https://www.i18next.com/misc/migration-guide#v-20-x-x-to-v-21-0-0
  debug: process.env.NODE_ENV !== "production",
  defaultNS: "common",
  fallbackNS: "common",
  detection: { order: ["localStorage", "cookie", "navigator", "path"] },
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false, // we use content as keys

  // have a common namespace used around the full app
  ns: ["common"],

  // we init with resources
  resources: getI18nResources(supportedLanguages, i18nNamespaces),
});
