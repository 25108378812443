import {
  AppBar,
  ButtonBase,
  IconButton,
  Toolbar,
  colors,
  styled,
} from "@mui/material";

import { borderRadius } from "utils/sharedStyles";

export const LiveControlPageWrapper = styled("div", {
  name: "LiveControlPageWrapper",
})({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  margin: "0 5px",
});

export const LiveControlPageContainer = styled("div", {
  name: "LiveControlPageContainer",
})({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: 500,
  height: "100%",
});

export const LatencyContainer = styled("div", {
  name: "LatencyContainer",
})({
  position: "fixed",
  right: 3,
  top: 3,
  zIndex: 1000,
});

export const RightButton = styled(IconButton, {
  name: "RightButton",
})({
  width: "20%",
});

export const Footer = styled("div", {
  name: "Footer",
})({
  display: "flex",
  width: "100%",
  margin: "0 auto",
  // backgroundColor: "white",
  zIndex: 1250,
});

export const FooterContent = styled("div", {
  name: "FooterContent",
})({
  flex: 1,
  overflow: "hidden",
  borderRadius: borderRadius,
});

export const BottomAppBar = styled(AppBar, {
  name: "BottomAppBar",
})({
  position: "relative",
  marginBottom: 6,
  backgroundColor: "transparent",
});

export const BottomToolbar = styled(Toolbar, {
  name: "BottomToolbar",
})({
  alignItems: "center",
});

export const StopButton = styled(ButtonBase, {
  name: "StopButton",
})({
  borderRadius: borderRadius,
  backgroundColor: colors.red[500],
  width: "100%",
  minHeight: 72,
});
