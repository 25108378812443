import { ATOMS, selectors } from "recoil/atoms";
import { IStatusCodeData, IStatusCodeEntry } from "types/statusCode";
import { ROLE_DEV_ONLY, ROLE_SUPERVISOR_ONLY } from "const";
import { RecoilState, useRecoilValue } from "recoil";
import { useCallback, useEffect, useState } from "react";

import { getBestTranslationCode } from "utils/lang";
import useHasAccess from "hooks/user/useHasAccess";
import { useTranslation } from "react-i18next";

const useGetStatusByCode = () => {
  const { t, i18n } = useTranslation();

  const statusCodes = useRecoilValue<IStatusCodeData>(
    selectors[ATOMS.STATUS_CODE] as RecoilState<IStatusCodeData>
  );

  const isSupervisor = useHasAccess("all", ROLE_SUPERVISOR_ONLY);
  const isDev = useHasAccess("all", ROLE_DEV_ONLY);

  const [statusCodesMap, setStatusCodesMap] = useState<
    Map<string, IStatusCodeEntry>
  >(new Map());

  const [defaultStatusCode, setDefaultStatusCode] = useState({
    extraMessage: "",
    lock_resolution: t("restartRobotAndCallSupport"),
    message: t("unknownError"),
    resolution: t("restartRobotAndCallSupport"),
  });

  useEffect(() => {
    setDefaultStatusCode({
      extraMessage: "",
      lock_resolution: t("restartRobotAndCallSupport"),
      message: t("unknownError"),
      resolution: t("restartRobotAndCallSupport"),
    });
  }, [i18n.language, t]);

  useEffect(() => {
    setStatusCodesMap(new Map(Object.entries(statusCodes)));
  }, [statusCodes]);

  const getStatus = useCallback(
    (
      code: string
    ): {
      extraMessage: string;
      lock_resolution: string;
      message: string;
      resolution: string;
    } => {
      let result = {
        extraMessage: "",
        lock_resolution: "",
        message: "",
        resolution: "",
      };

      if (!code) {
        return result;
      }

      if (!statusCodesMap.has(code)) {
        return defaultStatusCode;
      }

      const statusCode = statusCodesMap.get(code) as IStatusCodeEntry;

      const langToUse = getBestTranslationCode(
        statusCode.user.map((translation) => translation.code.substring(0, 2)),
        i18n.language
      );
      if (langToUse) {
        try {
          result = JSON.parse(
            JSON.stringify(
              statusCode.user.find(
                (translation) => translation.code.substring(0, 2) === langToUse
              )
            )
          );
        } catch (error) {
          console.log(
            "unable to parse",
            statusCode.user.find(
              (translation) => translation.code === langToUse
            ),
            error
          );
        }
      } else {
        // Only one traduction
        if (statusCode.user.length === 1) {
          try {
            result = JSON.parse(JSON.stringify(statusCode.user[0]));
          } catch (error) {
            console.log("unable to parse", statusCode.user[0], error);
          }
        }
      }

      if (isSupervisor) {
        const langToUse = getBestTranslationCode(
          statusCode.supervisor_comment.map((translation) => translation.code),
          i18n.language
        );
        if (langToUse) {
          const findSupervisorTranslation = statusCode.supervisor_comment.find(
            (translation) => translation.code === langToUse
          );
          if (findSupervisorTranslation) {
            result.extraMessage = findSupervisorTranslation.message;
          }
        }
      }

      if (isDev && statusCode.dev_comment) {
        result.extraMessage = statusCode.dev_comment;
      }

      return result;
    },
    [statusCodesMap, i18n.language, isSupervisor, isDev, defaultStatusCode]
  );

  return getStatus;
};

export default useGetStatusByCode;
