import {
  IntercepCalibrationActionButton,
  IntercepCalibrationPageBodyContent,
  IntercepCalibrationPageFooter,
} from "pages/IntercepCalibrationPage.styled";

import { TIntercepCalibrationMachineSendActionFn } from "stateMachine/IntercepCalibration";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type IntercepCalibrationStartStepProps = {
  sendAction: TIntercepCalibrationMachineSendActionFn;
};

const IntercepCalibrationStartStep = ({
  sendAction,
}: IntercepCalibrationStartStepProps) => {
  const { t } = useTranslation(["common", "intercep"]);

  return (
    <>
      <IntercepCalibrationPageBodyContent>
        <Typography textAlign="center">
          {t("intercep:calibrationStepActionDeployCalibrationStoppers")}
        </Typography>
      </IntercepCalibrationPageBodyContent>

      <IntercepCalibrationPageFooter>
        <IntercepCalibrationActionButton
          variant="contained"
          onClick={() => {
            sendAction({
              type: "MECHANICAL_DEPLOYED",
            });
          }}
        >
          {t("common:continue")}
        </IntercepCalibrationActionButton>
      </IntercepCalibrationPageFooter>
    </>
  );
};

export default IntercepCalibrationStartStep;
