import { Divider, LinearProgress, Typography } from "@mui/material";
import {
  IntercepCalibrationPageBodyContainer,
  IntercepCalibrationPageContainer,
  IntercepCalibrationPageHeader,
} from "./IntercepCalibrationPage.styled";
import useIntercepCalibration, {
  MAX_INTERCEP_CALIBRATION_STEP_TIME,
} from "hooks/tools/useIntercepCalibration";

import FixedSpinner from "components/Global/FixedSpinner";
import { INTERCEP_CALIBRATION_STEP } from "const";
import IntercepCalibrationAskPutEmergencyStopStep from "components/intercepCalibration/steps/IntercepCalibrationAskPutEmergencyStopStep";
import IntercepCalibrationAskRemoveEmergencyStopStep from "components/intercepCalibration/steps/IntercepCalibrationAskRemoveEmergencyStopStep";
import IntercepCalibrationDoneStep from "components/intercepCalibration/steps/IntercepCalibrationDoneStep";
import IntercepCalibrationError from "components/intercepCalibration/steps/IntercepCalibrationError";
import IntercepCalibrationIdleStep from "components/intercepCalibration/steps/IntercepCalibrationIdleStep";
import IntercepCalibrationIntercepLoadingStep from "components/intercepCalibration/steps/IntercepCalibrationIntercepLoadingStep";
import IntercepCalibrationMechanicalDeploymentStep from "components/intercepCalibration/steps/IntercepCalibrationMechanicalDeploymentStep";
import IntercepCalibrationMechanicalFoldStep from "components/intercepCalibration/steps/IntercepCalibrationMechanicalFoldStep";
import IntercepCalibrationStartStep from "components/intercepCalibration/steps/IntercepCalibrationStartStep";
import IntercepCalibrationStopButton from "components/intercepCalibration/IntercepCalibrationStopButton";
import { useTranslation } from "react-i18next";

// Portail "captif" présent dans SingleRobotHome.tsx
const IntercepCalibrationPage = () => {
  const { t } = useTranslation(["intercep"]);

  const { currentState, sendAction, stepTimerLeft } = useIntercepCalibration();

  const getCurrentStepContent = () => {
    switch (currentState) {
      case "idle":
        return <IntercepCalibrationIdleStep sendAction={sendAction} />;
      case "robot_step_1":
        return <IntercepCalibrationStartStep sendAction={sendAction} />;
      case "robot_step_2":
        return (
          <IntercepCalibrationIntercepLoadingStep
            text={t("intercep:calibrationStepRunningIntercepDeployment")}
          />
        );
      case "gui_step_3":
        return (
          <IntercepCalibrationAskPutEmergencyStopStep sendAction={sendAction} />
        );
      case "gui_step_3_1":
        return (
          <IntercepCalibrationMechanicalDeploymentStep
            sendAction={sendAction}
          />
        );
      case "gui_step_3_2":
        return (
          <IntercepCalibrationAskRemoveEmergencyStopStep
            sendAction={sendAction}
            nextStep={INTERCEP_CALIBRATION_STEP.CALIBRATION}
            continueText={t("intercep:calibrationContinueToStepCalibration")}
          />
        );
      case "robot_step_4":
        return (
          <IntercepCalibrationIntercepLoadingStep
            text={t("intercep:calibrationStepRunningCalibration")}
          />
        );
      case "robot_step_5":
        return (
          <IntercepCalibrationIntercepLoadingStep
            text={t("intercep:calibrationStepRunningConfigSave")}
          />
        );
      case "gui_step_6":
        return (
          <IntercepCalibrationAskPutEmergencyStopStep sendAction={sendAction} />
        );
      case "gui_step_6_1":
        return (
          <IntercepCalibrationMechanicalFoldStep sendAction={sendAction} />
        );
      case "gui_step_6_2":
        return (
          <IntercepCalibrationAskRemoveEmergencyStopStep
            sendAction={sendAction}
          />
        );
      case "gui_step_6_3":
        return <IntercepCalibrationDoneStep sendAction={sendAction} />;
      case "error":
        return <IntercepCalibrationError sendAction={sendAction} />;
      default:
        return <FixedSpinner />;
    }
  };

  return (
    <IntercepCalibrationPageContainer>
      <IntercepCalibrationPageHeader>
        <Typography>{t("intercep:calibrationPageTitle")}</Typography>
      </IntercepCalibrationPageHeader>

      <IntercepCalibrationPageBodyContainer>
        {getCurrentStepContent()}
      </IntercepCalibrationPageBodyContainer>

      {stepTimerLeft && stepTimerLeft > 0 ? (
        <LinearProgress
          variant="determinate"
          value={(stepTimerLeft / MAX_INTERCEP_CALIBRATION_STEP_TIME) * 100}
        />
      ) : null}

      <Divider />

      <IntercepCalibrationStopButton />
    </IntercepCalibrationPageContainer>
  );
};

export default IntercepCalibrationPage;
