import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";

interface IIntercepIconProps {
  frontColor?: string;
  fontSize?: number;
}

const Steering = (props: IIntercepIconProps) => {
  const theme = useTheme();

  const frontColor = props.frontColor ?? theme.palette.primary.main;
  const fontSize = props.fontSize ?? 24;

  return (
    <SvgIcon viewBox="0 0 496 512" style={{ fontSize: fontSize }}>
      <path
        fill={frontColor}
        d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 64c90.53 0 165.82 65.77 181.03 152h-93.9c-11.09-19.05-31.49-32-55.12-32h-64c-23.63 0-44.04 12.95-55.12 32h-93.9C82.18 137.77 157.47 72 248 72zM66.97 288H168l48 64v85.03C140.19 423.65 80.35 363.81 66.97 288zM280 437.03V352l48-64h101.03C415.65 363.81 355.81 423.65 280 437.03z"
      />
    </SvgIcon>
  );
};

export default Steering;
