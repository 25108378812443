import { borderRadius } from "utils/sharedStyles";
import { styled } from "@mui/material/styles";

// SliderBase SliderVertical SliderTool SliderToolDisabled
export const SliderContainer = styled("div", {
  name: "SliderContainer",
})<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  & input{
    &::-webkit-slider-thumb {
      appearance: none;

      background-color: ${disabled ? "#777" : theme.palette.primary.main};
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(./icons/import_export-24px.svg);

      transform: rotate(90deg);

      border: ${disabled ? "unset" : `2px solid ${theme.palette.primary.main}`};
      border-radius: 6px;

      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
      cursor: pointer;
      fill: white;
      height: 64px;
      width: 64px;
    }
    appearance: none;
    border: 2px solid;
    border-color: ${disabled ? "#777" : theme.palette.primary.main};
    border-radius: ${borderRadius}px;

    background-color: ${theme.palette.background.default};

    height: 36px;
    margin: 0;
    width: 300px;

    transform: rotate(90deg) translateY(11px);
    transform-origin: 32px 32px;
  }

  display: inline-block;
  padding: 0;
  height: 300px;
  width: 64px;
`
);
