import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  styled,
} from "@mui/material";

export const LangButton = styled(
  (props: ButtonProps) => <Button {...props} />,
  {
    name: "LangButton",
  }
)(({ theme }) => ({
  paddingRight: theme.spacing(1.75),
  "& .MuiButton-endIcon": {
    marginLeft: theme.spacing(0.5),
    opacity: 0.5,
  },
}));

export const LangMenu = (props: MenuProps) => (
  <Menu
    PaperProps={{
      sx: {
        marginTop: 1,
        border: "1.5px solid #0000000d",
        boxShadow: "-8px 8px 20px 0px #0000001a",
      },
    }}
    MenuListProps={{
      "aria-labelledby": "change-lang-btn",
    }}
    {...props}
  />
);

export const LangMenuItem = styled(
  (props: MenuItemProps) => <MenuItem {...props} />,
  {
    name: "LangMenuItem",
  }
)(({ theme }) => ({
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(3),
}));
