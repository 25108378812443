import { ATOMS, selectors } from "recoil/atoms";
import { LiveContainer, LiveWrapper } from "./LiveManager.styled";

import LiveFeedback from "components/orders/Live/LiveFeedback";
import LivePaneManager from "components/orders/Live/LivePaneManager";
import LiveProcessor from "components/orders/Live/LiveProcessor";
import PageVisibility from "react-page-visibility"; // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Browser_compatibility
import useHttpApi from "hooks/network/useHttpApi";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

type LiveManagerProps = {
  onClose: () => void;
};

const LiveManager = ({ onClose }: LiveManagerProps) => {
  const { t } = useTranslation(["live"]);
  const navigate = useNavigate();

  const { httpRobotManager } = useHttpApi();
  const setSnackBar = useSetRecoilState(selectors[ATOMS.SNACKBAR]);

  const handleVisibilityChange = () => {
    httpRobotManager.releaseControl();
  };

  const onTimeoutControl = () => {
    setSnackBar({
      content: t("live:unableToTakeControlInTime"),
      duration: 6000,
      severity: "warning",
    });
    navigate(-1);
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <LiveWrapper>
        <LiveContainer>
          <LiveProcessor onTimeout={onTimeoutControl} />
          <LiveFeedback />
          <LivePaneManager onClose={onClose} />
        </LiveContainer>
      </LiveWrapper>
    </PageVisibility>
  );
};

export default LiveManager;
