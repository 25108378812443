import { ListItem, ListItemSecondaryAction, styled } from "@mui/material";

export const ListItemElement = styled(ListItem, { name: "ListItemElement" })<{
  onClick?: VoidFunction;
}>(
  ({ onClick }) => `
  cursor: ${onClick ? "pointer" : "default"};
`
);

export const ListItemElementAction = styled(ListItemSecondaryAction, {
  name: "ListItemElementAction",
  shouldForwardProp: (prop) =>
    prop !== "leadingFlex" && prop !== "leadingInteractive",
})<{
  leadingInteractive?: boolean;
  leadingFlex?: boolean;
}>(
  ({ leadingInteractive, leadingFlex }) => `
  ${leadingFlex ? "display: flex;" : ""}
  ${leadingInteractive === false ? "pointer-events: none;" : ""}
`
);
