import { IStamp } from "types/emergency";

export const getStamp = (): IStamp => {
  const date = (Date.now() / 1000).toPrecision(13).toString().split("."); // split second and millisecond
  return {
    nsecs: Number(date[1]) * 1000000, // millisecond to nanosecond
    secs: Number(date[0]),
  };
};

export const getStampFromDateRobot = (
  date: number,
  dateRobotStamped: IStamp | null
): IStamp => {
  if (
    dateRobotStamped !== null &&
    dateRobotStamped !== undefined &&
    dateRobotStamped.secs !== null &&
    dateRobotStamped.secs !== undefined &&
    dateRobotStamped.nsecs !== null &&
    dateRobotStamped.nsecs !== undefined
  ) {
    return dateRobotStamped;
  }
  const dt = date.toPrecision(13).toString().split("."); // split second and millisecond
  return {
    nsecs: Number(dt[1]) * 1000000, // millisecond to nanosecond
    secs: Number(dt[0]),
  };
};
