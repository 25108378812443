import { API_ORDERS, EMERGENCY_LEVEL } from "const";
import { ATOMS, selectors } from "recoil/atoms";
import { RecoilState, useRecoilValue } from "recoil";

import { AutopilotSocketManager } from "managers/AutopilotSocket";
import { IFirebaseUser } from "types/firebase";
import useRobotOrder from "hooks/robot/useRobotOrder";

const useStopOrder = () => {
  const sendOrder = useRobotOrder();
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);
  const fbUser = useRecoilValue<IFirebaseUser | null>(
    selectors[ATOMS.FB_USER] as RecoilState<IFirebaseUser | null>
  );

  const emergencyOrder = () => {
    if (socketConnected === false) {
      sendOrder(API_ORDERS.EmergencyLock, {
        lvl: EMERGENCY_LEVEL.NO_POWER,
        name: fbUser?.uid ?? "unknown",
        type: "4G",
      });
    } else if (socketConnected === true) {
      AutopilotSocketManager.getInstance().socketManager.emit(
        API_ORDERS.EmergencyLock,
        {
          lvl: EMERGENCY_LEVEL.NO_POWER,
          name: fbUser?.uid ?? "unknown",
          type: "WIFI",
        }
      );
    }
  };

  return [emergencyOrder];
};

export default useStopOrder;
