import { ButtonGroup, IconButton, styled } from "@mui/material";

import { borderRadius } from "utils/sharedStyles";

export const CloseButton = styled(IconButton, {
  name: "CloseButton",
})(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main} !important`,
  color: `${theme.palette.common.white} !important`,
  border: "2px solid rgba(0, 0, 0, 0.2)",
  backgroundClip: "padding-box",
  pointerEvents: "all",
  borderRadius,
  zIndex: 6,
}));

export const ButtonBar = styled(ButtonGroup, {
  name: "ButtonBar",
})({
  pointerEvents: "none",

  "& > *": {
    borderRadius: "0 !important",
  },

  "& > :first-child": {
    borderTopLeftRadius: `${borderRadius}px !important`,
    borderBottomLeftRadius: `${borderRadius}px !important`,
  },

  "& > :last-child": {
    borderTopRightRadius: `${borderRadius}px !important`,
    borderBottomRightRadius: `${borderRadius}px !important`,
  },
});

export const PaneIcon = styled(IconButton, {
  name: "PaneIcon",
})<{ active: boolean }>(({ theme, active }) => ({
  backgroundColor: `${
    active ? theme.palette.primary.main : theme.palette.background.paper
  } !important`,
  color: `${
    active ? theme.palette.background.paper : theme.palette.primary.main
  } !important`,
  border: "2px solid rgba(0, 0, 0, 0.2)",
  backgroundClip: "padding-box",
  pointerEvents: "all",
}));
