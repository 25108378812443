import { ATOMS, selectors } from "recoil/atoms";
import {
  IWorkConfigEnum,
  LIVE_PANE_CONTENT,
  availableWorkConfigEntry,
} from "const";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useRef, useState } from "react";

import { IGuiConfig } from "types/guiConfig";
import LiveDataManager from "managers/LiveDataManager";
import LiveIntercepManager from "components/orders/Live/LiveIntercepManager";
import LiveMoveManager from "components/orders/Live/LiveMoveManager";
import LiveMowerManager from "components/orders/Live/LiveMowerManager";
import LiveToolbarManager from "components/orders/Live/LiveToolbarManager";
import { PaneContainer } from "./LiveProcessor.styled";
import useLiveSocketControl from "hooks/robot/useLiveSocketControl";

const fallbackIntercepMode: availableWorkConfigEntry = IWorkConfigEnum.STOP;

interface ILiveProcessorProps {
  onTimeout: () => void;
}

// let waitThrottle = false;

const LiveProcessor = (props: ILiveProcessorProps) => {
  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);
  const { setPublishedContent } = useLiveSocketControl();

  const [disabled, setDisabled] = useState(true);

  const disabledCB = useRef(true);
  const releaseControlTimeout = useRef<NodeJS.Timeout | null>(null);

  const liveControlPane = useRecoilValue<LIVE_PANE_CONTENT>(
    selectors[ATOMS.UI_LIVE_PANE_CONTENT] as RecoilState<LIVE_PANE_CONTENT>
  );
  const readyToDrive = useRecoilValue<boolean>(
    selectors[ATOMS.READY_TO_DRIVE] as RecoilState<boolean>
  );
  const usersConfig = useRecoilValue<IGuiConfig>(
    selectors[ATOMS.USERS_CONFIG] as RecoilState<IGuiConfig>
  );

  useEffect(() => {
    if (readyToDrive !== null && readyToDrive !== undefined) {
      setDisabled(readyToDrive !== true || !socketConnected);
      disabledCB.current = readyToDrive !== true || !socketConnected;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToDrive, socketConnected]);

  function checkControl() {
    if (disabledCB.current && props.onTimeout) {
      props.onTimeout();
    }
  }

  useEffect(() => {
    if (usersConfig) {
      releaseControlTimeout.current = setTimeout(
        checkControl,
        usersConfig.takeControlTimeout
      );
    }

    return () => {
      if (releaseControlTimeout.current) {
        clearTimeout(releaseControlTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersConfig]);

  useEffect(() => {
    if (socketConnected === true) {
      LiveDataManager.getInstance().connect();

      return () => {
        // Stop listening
        LiveDataManager.getInstance().dispose();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected]);

  return (
    <>
      {liveControlPane === LIVE_PANE_CONTENT.move && (
        <LiveMoveManager
          setPublishedContent={setPublishedContent}
          disabled={disabled}
        />
      )}
      {liveControlPane === LIVE_PANE_CONTENT.toolbar && (
        <PaneContainer>
          <LiveToolbarManager
            setPublishedContent={setPublishedContent}
            disabled={disabled}
            fallbackIntercepMode={fallbackIntercepMode}
          />
        </PaneContainer>
      )}
      {liveControlPane === LIVE_PANE_CONTENT.tools && (
        <PaneContainer>
          <LiveIntercepManager
            setPublishedContent={setPublishedContent}
            disabled={disabled}
          />
        </PaneContainer>
      )}
      {liveControlPane === LIVE_PANE_CONTENT.mower && (
        <PaneContainer>
          <LiveMowerManager
            setPublishedContent={setPublishedContent}
            disabled={disabled}
          />
        </PaneContainer>
      )}
    </>
  );
};

export default LiveProcessor;
