import { Timestamp } from "firebase/firestore";

export enum ImageType {
  AUTOPILOT = "autopilot",
  GUI = "gui",
  WIZARD = "wizard",
}

export enum PullStatus {
  CHECKING = "checking",
  DONE = "done",
  ERROR = "error",
  PULLING = "pulling",
  REQUIRED = "required",
  UNCHECKED = "unchecked",
  WAITING_RETRY = "waiting_retry",
}

export enum UpdateStatus {
  CHECKING = "checking",
  DONE = "done",
  ERROR = "error",
  UPDATING = "updating",
  REQUIRED = "required",
  UNCHECKED = "unchecked",
  WAITING_RETRY = "waiting_retry",
}

export enum UpdateLockReason {
  NONE = "none",
  WAITING_ESTOP = "waiting_estop",
}

export enum UpdaterStatus {
  CHECKING = "checking", // Global check
  CHECKING_PULL = "checking_pull",
  CHECKING_UPDATE = "checking_update",
  ERROR = "error", // ?
  IDLE = "idle",
  PULLING = "pulling",
  REQUIRE_PULL = "require_pull",
  REQUIRE_UPDATE = "require_update",
  UNCHECKED = "unchecked",
  UPDATING = "updating",
  WAITING_FOR_VERSION_INFO = "waiting_for_version_info",
  DONE = "done",
  UP_TO_DATE = "up_to_date",
  PULLING_WAITING_RETRY = "pulling_waiting_retry",
  UPDATING_WAITING_RETRY = "updating_waiting_retry",
}

export enum ImageSource {
  DOCKER = "DOCKER",
  URL = "URL",
}

export interface IBakusVersion {
  id: string;
  version: string;
  creation_date: Timestamp;
  created_by: {
    user_id: string;
    user_name: string;
  };
  details: {
    autopilotVersionID: string | null;
    wizardVersionID: string | null;
    networkVersionID: string | null;
    guiVersionID: string | null;
  };
}

export interface IUpdateBakusVersion {
  global: IUpdateBakusVersionGlobal;
  versions: IUpdateBakusVersionEntry[];
}

export interface IUpdateLowLevelVersion {
  global: UpdaterStatus;
  version: MisMatchVersionInfo;
}

type MisMatchVersionInfo = {
  hasMismatch: boolean;
  expectedVersion: string | null;
  version?: IUpdateBakusVersionEntry;
};

interface IUpdateBakusVersionGlobal {
  status: UpdaterStatus;
  pullProgress: number;
  installProgress: number;
  name: string;
}

interface IUpdateBakusVersionEntry {
  type: ImageType;
  version: IUpdateBakusVersionDetails;
  pullStatus: PullStatus;
  updateStatus: UpdateStatus;
  pullProgress: number;
  installProgress: number;
  updateLocked: boolean;
  updateLockReason: UpdateLockReason;
}

export interface IUpdateBakusVersionDetails {
  endpoint: string;
  source: ImageSource;
  version: string;
}

export interface IBakusWizardVersion {
  version: string;
  commit_hash: string;
  flags: string;
  pretty_version: string;
}

export enum VisualUpdateStatus {
  None,

  Done,
  Error,
  PullPending,
  Pulling,
  UpToDate,
  UpdatePending,
  Updating,
  WaitingForVersionInfo,
  Working,
}
