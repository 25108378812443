import { useEffect, useState } from "react";

import { LiveDataEvent } from "managers/LiveDataManager";
import { secureOrder } from "utils/live";
import useLiveData from "hooks/live/useLiveData";

const liveFields: LiveDataEvent[] = [
  LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusLeft,
  LiveDataEvent.ToolsStatusInterceptOutsideWorkStatusRight,
];

const useIntercepOutsideLiveHandlingManager = () => {
  // Current Mode

  const {
    interceptOutsideWorkStatusLeft: leftOutsideCurrent,
    interceptOutsideWorkStatusRight: rightOutsideCurrent,
  } = useLiveData(liveFields, "useIntercepOutsideLiveHandlingManager");

  // User requested mode
  const [leftOutsideUserRequestMode, setLeftOutsideUserRequestMode] =
    useState(leftOutsideCurrent);
  const [rightOutsideUserRequestMode, setRightOutsideUserRequestMode] =
    useState(rightOutsideCurrent);

  // requested mode for setMessage use
  const [leftOutsideRequestToRobotMode, setLeftOutsideRequestToRobotMode] =
    useState(leftOutsideCurrent);
  const [rightOutsideRequestToRobotMode, setRightOutsideRequestToRobotMode] =
    useState(rightOutsideCurrent);

  useEffect(() => {
    // console.log(
    //   `leftOutside -> current: ${leftOutsideCurrent} || asked: ${leftOutsideUserRequestMode}`
    // );

    // console.log(
    //   "leftOutside -> User is allowed to perform action since it's not cleaning",
    //   leftOutsideUserRequestMode
    // );
    setLeftOutsideRequestToRobotMode(secureOrder(leftOutsideUserRequestMode));

    // else {
    //   console.log("leftOutside -> something is already working");
    // }
  }, [leftOutsideUserRequestMode]);

  useEffect(() => {
    // console.log(
    //   `rightOutside -> current: ${rightOutsideCurrent} || asked: ${rightOutsideUserRequestMode}`
    // );

    // console.log(
    //   "rightOutside -> User is allowed to perform action since it's not cleaning",
    //   rightOutsideUserRequestMode
    // );
    setRightOutsideRequestToRobotMode(secureOrder(rightOutsideUserRequestMode));

    // else {
    //   console.log("rightOutside -> something is already working");
    // }
  }, [rightOutsideUserRequestMode]);

  return {
    leftOutsideCurrent,
    rightOutsideCurrent,
    leftOutsideUserRequestMode,
    setLeftOutsideUserRequestMode,
    rightOutsideUserRequestMode,
    setRightOutsideUserRequestMode,
    leftOutsideRequestToRobotMode,
    rightOutsideRequestToRobotMode,
  };
};

export default useIntercepOutsideLiveHandlingManager;
