import useInitHooks from "recoil/useInitHooks";

/**
 * Wrap the init hook inside an empty component to avoid tree rendering
 *
 * @returns Nothing
 */
const InitHookContainer = () => {
  useInitHooks();

  return null;
};

export default InitHookContainer;
