import { ATOMS, selectors } from "recoil/atoms";
import { Fade, Typography } from "@mui/material";
import { RecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { FeedbackContainer } from "./LiveFeedback.styled";
import { useTranslation } from "react-i18next";

const LiveFeedback = () => {
  const { t } = useTranslation(["live", "alert"]);

  const socketConnected = useRecoilValue(selectors[ATOMS.WS_CONNECTED]);

  const [userFeedback, setUserFeedback] = useState("");
  const [isFeedbackAlert, setIsFeedbackAlert] = useState(false);

  const readyToDrive = useRecoilValue<boolean>(
    selectors[ATOMS.READY_TO_DRIVE] as RecoilState<boolean>
  );
  const isCalibrating = useRecoilValue<boolean>(
    selectors[ATOMS.CALIBRATING] as RecoilState<boolean>
  );

  const lowLevelVersionMismatch = useRecoilValue<boolean>(
    selectors[ATOMS.LOW_LEVEL_VERSION_MISMATCH] as RecoilState<boolean>
  );

  useEffect(() => {
    if (isCalibrating === true) {
      setUserFeedback(t("live:calibrating"));
      setIsFeedbackAlert(false);
      return;
    }
    if (readyToDrive !== true) {
      setUserFeedback(t("live:takingControl"));
      setIsFeedbackAlert(false);
      return;
    }
    if (lowLevelVersionMismatch === true) {
      setUserFeedback(t("live:lowLevelVersionMismatchAlert"));
      setIsFeedbackAlert(true);
      return;
    }
    if (!socketConnected) {
      setUserFeedback(t("alert:directConnectionError"));
      setIsFeedbackAlert(false);
      return;
    }
    setUserFeedback("");
    setIsFeedbackAlert(false);
  }, [
    readyToDrive,
    isCalibrating,
    socketConnected,
    lowLevelVersionMismatch,
    t,
  ]);

  return (
    <Fade in={userFeedback.length !== 0}>
      <FeedbackContainer isAlert={isFeedbackAlert} data-cy="feedback-alert">
        <Typography variant="h5">{userFeedback}</Typography>
      </FeedbackContainer>
    </Fade>
  );
};

export default LiveFeedback;
